import {
  useBalanceHub,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/aptos-defi/common';
import {
  ECONIA_COIN_ACCOUNT,
  ECONIA_TEST_PROGRAM_ADDRESS,
} from '@aries/aptos-defi/config';
import { getEconiaSDK } from '@aries/aptos-defi/trading/providers/econia/sdk';
import { withLoadingNotify } from '@aries/aptos-defi/utils';
import { useWallet } from '@aries/aptos-defi/wallet';
import { getTFunction } from '@aries/shared/locale';
import Big from 'big.js';
import { compact } from 'lodash';

export const useFaucet = () => {
  const { getAddressWithNotify } = useWallet();

  const { balanceMap, refresh } = useBalanceHub();
  const { tokenMap, tokenList } = useTokenInfoHub();
  const { provider } = useProviderHub();

  const faucets = compact([
    // tokenMap[APTOS_COIN_ADDRESS] && {
    //   asset: {
    //     ...tokenMap[APTOS_COIN_ADDRESS],
    //     priceUSD: tokenMap[APTOS_COIN_ADDRESS].price.toFixed(6),
    //     priceUSDNum: tokenMap[APTOS_COIN_ADDRESS].price.toNumber(),
    //     id: APTOS_COIN_ADDRESS,
    //   },
    //   wallet: tokenMap[APTOS_COIN_ADDRESS].toAmount(
    //     balanceMap[APTOS_COIN_ADDRESS]?.amount ?? Big(0),
    //   ),
    //   airdrop: async () => {
    //     const address = await withLoadingNotify(
    //       Promise.resolve(getAddressWithNotify()),
    //     );
    //     if (!address) return;

    //     const asset = tokenMap[APTOS_COIN_ADDRESS];
    //     const res = await withLoadingNotify(
    //       faucetClient.fundAccount(address, 10 ** 10),
    //       {
    //         sendingTitle: `Airdroping ${asset.symbol}...`,
    //         sendSuccessTitle: 'Airdrop Success!',
    //         sendSuccessContent: `Asset added to your wallet.`,
    //       },
    //     );

    //     if (res) {
    //       refresh();
    //     }
    //   },
    // },
    ...tokenList
      .filter(v => v.symbol !== 'APT' && v.symbol.startsWith('dev'))
      .map(coin => {
        return {
          asset: coin,
          wallet: tokenMap[coin.id].toAmount(
            balanceMap[coin.id]?.amount ?? Big(0),
          ),
          airdrop: async () => {
            const t = getTFunction();
            const asset = tokenMap[coin.id];
            const res = await withLoadingNotify(
              (async () => {
                getAddressWithNotify();

                return provider.sendCustomTx({
                  function:
                    '0x498d8926f16eb9ca90cab1b3a26aa6f97a080b3fcbe6e83ae150b7243a00fb68::devnet_coins::mint_to_wallet',
                  arguments: ['1000000000'],
                  type: 'entry_function_payload',
                  type_arguments: [coin.id],
                });
              })(),
              {
                sendingTitle: t`Airdroping ${asset.symbol}...`,
                sendSuccessTitle: t`Airdrop Success!`,
                sendSuccessContent: t`Asset added to your wallet.`,
              },
            );

            if (res) {
              refresh();
            }
          },
        };
      }),
    ...tokenList
      .filter(
        // v => v.symbol !== 'APT',
        v => v.symbol === 'eAPT' || v.symbol === 'eUSDC',
      )
      .map(coin => {
        return {
          asset: coin,
          wallet: tokenMap[coin.id].toAmount(
            balanceMap[coin.id]?.amount ?? Big(0),
          ),
          airdrop: async () => {
            const t = getTFunction();
            const asset = tokenMap[coin.id];
            const res = await withLoadingNotify(
              (async () => {
                getAddressWithNotify();

                const pld = await getEconiaSDK()
                  .faucet.mint(
                    {
                      amount: asset.toLamports(
                        coin.symbol === 'eUSDC' ? 600 : 100,
                      ),
                    },
                    { CoinType: coin.id },
                  )
                  .makePayload();
                return provider.sendCustomTx({
                  ...pld,
                  function: pld.function.replace(
                    ECONIA_TEST_PROGRAM_ADDRESS,
                    ECONIA_COIN_ACCOUNT,
                  ),
                });
              })(),
              {
                sendingTitle: t`Airdroping ${asset.symbol}...`,
                sendSuccessTitle: t`Airdrop Success!`,
                sendSuccessContent: t`Asset added to your wallet.`,
              },
            );

            if (res) {
              refresh();
            }
          },
        };
      }),
  ]);

  return {
    faucets,
  };
};
