/* eslint-disable prettier/prettier */
import './initialize';

import {
  getAptosLendingApp,
  useAptosLendingApp,
  useAptosTradingApp,
  useProviderHub,
} from '@aries/aptos-defi';
import { initWalletCtx } from '@aries/aptos-defi/wallet';
import {
  getWalletAdapter,
  useWalletAdapter,
} from '@aries/aptos-web-wallet';
import { WalletProvider } from '@aries/aptos-web-wallet/provider/mobile-web';
import { initLendingAppImpl } from '@aries/lending-fe-hooks/adapter';
import { UIProvider } from '@aries/mobile-core/provider';
import { HoxRoot } from '@aries/shared/deps';
import { initTradingAppImpl } from '@aries/trading-fe-hooks/adapter';
import React from 'react';
import { AppRoutes } from './routes';

initWalletCtx({ hook: useWalletAdapter, getter: getWalletAdapter });

initTradingAppImpl({
  hook: useAptosTradingApp,
});

initLendingAppImpl({
  hook: useAptosLendingApp,
  getter: getAptosLendingApp,
});

export default function App() {
  return (
    <HoxRoot>
      <UIProvider>
        <AppInner />
      </UIProvider>
    </HoxRoot>
  );
}

const AppInner = () => {
  const { env } = useProviderHub(p => [p.env.isTestnet]);

  return (
    <WalletProvider isTestnet={env.isTestnet}>
      <AppRoutes />
    </WalletProvider>
  );
};
