import { SwapProvider } from '@aries/aptos-defi/swap';
import { PanoraSwapProvider } from '@aries/aptos-defi/swap/providers/panora';
import { AptosTradingProvider } from '@aries/aptos-defi/trading';
import React, { useRef } from 'react';
import { ScrollView } from 'react-native';
import { FirstPageWrapper } from '~/components';
import { Content } from './Content';
import { Tips } from './Tips';

export const Swap: React.FunctionComponent<{}> = () => {
  const scrollviewRef = useRef<ScrollView>(null);

  const toBottom = () => {
    scrollviewRef.current?.scrollToEnd();
  };

  return (
    <FirstPageWrapper>
      <ScrollView
        ref={scrollviewRef}
        contentContainerStyle={{ paddingBottom: 12 }}
        showsVerticalScrollIndicator={false}
      >
        <Tips />
        <PanoraSwapProvider>
          <AptosTradingProvider>
            <SwapProvider>
              <Content scrollToBottom={toBottom} />
            </SwapProvider>
          </AptosTradingProvider>
        </PanoraSwapProvider>
      </ScrollView>
    </FirstPageWrapper>
  );
};
