import { CenterCol, CenterRow, Txt } from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import React from 'react';
import { FirstPageWrapper } from '~/components';

export const Bridge = () => {
  const { t } = useTranslation();
  return (
    <FirstPageWrapper>
      <CenterRow style={{ height: '100%' }}>
        <CenterCol fullWidth>
          <Txt h4 bold style={{ width: '80%', textAlign: 'center' }}>
            {t` Use Aries Markets at desktop to bridge tokens between
            blockchains easily`}
          </Txt>
        </CenterCol>
      </CenterRow>
    </FirstPageWrapper>
  );
};
