import {
  CenterBetweenRow,
  Col,
  LinearView,
  styled,
  Txt,
} from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import { isString } from 'lodash';
import React from 'react';
import { ViewStyle } from 'react-native';

export const Section: React.FC<{
  title: React.ReactNode;
  style?: ViewStyle;
  headerRight?: React.ReactNode;
  containerStyle?: ViewStyle;
}> = ({ title, children, style, headerRight, containerStyle }) => {
  return (
    <SectionCard style={containerStyle}>
      <Title color="#0A0B10,#0F121A,#151824/0.3,0:0.8:1">
        <CenterBetweenRow>
          {isString(title) ? (
            <Txt h3 bold>
              {title}
            </Txt>
          ) : (
            title
          )}
          <Txt h3 bold>
            {headerRight}
          </Txt>
        </CenterBetweenRow>
      </Title>
      <Col style={{ padding: '16px', ...style }}>{children}</Col>
    </SectionCard>
  );
};

const { marginSM, colorBgSpotlight, margin, borderRadiusLG } = globalToken;
const SectionCard = styled.View`
  margin-top: ${marginSM}px;
  border-color: ${colorBgSpotlight};
  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadiusLG};
`;

const Title = styled(LinearView)`
  padding: ${marginSM}px ${margin}px;
`;
