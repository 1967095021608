export const WALLET_POSITION_MAP: Record<string, number | undefined> = {
  'Continue with Google': 0,
  Petra: 1,
  'OKX Wallet': 2,
  'Bitget Wallet': 3,
  'Mizu Wallet': 4,
  'Pontem Wallet': 5,
  Martian: 6,
  Rise: 7,
  Blocto: 8,
  TrustWallet: 9,
  Fewcha: 10,
  MSafe: 11,
};
