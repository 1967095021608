import { EMPTY_BALANCE } from '@aries/aptos-defi/../defi-toolkit/types';
import { useBalanceHub, useTokenInfoHub } from '@aries/aptos-defi/common';
import { IconEconiaLogoLong } from '@aries/aptos-defi/common/assets/dex-icons';
import { EMPTY_MARKET } from '@aries/trading-fe-hooks';
import Big from 'big.js';
import { compact, flatten, noop, sortBy } from 'lodash';
import { useMemo } from 'react';
import { TradingProvider } from '../type';
import { getMarketActions } from './actions';
import { useAllOrderBook, useUserAccounts } from './data';

export const useEconiaTrading = (): TradingProvider => {
  const { tokenMap } = useTokenInfoHub();
  const { balanceMap } = useBalanceHub();

  const {
    orderbookList,
    currentMarketId,
    changeMarket,
    refreshCurrentMarket,
    marketLoading,
    setEnableOrderBook,
  } = useAllOrderBook();

  const {
    accountByMarket,
    getAccountByMarket,
    loading: accountLoading,
    refresh: refreshUserAccount,
    registerAccount,
  } = useUserAccounts(orderbookList);

  const markets = useMemo(() => {
    return compact(
      orderbookList.map(
        ({
          marketId,
          baseCoinAddress,
          quoteCoinAddress,
          lotSize,
          tickSize,
          value,
        }) => {
          const baseAsset = tokenMap[baseCoinAddress];
          const quoteAsset = tokenMap[quoteCoinAddress];
          if (!baseAsset?.id || !quoteAsset?.id) {
            return null;
          }
          const marketInfo = {
            baseCoinType: baseCoinAddress,
            quoteCoinType: quoteCoinAddress,
            marketId: Big(marketId),
          };
          if (!value) {
            return baseAsset.id && quoteAsset.id
              ? {
                  ...EMPTY_MARKET,
                  id: marketId,
                  marketInfo,
                  platformLogo: IconEconiaLogoLong,
                  baseAsset,
                  quoteAsset,
                }
              : null;
          }
          const { asks, bids, tradings, maxBidLamports, minAskLamports } =
            value;
          const maxBidAmount = quoteAsset.toAmount(
            baseAsset.toLamports(maxBidLamports.toNumber()),
          );
          const minAskAmount = quoteAsset.toAmount(
            baseAsset.toLamports(minAskLamports.toNumber()),
          );

          const baseAssetFromWallet =
            balanceMap[baseCoinAddress]?.amount ?? Big(0);
          const quoteAssetFromWallet =
            balanceMap[quoteCoinAddress]?.amount ?? Big(0);

          const actions = getMarketActions(marketInfo);
          const maybeAccount = getAccountByMarket(marketInfo);

          const toBaseBalance = (amount: Big) =>
            baseAsset.lamportsToBalance(amount);
          const toQuoteBalance = (amount: Big) =>
            quoteAsset.lamportsToBalance(amount);

          const shouldBeRegistered = async () => {
            const registered = await registerAccount(marketInfo);

            if (!registered) {
              throw new Error('User Canceled Registry');
            }
          };

          const userAccount = {
            hasWithdrawableFunds: maybeAccount?.canWithdraw ?? false,
            baseCollateral: {
              total: toBaseBalance(maybeAccount?.baseTotal ?? Big(0)),
              withdrawable: toBaseBalance(
                maybeAccount?.baseAvailable ?? Big(0),
              ),
              available: toBaseBalance(
                maybeAccount?.baseAvailable ?? Big(0),
              ),
            },
            quoteCollateral: {
              total: toQuoteBalance(maybeAccount?.quoteTotal ?? Big(0)),
              withdrawable: toQuoteBalance(
                maybeAccount?.quoteAvailable ?? Big(0),
              ),
              available: toQuoteBalance(
                maybeAccount?.quoteAvailable ?? Big(0),
              ),
            },
            orders: [
              ...compact(
                maybeAccount?.asks.map(v => {
                  const { priceLamports, lamports } = v;
                  if (priceLamports.eq(0)) {
                    return null;
                  }
                  const amount = baseAsset.toAmount(lamports);
                  const price = quoteAsset.toAmount(
                    baseAsset.toLamports(priceLamports.toNumber()),
                  );

                  return {
                    id: v.marketOrderId.toString(),
                    type: 'ask' as const,
                    amount: `${baseAsset.toAmountStr(lamports)}`,
                    price: `${price.toNumber()} ${quoteAsset.symbol}`,
                    total: `${quoteAsset.formatAmount(
                      price.mul(amount).toNumber(),
                    )}`,
                    cancel: async () => {
                      return actions.cancelLimitOrder(
                        'ask',
                        v.marketOrderId.toString(),
                      );
                    },
                  };
                }),
              ),
              ...compact(
                maybeAccount?.bids.map(v => {
                  const { priceLamports, lamports } = v;
                  if (priceLamports.eq(0)) {
                    return null;
                  }
                  const amount = baseAsset.toAmount(lamports);
                  const price = quoteAsset.toAmount(
                    baseAsset.toLamports(priceLamports.toNumber()),
                  );

                  return {
                    id: v.marketOrderId.toString(),
                    type: 'bid' as const,
                    amount: `${baseAsset.toAmountStr(lamports)}`,
                    price: `${price.toNumber()} ${quoteAsset.symbol}`,
                    total: `${quoteAsset.formatAmount(
                      price.mul(amount).toNumber(),
                    )}`,
                    cancel: async () => {
                      return actions.cancelLimitOrder(
                        'bid',
                        v.marketOrderId.toString(),
                      );
                    },
                  };
                }),
              ),
            ],
            addBaseColateral: async (amount: number) => {
              await shouldBeRegistered();
              return actions.addCollateral(
                'ask',
                baseAsset.toLamports(amount),
              );
            },
            withdrawBaseColateral: (amount: number) =>
              actions.withdrawCollateral(
                'ask',
                baseAsset.toLamports(amount),
              ),
            addQuoteColateral: async (amount: number) => {
              await shouldBeRegistered();
              return actions.addCollateral(
                'bid',
                quoteAsset.toLamports(amount),
              );
            },
            withdrawQuoteColateral: (amount: number) =>
              actions.withdrawCollateral(
                'bid',
                quoteAsset.toLamports(amount),
              ),
            withdrawAllFunds: async () =>
              maybeAccount?.canWithdraw
                ? actions.withdrawAllAsset(maybeAccount)
                : true,
          };

          return {
            id: marketId,
            marketInfo,
            lotSize: lotSize.toNumber(),
            tickSize: tickSize.toNumber(),
            platformLogo: IconEconiaLogoLong,
            baseAsset: {
              ...baseAsset,
              id: baseCoinAddress,
              priceUSD: `$${baseAsset.price.toFixed(6)}`,
              priceUSDNum: baseAsset.price.toNumber(),
            },
            quoteAsset: {
              ...quoteAsset,
              priceUSD: `$${quoteAsset.price.toFixed(6)}`,
              id: quoteCoinAddress,
              priceUSDNum: quoteAsset.price.toNumber(),
            },
            baseFromWallet: toBaseBalance(baseAssetFromWallet),
            quoteFromWallet: toQuoteBalance(quoteAssetFromWallet),
            userAccount,
            hasWithdrawableFunds: !!userAccount?.hasWithdrawableFunds,
            orderbook: {
              marketId,
              bids: bids.map(bid => {
                const price = quoteAsset.toAmount(
                  baseAsset.toLamports(Number(bid.price)),
                );
                return {
                  amount: baseAsset.toAmount(bid.lamports).toNumber(),
                  total: quoteAsset.toAmount(bid.totalLamports).toNumber(),
                  depthPct: bid.depthPct,
                  price: price.toString(),
                  priceNum: price.toNumber(),
                };
              }),
              asks: asks.map(ask => {
                const price = quoteAsset.toAmount(
                  baseAsset.toLamports(Number(ask.price)),
                );
                return {
                  amount: baseAsset.toAmount(ask.lamports).toNumber(),
                  total: quoteAsset.toAmount(ask.totalLamports).toNumber(),
                  depthPct: ask.depthPct,
                  price: price.toString(),
                  priceNum: price.toNumber(),
                };
              }),
              maxBid: maxBidAmount.toString(),
              maxBidNum: maxBidAmount.toNumber(),
              minAsk: minAskAmount.toString(),
              minAskNum: minAskAmount.toNumber(),
            },
            marketTradings: tradings.map(t => ({
              id: t.id,
              price: quoteAsset
                .toAmount(
                  baseAsset.toLamports(
                    t.priceLamports.mul(tickSize).div(lotSize).toNumber(),
                  ),
                )
                .toString(),
              amount: baseAsset
                .toAmount(t.lamports.mul(lotSize))
                .toString(),
              isBid: t.isBid,
              version: t.version,
            })),
            registerMarketAccount: () =>
              registerAccount({
                marketId: Big(marketId),
                baseCoinType: baseCoinAddress,
                quoteCoinType: quoteCoinAddress,
              }),
            actions,
            placeLimitOrder: (
              direction: 'buy' | 'sell',
              amount: number,
              price: number,
            ) => {
              return actions.placeLimitOrder(
                direction === 'buy' ? 'bid' : 'ask',
                baseAsset.toLamports(amount).div(lotSize),
                baseAsset.toAmount(
                  Big(price)
                    .mul(Big(10).pow(quoteAsset.decimals))
                    .mul(lotSize)
                    .div(tickSize),
                ),
              );
            },
            fillMarketOrder: (
              direction: 'buy' | 'sell',
              amount: number,
            ) => {
              return actions.fillMarketOrder({
                side: direction === 'buy' ? 'bid' : 'ask',
                amount,
                orderbookList,
              });
            },
          };
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbookList, tokenMap, accountByMarket, balanceMap]);

  const currentMarket = useMemo(() => {
    const m = markets.find(
      m => m.marketInfo.marketId.toString() === currentMarketId,
    );
    if (!m && markets?.[0]?.id) {
      changeMarket(markets?.[0]?.id);
    }
    return m ?? markets[0];
  }, [changeMarket, currentMarketId, markets]);

  const totalOrders = useMemo(() => {
    return sortBy(
      flatten(
        compact(
          Object.values(markets).map(m =>
            m.userAccount?.orders.map(o => ({
              ...o,
              baseAsset: m.baseAsset,
              quoteAsset: m.quoteAsset,
            })),
          ),
        ),
      ),
      a =>
        a.baseAsset.symbol === currentMarket?.baseAsset.symbol &&
        a.quoteAsset.symbol === currentMarket?.quoteAsset.symbol
          ? -1
          : 1,
    );
  }, [
    currentMarket?.baseAsset.symbol,
    currentMarket?.quoteAsset.symbol,
    markets,
  ]);

  return {
    markets,
    currentMarket,
    currentMarketId,
    changeMarket,
    refreshCurrentMarket,
    totalOrders,
    accountLoading,
    setInitialTradingPair: noop,
    refreshUserAccount,
    tradingTokens: currentMarket
      ? [
          {
            asset: currentMarket.baseAsset,
            collateral: currentMarket.userAccount.baseCollateral,
            addColateral: currentMarket.userAccount.addBaseColateral,
            withdrawColateral:
              currentMarket.userAccount.withdrawBaseColateral,
            wallet: currentMarket.baseFromWallet ?? EMPTY_BALANCE,
            withdrawable:
              currentMarket.userAccount.baseCollateral.withdrawable,
            depositable: currentMarket.baseFromWallet ?? EMPTY_BALANCE,
          },
          {
            asset: currentMarket.quoteAsset,
            collateral: currentMarket.userAccount.quoteCollateral,
            addColateral: currentMarket.userAccount.addQuoteColateral,
            withdrawColateral:
              currentMarket.userAccount.withdrawQuoteColateral,
            wallet: currentMarket.quoteFromWallet ?? EMPTY_BALANCE,
            withdrawable:
              currentMarket.userAccount.quoteCollateral.withdrawable,
            depositable: currentMarket.quoteFromWallet ?? EMPTY_BALANCE,
          },
        ]
      : [],
    marketLoading,
    marketEmpty: !marketLoading && !orderbookList.length,
    setEnableTradingRefresh: setEnableOrderBook,
  };
};
