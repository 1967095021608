import { CenterRow, Txt } from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import { globalToken } from '@aries/ui-theming';
import React from 'react';
import styled from 'styled-components/native';
import { IconThirdInfo } from '~/components';

const { marginXS, marginSM } = globalToken;
export const Tips: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <IconThirdInfo />
      <Txt flex={1} ml={marginXS} lh={18} h1 c2>
        {t`Transactions will happen inside of the Aries Markets platform when you allow borrow.`}
      </Txt>
    </Container>
  );
};

const Container = styled(CenterRow)`
  margin-top: ${marginSM}px;
  margin-bottom: ${marginSM}px;
`;
