import { CenterCol, Txt } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import styled from 'styled-components/native';

const { marginXS, borderRadiusLG, colorBgSpotlight } = globalToken;
export const SmallCard: React.FunctionComponent<{
  title: string;
  subTitle: string;
  hasMarginLeft: boolean;
  subColor?: string;
}> = ({ title, subTitle, hasMarginLeft, subColor }) => {
  return (
    <ItemContent ml={hasMarginLeft ? marginXS : 0}>
      <Txt h1 c2 style={{ fontWeight: '400' }} mb={marginXS}>
        {title}
      </Txt>
      <Txt bold color={subColor}>
        {subTitle}
      </Txt>
    </ItemContent>
  );
};

const ItemContent = styled(CenterCol)`
  flex: 1;
  padding: 10px;
  border-radius: ${borderRadiusLG};
  border-width: 1px;
  border-style: solid;
  border-color: ${colorBgSpotlight};
  margin-bottom: 5px;
`;
