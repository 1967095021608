import useSWR from 'swr';

export const useSupportMizuWallet = () => {
  const { data: mizuSupported = false } = useSWR(
    ['SupportMizuCountry'],
    async () => {
      try {
        await fetch(
          'https://assets.mz.xyz/static/config/mizuwallet-connect-manifest.json',
        );
        return true;
      } catch (error) {
        // console.log('🚀 ~ error:', error);
      }

      return false;
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
    },
  );

  return {
    mizuSupported,
  };
};
