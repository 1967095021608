import { last } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useReserves } from '../../reserves';
import { useProfileDetailHub } from './detail-hub';
import { useProfileList } from './list';

export const useProfileData = (walletAddress: string | undefined) => {
  const {
    profileList: metaList = [],
    addProfile,
    init: initMainProfile,
  } = useProfileList(walletAddress);

  const { profileMap, triggerLoadProfileDetail: triggerLoadProfile } =
    useProfileDetailHub();

  // Subaccount address
  const [currentProfileKey, setCurrentProfileKey] = useState<string>();

  const { reserves } = useReserves();

  const profileList = useMemo(() => {
    if (!metaList) return [];

    return metaList.map(({ profileName: name, address }) => {
      return {
        name,
        address,
        value: profileMap[address]?.value,
        loading: profileMap[address]?.loading,
      };
    });
  }, [metaList, profileMap]);

  const refreshCurrentProfile = async () => {
    const profile = metaList?.find(p => p.address === currentProfileKey);
    if (!profile) return;

    await triggerLoadProfile(profile.address, profile.profileName);
  };

  const changeProfile = (address: string) => {
    const profile = profileList?.find(p => p.address === address);
    if (!profile) return;

    setCurrentProfileKey(address);
    triggerLoadProfile(profile.address, profile.name);
  };

  useEffect(() => {
    if (
      metaList &&
      metaList.length > 0 &&
      !metaList.find(p => p.address === currentProfileKey)
    ) {
      setCurrentProfileKey(last(metaList)?.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaList, currentProfileKey]);

  useEffect(() => {
    profileList.forEach((profile, i) => {
      setTimeout(() => {
        triggerLoadProfile(profile.address, profile.name);
      }, 1000 * i);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileList.length, walletAddress]);

  // Trigger profile to recalculate rewards based on reserves info
  useEffect(() => {
    refreshCurrentProfile();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    // Refreshing profile depends on reserve type and reserve rewards (for claimable rewards)
    reserves
      .map(
        r =>
          r.coinAddress +
          r.borrowRewardApyPct.toFixed(0).toString() +
          r.supplyRewardApyPct.toFixed(0).toString(),
      )
      .join(''),
  ]);

  return {
    hasInitialized: (profileList?.length ?? 0) > 0,
    profileList,
    currentProfileKey,
    initMainProfile,
    addProfile,
    triggerLoadProfile,
    changeProfile,
    refreshCurrentProfile,
  };
};
