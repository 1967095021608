import { CenterRow, Txt } from '@aries/mobile-core/components';
import { ScreenInset } from '@aries/mobile-core/utils';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { ProfileSelect } from '~/services';
import { IconLogo } from '../../icons';
import { IconEarth } from './Icons';
import { openLanguageSelect } from './LanguageSelect';

export const PAGE_HEADER_HEIGHT = 45;

export type HeaderProps = {
  headerRight?: string | React.ReactNode;
  headerLeft?: string | React.ReactNode;
  headerStyle?: ViewStyle;
  hasAccount?: boolean;
};

export const Header = React.memo((props: HeaderProps) => {
  const {
    headerRight,
    headerLeft,
    headerStyle,
    hasAccount = true,
  } = props;
  const { mode } = useThemeMode();
  const { colorBgSpotlight } = colorConfig;
  return (
    <Container style={headerStyle}>
      <Content>
        {!headerLeft ? (
          <LeftBox>
            <IconLogo size={25} />
            <Txt ml={8}>Aries Markets</Txt>
          </LeftBox>
        ) : (
          headerLeft
        )}
        {hasAccount ? (
          <ProfileSelect
            style={{
              backgroundColor: `${colorBgSpotlight[mode]}`,
            }}
          />
        ) : null}

        {!headerRight ? (
          <RightBox onPress={() => openLanguageSelect()}>
            <IconEarth />
          </RightBox>
        ) : (
          headerRight
        )}
      </Content>
    </Container>
  );
});

const { colorBgContainer, colorBgSpotlight, padding } = globalToken;
const Container = styled(View)`
  background-color: ${colorBgContainer};
  padding-top: ${ScreenInset.top}px;
  padding-left: ${padding}px;
  padding-right: ${padding}px;
`;

const Content = styled(CenterRow)`
  height: ${PAGE_HEADER_HEIGHT}px;
`;

const LeftBox = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RightBox = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
  border-radius: 8;
  background-color: ${colorBgSpotlight};
`;
