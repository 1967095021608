import { useTokenInfoHub } from '@aries/aptos-defi/common';
import { Asset } from '@aries/defi-toolkit/types';
import { useEffect, useRef, useState } from 'react';

export type InitialPair = Parameters<typeof usePairState>;

export const usePairState = (
  initialValue?: Partial<{
    fromAsset: Asset;
    toAsset: Asset;
    fromAmount: number;
    toAmount: number;
  }>,
) => {
  const [fromAsset, setFromAsset] = useState<Asset | undefined>(
    initialValue?.fromAsset,
  );
  const [toAsset, setToAsset] = useState<Asset | undefined>(
    initialValue?.toAsset,
  );

  const [fromAmount, setFromAmount] = useState(
    initialValue?.fromAmount ?? -1,
  );

  const [toAmount, setToAmount] = useState(initialValue?.toAmount ?? -1);

  const changeFromAsset = async (asset: Asset | undefined) => {
    setFromAsset(asset);

    if (toAsset && toAsset.id === asset?.id) {
      setFromAsset(undefined);
    }
  };

  const changeToAsset = (asset: Asset | undefined) => {
    setToAsset(asset);
    if (fromAsset && fromAsset.id === asset?.id) {
      setFromAsset(undefined);
    }
  };

  const changeDirection = () => {
    // TODO
    setFromAmount(0);
    setFromAsset(toAsset);
    setToAsset(fromAsset);
  };

  const changePair = (from: Asset | undefined, to: Asset | undefined) => {
    setFromAsset(from);
    setToAsset(to);
  };

  const setInitialSwapPair = usePairInitial(changePair);

  return {
    currentPair: {
      fromAsset,
      fromAmount,
      toAsset,
      toAmount,
    },
    changePair,
    changeFromAsset,
    changeFromAmount: setFromAmount,
    changeToAsset,
    changeToAmount: setToAmount,
    changeDirection,
    setInitialSwapPair,
  };
};

const usePairInitial = (
  changePair: (a1: Asset | undefined, a2: Asset | undefined) => void,
) => {
  const { emptyableTokenMap } = useTokenInfoHub();
  const tokenPairKey = useRef<[string, string]>();

  const hasInitFromTokenPair = useRef(false);
  const mayInitFromSwapPair = () => {
    const map = emptyableTokenMap;
    if (
      Object.keys(map).length === 0 ||
      hasInitFromTokenPair.current ||
      !tokenPairKey.current
    ) {
      return;
    }

    hasInitFromTokenPair.current = true;

    const [a1, a2] = tokenPairKey.current;

    changePair(map[a1], map[a2]);
  };

  useEffect(() => {
    mayInitFromSwapPair();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyableTokenMap]);

  return (a1: string, a2: string) => {
    tokenPairKey.current = [a1, a2];
    mayInitFromSwapPair();
  };
};
