import { InputTransactionData } from '@aptos-labs/wallet-adapter-core';

/* eslint-disable import/no-mutable-exports */

type Adapter = {
  walletAddress: string | undefined;
  walletIcon: string | undefined;
  walletPublicKey: string | undefined;
  signAndSubmitTransaction: (
    transactionData: InputTransactionData,
  ) => Promise<{ hash: string; payload: any }>;
  getAddressWithNotify: () => string | undefined;
  getAddress: () => string | undefined;
  connect: () => void;
  disconnect: () => void;
};

export let useWallet: () => Adapter;

export let getWalletCtx: () => Adapter | undefined;

export const initWalletCtx = (adapter: {
  hook: typeof useWallet;
  getter: typeof getWalletCtx;
}) => {
  useWallet = adapter.hook;
  getWalletCtx = adapter.getter;
};
