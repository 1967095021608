import { createTokenHub } from '@aries/defi-toolkit/token-hub';
import { EMPTY_ASSET } from '@aries/defi-toolkit/types';
import { mapValues } from 'lodash';
import { ariesApi } from '../api';
import { getProviderHub, onNetworkChange } from '../aptos-provider';
import { fetchTokenList } from './fetcher';

export const APTOS_COIN_ADDRESS = '0x1::aptos_coin::AptosCoin';

export const { useTokenInfoHub, getTokenInfoHub } = createTokenHub(() => {
  const isTestnet = getProviderHub()?.env.isTestnet;
  return fetchTokenList(!!isTestnet);
});

let tAPTrefreshTimer: NodeJS.Timeout | undefined;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
onNetworkChange((_, provider) => {
  clearInterval(tAPTrefreshTimer);

  getTokenInfoHub()?.refreshTokenList();

  const loadBackendPrices = async () => {
    try {
      const oraclePriceMap = await ariesApi.coinInfo.currentInfo.query();
      getTokenInfoHub()?.setCustomizedPriceMap(
        mapValues(oraclePriceMap, v => v.price),
      );
      // eslint-disable-next-line no-empty
    } catch {}
  };

  loadBackendPrices();
  tAPTrefreshTimer = setInterval(() => {
    loadBackendPrices();
  }, 15000);
});

export const EMPTY_TOKEN = {
  ...EMPTY_ASSET,
  address: '',
};
