import { AptosProvider, createAptosSDK } from '@aries-markets/create-sdk';
import { getProviderHub } from '@aries/aptos-defi/common';
import { memoize } from 'lodash';
import './custom-resolver';
import { EconiaDefine, EconiaIdl } from './idl';

const getEconiaSDKMemo = memoize(
  (provider: AptosProvider, program: string) =>
    createAptosSDK<EconiaDefine>({
      provider,
      program,
      idlJSON: EconiaIdl,
    }),
);

export const getEconiaSDK = () => ({
  ...getEconiaSDKMemo(
    getProviderHub()?.provider!,
    getProviderHub()?.ECONIA_PROGRAM!,
  ),
});
