import { createGlobalStore } from '@aries/shared/deps';
import Big from 'big.js';
import { keyBy, mapValues } from 'lodash';
import useSWR from 'swr';
import { extractCoinAddress } from '../utils';
import { useWallet } from '../wallet';
import {
  getProviderHub,
  MAX_GAS_PER_TX,
  useProviderHub,
} from './aptos-provider';
import { getCurrentAptosSDK } from './aptos-sdk';
import { APTOS_COIN_ADDRESS } from './token-info';

const emptyProxy = (rawMap: any): Record<string, { amount: Big }> =>
  new Proxy(rawMap, {
    get: (map, prop: string) => map[prop] ?? { amount: Big(0) },
  });

export const useBalanceHubByAddress = (
  walletAddress: string | undefined,
) => {
  const { currentNetwork } = useProviderHub();
  const { data: balanceMap = emptyProxy({}), mutate } = useSWR(
    ['BalanceHub', walletAddress, currentNetwork.network],
    async () => {
      // Will not return empty value.

      if (walletAddress) {
        const aptos = getCurrentAptosSDK();
        const res = await aptos.coin.CoinStore.all.fromAddress(
          walletAddress,
        );

        return emptyProxy(
          mapValues(
            keyBy(res, i => extractCoinAddress(i.resourceType)),
            v => ({ amount: v.coin.value }),
          ) as Record<string, { amount: Big }>,
        );
      }

      return emptyProxy({});
    },
    {
      refreshInterval: 60 * 1000,
    },
  );

  return { balanceMap, refresh: () => mutate(v => v, true) };
};

export const [useBalanceHub, getBalanceHub] = createGlobalStore(() => {
  const { walletAddress } = useWallet();
  return useBalanceHubByAddress(walletAddress);
});

export const hasEnoughGasOrThrow = () => {
  const aptosBalance =
    getBalanceHub()!.balanceMap[APTOS_COIN_ADDRESS]?.amount ?? Big(0);

  if (aptosBalance.lte(MAX_GAS_PER_TX)) {
    throw `You need to have ${
      MAX_GAS_PER_TX / 10 ** 8
    } APTOS at least to send transaction. ${
      getProviderHub()?.env.isTestnet ? 'Go to Faucet to get Aptos!' : ''
    } ` as any;
  }
};
