import { defineResolver } from '@aries-markets/create-sdk';
import {
  getIterableValueResolver,
  getTableResolver,
} from '@aries/shared/utils/resolvers';

defineResolver(
  'Table',
  async (value: { handle: string; length: string }, ctx) => {
    return getTableResolver(value, ctx);
  },
);

defineResolver(
  'TableWithLength',
  async (value: { inner: { handle: string }; length: string }, ctx) => {
    return getTableResolver(
      { handle: value.inner.handle, length: value.length },
      ctx,
    );
  },
);

defineResolver(
  'Tablist',
  async (
    value: {
      table: { inner: { handle: string }; length: string };
      head: { vec: any[] };
      tail: { vec: any[] };
    },
    ctx,
  ) => {
    const { handle } = value.table.inner;

    return getIterableValueResolver(
      {
        handle,
        headKeyValue: value.head?.vec?.[0],
        tailKeyValue: value.tail?.vec?.[0],
      },
      ctx,
    );
  },
);
