import {
  getBalanceHub,
  getProviderHub,
  getTokenInfoHub,
  hasEnoughGasOrThrow,
} from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { getProfileHub } from '@aries/aptos-defi/lending/data';
import { computeTotal, withSendTxNotify } from '@aries/aptos-defi/utils';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import { bigMin, eventEmitter } from '@aries/shared/utils';
import Big from 'big.js';
import { isNil } from 'lodash';
import { AccountInMarket, OrderBookList } from './data';
import { getEconiaSDK } from './sdk';

export const INTEGRATOR_ADDR =
  '0x2e51979739db25dc987bd24e1a968e45cca0e0daea7cae9121f68af93e8884c9';
const NO_CUSTODIAN = Big(0);
export const NO_RESTRICTION = 0;
export const CANCEL_TAKER = 3;
type ActionSide = 'bid' | 'ask';

export const getMarketActions = ({
  marketId,
  baseCoinType: baseCoin,
  quoteCoinType: quoteCoin,
}: {
  marketId: Big;
  baseCoinType: string;
  quoteCoinType: string;
}) => {
  const s = () => {
    hasEnoughGasOrThrow();
    return getEconiaSDK();
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const market_id = Big(marketId);

  const typeArgs = { BaseType: baseCoin, QuoteCoin: quoteCoin };
  const refreshBalance = () => getBalanceHub()?.refresh();
  const refreshUserAccount = () => {
    eventEmitter.emit('RefreshUserAccount');
  };

  const toBooleanSide = (side: ActionSide) => side === 'ask';

  const getCustodianId = () => {
    return NO_CUSTODIAN;
  };

  const addCollateral = withSendTxNotify(
    async (side: ActionSide, amount: Big) => {
      const walletAddress = getWalletCtx()?.walletAddress;
      if (!walletAddress) {
        throw new Error('Please Connect your wallet first');
      }

      const res = await s()
        .user.depositFromCoinstore(
          {
            custodian_id: getCustodianId(),
            amount,
            market_id,
          },
          { CoinType: side === 'ask' ? baseCoin : quoteCoin },
        )
        .execute();

      await Promise.all([refreshUserAccount(), refreshBalance()]);
      return res;
    },
  );

  const withdrawCollateral = withSendTxNotify(
    async (side: ActionSide, amount: Big) => {
      const res = await s()
        .user.withdrawToCoinstore(
          {
            market_id,
            amount,
          },
          { CoinType: side === 'ask' ? baseCoin : quoteCoin },
        )
        .execute();

      await Promise.all([refreshUserAccount(), refreshBalance()]);
      return res;
    },
  );

  const placeLimitOrder = withSendTxNotify(
    async (side: ActionSide, size: Big, price: Big) => {
      const walletAddress = getWalletCtx()?.walletAddress;
      if (!walletAddress) {
        throw new Error('Please Connect your wallet first');
      }
      const res = await s()
        .market.placeLimitOrderUserEntry(
          {
            market_id: marketId,
            integrator: INTEGRATOR_ADDR,
            side: toBooleanSide(side),
            size,
            price,
            restriction: NO_RESTRICTION,
            self_match_behavior: CANCEL_TAKER,
          },
          { BaseType: baseCoin, QuoteType: quoteCoin },
        )
        .execute();

      await Promise.all([refreshUserAccount()]);

      setTimeout(() => {
        getProfileHub()?.refreshCurrentProfile();
      }, 0);

      return res;
    },
  );

  const fillMarketOrder = withSendTxNotify(
    async ({
      side,
      amount,
      orderbookList,
    }: {
      side: ActionSide;
      amount: number;
      orderbookList: OrderBookList;
    }) => {
      const tokenMap = getTokenInfoHub()?.tokenMap;
      const walletAddress = getWalletCtx()?.walletAddress;
      const currentMarket = orderbookList.find(
        o => o.marketId === marketId.toString(),
      );
      if (!walletAddress) {
        throw new Error('Please Connect your wallet first');
      }
      if (!tokenMap || !currentMarket) {
        throw new Error('Network error');
      }
      const baseAsset = tokenMap[baseCoin];
      const isBuy = side === 'bid';
      const { lotSize } = currentMarket;

      const totalAmount = baseAsset.toLamports(amount);
      const targetOrders =
        (isBuy ? currentMarket.value?.asks : currentMarket.value?.bids) ??
        [];
      const fillableAmount = computeTotal(targetOrders, o => o.lamports);
      const size = bigMin(fillableAmount, totalAmount).div(lotSize);

      const res = await s()
        .market.placeMarketOrderUserEntry(
          {
            market_id: marketId,
            integrator: INTEGRATOR_ADDR,
            direction: toBooleanSide(side),
            size,
            self_match_behavior: CANCEL_TAKER,
          },
          { BaseType: baseCoin, QuoteType: quoteCoin },
        )
        .execute();

      await Promise.all([refreshUserAccount()]);

      setTimeout(() => {
        getProfileHub()?.refreshCurrentProfile();
      }, 0);

      return res;
    },
  );

  const withdrawAllAsset = async (userAccount: AccountInMarket) => {
    const { baseAvailable, quoteAvailable } = userAccount ?? {
      baseAvailable: Big(0),
      quoteAvailable: Big(0),
    };
    const res1 =
      baseAvailable.eq(0) ||
      (await withSendTxNotify(async () => {
        return s()
          .user.withdrawToCoinstore(
            {
              market_id: marketId,
              amount: baseAvailable,
            },
            { CoinType: baseCoin },
          )
          .execute();
      })());
    if (!res1) {
      return false;
    }
    const res =
      quoteAvailable.eq(0) ||
      (await withSendTxNotify(async () => {
        return s()
          .user.withdrawToCoinstore(
            {
              market_id: marketId,
              amount: quoteAvailable,
            },
            { CoinType: quoteCoin },
          )
          .execute();
      })());

    Promise.all([refreshUserAccount()]);

    return res;
  };

  const cancelLimitOrder = withSendTxNotify(
    async (side: ActionSide, orderId: string) => {
      const res = await s()
        .market.cancelOrderUser({
          market_id: marketId,
          market_order_id: Big(orderId),
          side: toBooleanSide(side),
        })
        .execute();

      await Promise.all([refreshUserAccount()]);

      return res;
    },
  );

  const swap = withSendTxNotify(
    async (lamports: Big, side: ActionSide, allowBorrow?: boolean) => {
      const MARKET_HOST = getProviderHub()?.ECONIA_PROGRAM;
      if (isNil(MARKET_HOST)) {
        throw new Error('Please connect wallet first');
      }
      const res = await getAriesSDK()
        .controller.swap(
          {
            profile_name: getProfileHub()?.currentProfile!.name!,
            style: !toBooleanSide(side),
            host: MARKET_HOST,
            amount: lamports,
            allow_borrow: allowBorrow ?? false,
            minimum_out: Big(0),
            market_id,
          },
          {
            BaseCoin: typeArgs.BaseType,
            QuoteCoin: typeArgs.QuoteCoin,
          },
        )
        .execute();

      await Promise.all([refreshUserAccount()]);
      // To refresh after modal close
      setTimeout(() => {
        getProfileHub()?.refreshCurrentProfile();
      }, 0);

      return res;
    },
  );

  return {
    placeLimitOrder,
    cancelLimitOrder,
    fillMarketOrder,
    addCollateral,
    withdrawCollateral,
    swap,
    withdrawAllAsset,
  };
};
