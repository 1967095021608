import { getAptosSDK as getAptosSDKInner } from '@aries-markets/aptos-sdk';
import { AptosProvider } from '@aries-markets/create-sdk';
import { memoize } from 'lodash';
import { getProviderHub } from './aptos-provider';

const getSDK = memoize((provider: AptosProvider) =>
  getAptosSDKInner(provider),
);

export const getCurrentAptosSDK = () => getSDK(getProviderHub()!.provider);
