import { camelCaseKey } from '@aries-markets/create-sdk';
import { useProviderHub } from '@aries/aptos-defi/common';
import {
  parseTypeInfo,
  typeInfoToStr,
  withSendTxNotify,
} from '@aries/aptos-defi/utils';
import { useWallet } from '@aries/aptos-defi/wallet';
import Big from 'big.js';
import { compact, keyBy, mapValues } from 'lodash';
import { useEffect } from 'react';
import useSWR from 'swr';
import { getEconiaSDK } from '../sdk';
import { parseOrderId } from './orderbook';
import { useOrderbookList } from './orderbook/list';

import { eventEmitter } from '@aries/aptos-defi/../shared/utils';
import * as $ from '@manahippo/move-to-ts';
import { u128, U64, u64, U8, u8 } from '@manahippo/move-to-ts';
import { MarketAccounts } from '../sdk/idl/user';

type MarketInfo = {
  marketId: Big;
  baseCoinType: string;
  quoteCoinType: string;
};

export const NO_CUSTODIAN = Big(0);
export const useUserAccounts = (
  orderbookList: ReturnType<typeof useOrderbookList>['orderbookList'],
) => {
  const { walletAddress } = useWallet();
  const { currentNetwork } = useProviderHub();

  const {
    data: accountByMarketPair = {},
    mutate,
    isValidating: loading,
  } = useSWR(
    [
      'EconiaUserOrderBook',
      walletAddress,
      currentNetwork,
      orderbookList.length,
    ],
    async () => {
      if (!walletAddress) {
        return {};
      }
      const sdk = getEconiaSDK();

      let res: MarketAccounts | undefined;
      try {
        res = await sdk.user.MarketAccounts.fetch.fromAddress(
          walletAddress,
          // '0x2517feee72e6d751024c2b59c3aac3a587053893abacddbcc02ef09247d524ed',
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}

      const accountList =
        (await res?.map.fromKeys(
          orderbookList.map(o =>
            getMarketAccountId(
              u64(o.marketId),
              u64(NO_CUSTODIAN.toString()),
            ),
          ),
        )) ?? [];

      const accounts = await Promise.all(
        accountList.map(async _v => {
          const v: typeof _v = camelCaseKey(_v);
          const baseType = parseTypeInfo(v.value.baseType);
          const quoteType = parseTypeInfo(v.value.quoteType);

          const { asks: asksTable, bids: bidsTable } = v.value;
          const asks = (await asksTable.fetch()).map(a => a.value);
          const bids = compact(
            (await bidsTable.fetch()).map(a => a.value),
          );
          return {
            key: `${typeInfoToStr(baseType)}/${typeInfoToStr(quoteType)}-${
              v.key
            }`,
            value: {
              ...v.value,
              baseType,
              quoteType,
              asks,
              bids,
            },
          };
        }),
      );

      return mapValues(
        keyBy(accounts, a => a.key.toString()),
        ({ value: { asks, bids, ...value }, key }) => {
          const { lotSize, tickSize } = value;
          return {
            ...value,
            marketAccountId: key,
            canWithdraw:
              value.baseAvailable?.gt(0) || value.quoteAvailable?.gt(0),
            asks:
              asks.map(v => {
                return {
                  priceLamports: parseOrderId(v.marketOrderId.toString())
                    .price.div(lotSize)
                    .mul(tickSize),
                  lamports: v.size.mul(lotSize),
                  ...v,
                };
              }) ?? [],
            bids:
              bids.map(v => ({
                priceLamports: parseOrderId(v.marketOrderId.toString())
                  .price.div(lotSize)
                  .mul(tickSize),
                lamports: Big(v.size).mul(lotSize),
                ...v,
              })) ?? [],
          };
        },
      );
    },
    { refreshInterval: 10 * 1000 },
  );

  const getAccountByMarket = (
    market: MarketInfo,
  ): typeof accountByMarketPair[string] | null => {
    return (
      accountByMarketPair[
        `${market.baseCoinType}/${
          market.quoteCoinType
        }-${getMarketAccountId(
          u64(market.marketId.toString()),
          u64(NO_CUSTODIAN.toString()),
        )}`
      ] ?? null
    );
  };

  const registerAccount = async (marketInfo: MarketInfo) => {
    const sdk = getEconiaSDK();
    const account = getAccountByMarket(marketInfo);
    if (account) {
      return true;
    }
    return withSendTxNotify(
      async () =>
        sdk.user
          .registerMarketAccount(
            {
              market_id: marketInfo.marketId,
              custodian_id: NO_CUSTODIAN,
            },
            {
              BaseType: marketInfo.baseCoinType,
              QuoteType: marketInfo.quoteCoinType,
            },
          )
          .execute(),
      { sendingTitle: 'Registering your Econia account first' },
    )();
  };

  const refresh = () => mutate(v => (v ? { ...v } : {}), true);
  useEffect(() => {
    if (walletAddress) {
      refresh();

      eventEmitter.addListener('RefreshUserAccount', refresh);
      return () => {
        eventEmitter.removeListener('RefreshUserAccount', refresh);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return {
    accountByMarket: accountByMarketPair,
    loading,
    getAccountByMarket,
    registerAccount,
    refresh,
  };
};

export type AccountInMarket = ReturnType<
  ReturnType<typeof useUserAccounts>['getAccountByMarket']
>;

export const SHIFT_MARKET_ID: U8 = u8('64');
export const getMarketAccountId = (
  maketId: U64,
  custodianId: U64,
): string => {
  return u128($.copy(maketId))
    .shl($.copy(SHIFT_MARKET_ID))
    .or(u128($.copy(custodianId)))
    .value.toString();
};
