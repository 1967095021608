import { defineResolver } from '@aries-markets/create-sdk';
import { getIterableValueResolver } from './iterable-table';
import { getSimpleMapResolver } from './profiles';
import { getTableResolver } from './table';

defineResolver('ref_map::SimpleMap', getSimpleMapResolver);
defineResolver('Map', getSimpleMapResolver);

defineResolver(
  'Table',
  async (value: { handle: string; length: string }, ctx) => {
    return getTableResolver(value, ctx);
  },
);

defineResolver(
  'IterableTable',
  async (
    value: {
      inner: { inner: { handle: string }; length: string };
      head: { vec: any[] };
      tail: { vec: any[] };
    },
    ctx,
  ) => {
    const { handle } = value.inner.inner;

    return getIterableValueResolver(
      {
        handle,
        headKeyValue: value.head?.vec?.[0],
        tailKeyValue: value.tail?.vec?.[0],
      },
      ctx,
    );
  },
);

defineResolver('Option', async (value: { vec: any[] }) => {
  return value.vec[0];
});

defineResolver(
  'TableWithLength',
  async (
    value: { inner: { inner: { handle: string }; length: string } },
    ctx,
  ) => {
    return getTableResolver(
      { handle: value.inner.inner.handle, length: value.inner.length },
      ctx,
    );
  },
);
