import Big from 'big.js';

export type AriesWrapperDefine = {
  wrapped_controller: [
    {},
    {
      removeCollateral: {
        args: { profile_name: string; amount: Big; pyth_vaas: number[][] };
        typeArgs: { Coin0: string };
      };
      withdraw: {
        args: {
          profile_name: string;
          amount: Big;
          allow_borrow: boolean;
          pyth_vaas: number[][];
        };
        typeArgs: { Coin0: string };
      };
      hippoSwap: {
        args: {
          profile_name: string;
          allow_borrow: boolean;
          amount: Big;
          minimum_out: Big;
          num_steps: number;
          first_dex_type: number;
          first_pool_type: Big;
          first_is_x_to_y: boolean;
          second_dex_type: number;
          second_pool_type: Big;
          second_is_x_to_y: boolean;
          third_dex_type: number;
          third_pool_type: Big;
          third_is_x_to_y: boolean;
          pyth_vaas: number[][];
        };
        typeArgs: {
          InCoin: string;
          Y: string;
          Z: string;
          OutCoin: string;
          E1: string;
          E2: string;
          E3: string;
        };
      };
      claimRewards: {
        args: { profile_name: string };
        typeArgs: { RewardCoin: string };
      };
    },
  ];
};

export const AriesWrapperIdl = {
  modules: [
    {
      name: 'wrapped_controller',
      displayName: 'wrapped_controller',
      methods: [
        {
          name: 'remove_collateral',
          displayName: 'removeCollateral',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            {
              name: 'pyth_vaas',
              jsType: 'number[][]',
              moveType: 'vector',
            },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'withdraw',
          displayName: 'withdraw',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
            {
              name: 'pyth_vaas',
              jsType: 'number[][]',
              moveType: 'vector',
            },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'hippo_swap',
          displayName: 'hippoSwap',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'minimum_out', jsType: 'Big', moveType: 'u64' },
            { name: 'num_steps', jsType: 'number', moveType: 'u8' },
            { name: 'first_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'first_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'first_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
            { name: 'second_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'second_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'second_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
            { name: 'third_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'third_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'third_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
            {
              name: 'pyth_vaas',
              jsType: 'number[][]',
              moveType: 'vector',
            },
          ],
          typeArgs: [
            { name: 'InCoin' },
            { name: 'Y' },
            { name: 'Z' },
            { name: 'OutCoin' },
            { name: 'E1' },
            { name: 'E2' },
            { name: 'E3' },
          ],
        },
        {
          name: 'claim_rewards',
          displayName: 'claimRewards',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
          ],
          typeArgs: [{ name: 'RewardCoin' }],
        },
      ],
      structs: [],
    },
  ],
};
