/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-await-in-loop */
import {
  AptosProvider,
  camelCaseKey,
  deserialize,
  MoveValueType,
  StructValueType,
} from '@aries-markets/create-sdk';
import { compact, isObject, isString } from 'lodash';
import { Big, delay } from '../index';

// TODO: we should utilise schema info for serialisation instead of intristics
const serializeTableKey = <K extends string | StructValueType>(
  keyType: K,
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  keyTypeAddress: string = '0x1',
) => {
  const serializeTypeInfo = (typeInfo: TypeInfo) => {
    return {
      account_address: typeInfo.account_address,
      // reserve
      module_name: toHex(typeInfo.module_name),
      // `ReserveCoinContainer<0x1::aptos_coin::AptosCoin>`
      struct_name: toHex(typeInfo.struct_name),
    };
  };

  if (keyType === 'TypeInfo') {
    return (typeInfo: TypeInfo) => ({
      key: serializeTypeInfo(typeInfo),
      key_type: '0x1::type_info::TypeInfo',
    });
  }

  if (keyType === 'u64') {
    return (key: u64) => ({
      key: Big(key),
      key_type: 'u64',
    });
  }

  if (keyType === 'u128') {
    return (key: u128) => ({
      key: Big(key),
      key_type: 'u128',
    });
  }

  throw Error(`unsupported type: ${keyType}`);
};

// Define inner custom runtime deserilizer here
export const getTableResolver = (
  value: { handle: string; length: string },
  ctx: {
    path?: string;
    schema: MoveValueType;
    provider: AptosProvider;
    program: string;
  },
) => {
  if (isString(ctx.schema)) {
    return value;
  }
  const { typeArgs: [keyType, valueType] = [] } = ctx.schema;
  if (!isObject(valueType)) {
    return value;
  }

  const tableValueType = `${ctx.program}::${valueType.module}::${valueType.structName}`;
  const buildParams = serializeTableKey(keyType, ctx.program);

  const fromKey = async (
    keyValue: Parameters<typeof buildParams>[0],
    options?: { version?: number | bigint },
  ) => {
    try {
      const params = {
        // @ts-ignore-next-line
        ...buildParams(keyValue),
        value_type: tableValueType,
      };
      const res = await ctx.provider.client.getTableItem(
        value.handle,
        params,
        {
          ledgerVersion: options?.version,
        },
      );
      const parsed = await deserialize(res, {
        ...ctx,
        schema: (valueType as StructValueType).schema,
      });

      return camelCaseKey(parsed);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };

  const fromKeys = async (
    keys: Array<Parameters<typeof buildParams>[0]>,
    options?: { version?: number | bigint; noDelay?: boolean },
  ) => {
    const arr = await Promise.all(
      keys.map(async (key, index) => {
        // eslint-disable-next-line no-promise-executor-return
        if (!options?.noDelay) {
          await delay(index * 15);
        }

        const res = await fromKey(
          isObject(key) ? { ...key } : key,
          options,
        );
        if (!res) {
          return null;
        }

        return {
          key,
          value: res,
        };
      }),
    );

    return compact(arr);
  };

  return {
    fromKey,
    fromKeys,
    length: Number(value.length),
    handle: value.handle,
  };
};

const toHex = (str: string) => Buffer.from(str, 'utf-8').toString('hex');

type TypeInfo = {
  account_address: string;
  module_name: string;
  struct_name: string;
};

type u64 = string;
type u128 = string;
