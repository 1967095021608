import { useWallet as useWalletInner } from '@aptos-labs/wallet-adapter-react';
import { createGlobalStore } from '@aries/shared/deps';
import { useEffect, useRef, useState } from 'react';

const WALLET_RECORD_KEY = 'last_connected_wallet_1';

export const [useWallet] = createGlobalStore(() => {
  const [walletModalShow, setWalletModalShow] = useState(false);
  const [walletCtx, setWalletCtx] = useState<{
    adapter: ReturnType<typeof useWalletInner>;
    address: string;
  } | null>(null);
  const walletCtxRef = useRef(walletCtx);
  walletCtxRef.current = walletCtx;

  const disconnect = () => {
    if (!walletCtx) return;

    walletCtx.adapter.disconnect();
    localStorage.removeItem(WALLET_RECORD_KEY);
    setWalletCtx(null);
  };

  useEffect(() => {
    if (walletCtx) {
      localStorage.setItem(
        WALLET_RECORD_KEY,
        walletCtx.adapter.wallet?.name ?? '',
      );
    }
  }, [walletCtx]);

  return {
    walletModalShow,
    setWalletModalShow,
    walletCtx,
    walletCtxRef,
    setWalletCtx: (ctx: typeof walletCtx) => {
      walletCtxRef.current = ctx;
      setWalletCtx(ctx);
    },
    disconnect,
  };
});
