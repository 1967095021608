import { Asset, Big, EMPTY_ASSET } from '@aries/defi-toolkit/types';
import { useProfile } from '@aries/lending-fe-hooks';
import {
  Button,
  CenterBetweenRow,
  CenterCol,
  CenterRow,
  Col,
  createIcon,
  EndBetweenRow,
  EndCol,
  EndRow,
  Row,
  Slider,
  Spin,
  Txt,
} from '@aries/mobile-core/components';
import { openModal } from '@aries/mobile-core/Smoothy';
import { Trans, useTranslation } from '@aries/shared/locale';
import { useOrderbookApp } from '@aries/trading-fe-hooks';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { isArray, isNil } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import {
  IconSecondDown,
  IconSecondSwap,
  IconwarningInfo,
  ProgressBar,
  TokenIcon,
  TokenInput,
} from '~/components';
import { openReserveAction, openSelectToken } from '~/services';
import {
  ResultCard,
  RouteCard,
  SwapCard,
  SwapIcon,
  SwapIconBox,
  TransactionSettings,
} from './util';

const { marginXS, marginSM, marginXXS, borderRadiusSM } = globalToken;

const SLIDER_IMG = createIcon(require('./slider.png'));

export const Content: React.FunctionComponent<{
  scrollToBottom: () => void;
}> = ({ scrollToBottom }) => {
  const { t } = useTranslation();
  const { currentProfile } = useProfile();
  const {
    colorTextSuccess,
    colorTextWarning,
    colorTextCritical,
    colorInteractive,
  } = colorConfig;
  const { mode } = useThemeMode();
  const { useSwapContext } = useOrderbookApp();
  const {
    fromList,
    toList,
    allowBorrow,
    slippage,
    setSlippage,
    currentPair: {
      hasRoutes,
      fromAmount: fromAmountNum,
      toAmount,
      borrow,
      fromAsset,
      maxFromAmount,
      toAsset,
      toAssetBalance,
      insufficientFunds,
      activeRoute,
      routes,
      routeLoading,
      changeActiveRoute,
      CEXPrices,
    },
    changeFromAmount,
    changeFromAsset,
    changeToAsset,
    changeToAmount,
    changeDirection,
  } = useSwapContext();

  const {
    willBorrowAmount,
    borrowApy,
    borrowApyPositive,
    borrowableFromAmount,
  } = borrow;
  const [rateInverted, setInverted] = useState(false);
  const rate = activeRoute?.exchangeRate ?? 0;
  const fromAmount = useMemo(() => Big(fromAmountNum), [fromAmountNum]);

  const getTokenSelect = (
    list: typeof fromList,
    val: Asset | undefined,
    onChange: (asset: Asset) => void,
  ) => {
    return (
      <TouchableOpacity
        onPress={() => {
          openSelectToken(onChange, { list, showDeposit: allowBorrow });
        }}
      >
        <CenterRow style={{ minWidth: 50, justifyContent: 'flex-end' }}>
          <>
            {val && (
              <>
                <TokenIcon size={20} asset={val} />
                <Txt bold ml={3}>
                  {val.symbol}
                </Txt>
              </>
            )}
            <IconSecondDown size={13} style={{ marginLeft: 5 }} />
          </>
        </CenterRow>
      </TouchableOpacity>
    );
  };

  const prevFromAmountNum = useRef(0);
  useEffect(() => {
    if (
      prevFromAmountNum.current === 0 ||
      (prevFromAmountNum.current === -1 && fromAsset && toAsset)
    ) {
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    }
    if (fromAsset && toAsset) {
      prevFromAmountNum.current = fromAmountNum;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromAsset, toAsset, fromAmount]);

  return (
    <Col>
      <SwapCard style={{ paddingTop: 5 }}>
        <CenterBetweenRow mb={4} pt={0}>
          <Txt h4 bold>
            {t`You Pay`}
          </Txt>
          <CenterRow>
            <Button
              type="text"
              ml={5}
              onPress={() => {
                openModal({
                  renderHeader: () => (
                    <Txt h4>
                      <Trans i18nkey="Swap Setting" />
                    </Txt>
                  ),
                  renderContent: ({ close }) => (
                    <TransactionSettings
                      currentValue={slippage}
                      onChange={v => {
                        setSlippage(v);
                        close();
                      }}
                    />
                  ),
                  bodyStyle: { height: 'fit-content' },
                  hasContentMargin: false,
                });
              }}
              title={
                <>
                  <SLIDER_IMG size={24} mr={2} />
                  <Txt c2>{slippage}%</Txt>
                </>
              }
            />
          </CenterRow>
        </CenterBetweenRow>

        <Row mb={10}>
          <TokenInput
            value={
              fromAsset && toAsset && !hasRoutes ? Big(0) : Big(fromAmount)
            }
            onAmountChange={v => changeFromAmount(v.toNumber())}
            tokenInfo={fromAsset ?? EMPTY_ASSET}
            maxAmount={new Big('999999999999')}
          >
            <CenterRow style={{ width: '100%' }}>
              <TokenInput.Input
                placeholder="0.00"
                suffix={getTokenSelect(fromList, fromAsset, a =>
                  changeFromAsset(a),
                )}
                disabled={fromAsset && toAsset && !hasRoutes}
              />
            </CenterRow>
          </TokenInput>
        </Row>
        <Slider
          style={{ marginBottom: 10, marginLeft: 4, marginRight: 16 }}
          max={100}
          min={0}
          value={
            maxFromAmount === 0 ? 0 : (fromAmountNum / maxFromAmount) * 100
          }
          onChangeValue={pct => {
            changeFromAmount((pct / 100) * maxFromAmount);
          }}
          disabled={fromAsset && toAsset && !hasRoutes}
          marks={
            allowBorrow
              ? (() => {
                  const { maxLeverage } = borrow;
                  const marks: { step: number; label: string }[] = [];

                  for (let i = 0; i <= Math.round(maxLeverage); i++) {
                    marks.push({
                      step: (100 * i) / maxLeverage,
                      label: `${i}x`,
                    });
                  }

                  marks.push({
                    step: 100,
                    label: `${maxLeverage.toFixed(1)}x`,
                  });
                  return marks;
                })()
              : [
                  { step: 0, label: '0%' },
                  { step: 25, label: '25%' },
                  { step: 50, label: '50%' },
                  { step: 75, label: '75%' },
                  { step: 100, label: '100%' },
                ]
          }
        />
        <CenterBetweenRow mb={10}>
          <Col flex={2}>
            {fromAsset ? (
              maxFromAmount > 0 ? (
                <Txt c3 h2>
                  {allowBorrow ? t`Available` : t`Wallet`}:{' '}
                  {maxFromAmount ?? 0}
                </Txt>
              ) : (
                <Txt h2 critical>
                  {toAsset
                    ? t`Insufficient ${fromAsset.symbol} ${
                        allowBorrow ? t`deposit` : t`balance`
                      }`
                    : t`Select target token to see borrow ability`}
                </Txt>
              )
            ) : (
              <Txt c2>{t`Select a token`}</Txt>
            )}
          </Col>
          <CenterRow flex={1}>
            <Button
              title="half"
              type="ghost"
              active
              onPress={() => {
                changeFromAmount(maxFromAmount / 2);
              }}
              w={45}
              h={27}
              titleStyle={{ fontSize: 13 }}
              disabled={fromAsset && toAsset && !hasRoutes}
            />
            <Button
              title="max"
              type="ghost"
              active
              onPress={() => {
                changeFromAmount(maxFromAmount);
              }}
              h={27}
              w={45}
              ml={10}
              titleStyle={{ fontSize: 13 }}
              disabled={fromAsset && toAsset && !hasRoutes}
            />
          </CenterRow>
        </CenterBetweenRow>
      </SwapCard>

      <CenterCol style={{ zIndex: 100 }}>
        <SwapIconBox
          style={{ top: -24 - marginSM / 2 }}
          onPress={() => {
            changeDirection();
          }}
          activeOpacity={0.7}
        >
          <SwapIcon>
            <IconSecondSwap size={26} />
          </SwapIcon>
        </SwapIconBox>
      </CenterCol>

      <SwapCard>
        <Txt h4 bold mt={5} mb={16}>
          {t`You Receive`}
        </Txt>

        <Col mb={8}>
          <TokenInput
            value={Big(toAmount)}
            onAmountChange={v => changeToAmount(v.toNumber())}
            tokenInfo={toAsset ?? EMPTY_ASSET}
            maxAmount={Big(1000000)}
          >
            <CenterRow style={{ width: '100%' }}>
              <TokenInput.Input
                placeholder="0.00"
                suffix={getTokenSelect(toList, toAsset, a =>
                  changeToAsset(a),
                )}
                disabled={fromAsset && toAsset && !hasRoutes}
              />
            </CenterRow>
          </TokenInput>
        </Col>

        <Col mt={5}>
          {toAsset ? (
            <Txt c3 h2>
              {allowBorrow ? t`Deposited` : t`Wallet`}:{' '}
              {toAssetBalance?.amountNum ?? 0}
            </Txt>
          ) : (
            <Txt c2>{t`Select a token`}</Txt>
          )}
          {allowBorrow && (
            <CenterRow mt={4}>
              <IconwarningInfo style={{ marginRight: 4 }} />
              <Txt warning h1>
                {t`Token will be added to your deposits instead of wallet`}
              </Txt>
            </CenterRow>
          )}
        </Col>
      </SwapCard>
      <ResultCard
        isVisible={
          !!(fromAsset && toAsset && hasRoutes && fromAmount.gt(0))
        }
      >
        {fromAsset && toAsset && fromAmount.gt(0) && (
          <Col>
            <Txt h3 mb={16}>
              {routeLoading && !routes.length
                ? t`Finding routes`
                : t`Total ${routes.length.toString()} routes available`}
            </Txt>
            {routeLoading && !routes.length ? (
              <CenterRow
                style={{
                  justifyContent: 'center',
                }}
              >
                <Spin size={30} />
              </CenterRow>
            ) : (
              <Col>
                <Col h={330}>
                  <FlatList
                    style={{ flex: 1 }}
                    data={routes}
                    renderItem={({ item, index }) => {
                      const { desc, receive, dexes } = item;
                      const isBest = index === 0;

                      return (
                        <React.Fragment key={item.name + index}>
                          {index === 0 && (
                            <Row
                              style={{
                                backgroundColor:
                                  colorConfig.colorInteractive[mode],
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                padding: 1,
                                paddingLeft: 8,
                              }}
                            >
                              <Txt h1>{t`Best Price`}</Txt>
                            </Row>
                          )}
                          <TouchableOpacity
                            onPress={() => {
                              changeActiveRoute(item);
                            }}
                          >
                            <RouteCard
                              key={desc}
                              isActive={item.id === activeRoute?.id}
                              style={{
                                borderTopLeftRadius: isBest ? 0 : 5,
                                borderTopRightRadius: isBest ? 0 : 5,
                              }}
                            >
                              <EndBetweenRow flex={1}>
                                <Col>
                                  <Txt h2 bold>
                                    {receive}
                                  </Txt>
                                  <Txt h1 c2 mt={marginXXS}>
                                    {desc}
                                  </Txt>
                                </Col>
                                <EndRow style={{ alignItems: 'center' }}>
                                  {dexes?.map((splitDexes, index) => {
                                    const dexesInStep = isArray(splitDexes)
                                      ? splitDexes
                                      : [{ ...splitDexes, pct: 1 }];
                                    return (
                                      <CenterRow key={index}>
                                        <CenterRow mr={4}>
                                          {dexesInStep.map(
                                            (dex, dexIndex) => {
                                              const Logo = dex.logo;
                                              return (
                                                <Logo
                                                  style={{
                                                    height: 20,
                                                    width: 20,
                                                    marginLeft:
                                                      dexIndex !== 0
                                                        ? -4
                                                        : 0,
                                                    borderRadius: '50%',
                                                  }}
                                                  className="dex-icon"
                                                  key={dexIndex}
                                                />
                                              );
                                            },
                                          )}
                                        </CenterRow>
                                        {index !== dexes.length - 1 && (
                                          <Txt h3 mr={4}>
                                            x
                                          </Txt>
                                        )}
                                      </CenterRow>
                                    );
                                  })}
                                </EndRow>
                              </EndBetweenRow>
                            </RouteCard>
                          </TouchableOpacity>
                        </React.Fragment>
                      );
                    }}
                  />
                </Col>
                <Col mt={16}>
                  {[
                    {
                      label: t`Rate`,
                      value: (
                        <EndCol>
                          <CenterRow>
                            <Txt h2 c1 mr={marginXXS} mt={3}>
                              {!rateInverted
                                ? `1${
                                    fromAsset.symbol
                                  } ≈ ${toAsset.formatAmount(rate)}`
                                : `1${
                                    toAsset.symbol
                                  } ≈ ${fromAsset.formatAmount(
                                    rate && 1 / rate,
                                  )}`}
                            </Txt>
                            <TouchableOpacity
                              onPress={() => {
                                setInverted(inverted => !inverted);
                              }}
                            >
                              <CenterRow
                                style={{
                                  transform: [{ rotate: '90deg' }],
                                }}
                              >
                                <IconSecondSwap size={20} />
                              </CenterRow>
                            </TouchableOpacity>
                          </CenterRow>
                          {CEXPrices.map(
                            (
                              { name, comparePercentage, isPositive, uri },
                              index,
                            ) => (
                              <Row key={index} mt={marginXS}>
                                <Txt
                                  h2
                                  color={
                                    !isPositive || comparePercentage < 1
                                      ? colorTextSuccess[mode]
                                      : comparePercentage < 5
                                      ? colorTextWarning[mode]
                                      : colorTextCritical[mode]
                                  }
                                >
                                  {isPositive &&
                                    comparePercentage < 1 &&
                                    'Within'}{' '}
                                  {comparePercentage.toFixed(2)}%{' '}
                                  {!isPositive && 'better than'}
                                  {isPositive &&
                                    comparePercentage > 1 &&
                                    'worse than'}
                                </Txt>
                                <Txt h2 ml={marginXXS}>
                                  <a
                                    href={uri}
                                    target="_blank"
                                    onClick={e => e.stopPropagation()}
                                    rel="noreferrer"
                                    style={{
                                      color: colorInteractive[mode],
                                    }}
                                  >
                                    {name}
                                  </a>
                                </Txt>
                              </Row>
                            ),
                          )}
                        </EndCol>
                      ),
                    },
                    ...(!isNil(activeRoute)
                      ? !isNil(activeRoute.minReceive)
                        ? [
                            {
                              label: t`Expected Output`,
                              value: `${toAsset.formatAmount(
                                activeRoute.receive || 0,
                              )}`,
                            },
                            {
                              label: t`Minimum Receive`,
                              value: `${toAsset.formatAmount(
                                activeRoute.minReceive || 0,
                              )}`,
                            },
                          ]
                        : [
                            {
                              label: t`Expected Cost`,
                              value: `${toAsset.formatAmount(
                                activeRoute.cost || 0,
                              )}`,
                            },
                            {
                              label: t`Max Cost`,
                              value: `${toAsset.formatAmount(
                                activeRoute.maxCost || 0,
                              )}`,
                            },
                          ]
                      : []),
                    {
                      label: t`Slippage Tolerance`,
                      value: `${slippage}%`,
                      color:
                        slippage < 5
                          ? colorTextSuccess[mode]
                          : colorTextCritical[mode],
                    },
                  ].map(({ label, value, color }) => (
                    <CenterBetweenRow mb={4} key={label}>
                      <Txt h2 c2>
                        {label}
                      </Txt>
                      <Txt
                        h2
                        style={{
                          color:
                            color ?? colorConfig.colorTextQuaternary[mode],
                        }}
                      >
                        {value}
                      </Txt>
                    </CenterBetweenRow>
                  ))}
                </Col>
              </Col>
            )}
          </Col>
        )}
      </ResultCard>
      <ResultCard isVisible={allowBorrow}>
        <Txt h3 mb={12}>
          {t`Borrow ${fromAsset?.symbol ?? ''}`}
        </Txt>
        <CenterBetweenRow mb={4}>
          <Txt h2 c3>
            {t`Borrowing Amount`}
          </Txt>
          <Txt h2 color={colorInteractive[mode]}>
            {willBorrowAmount} {`${fromAsset?.symbol ?? ''}`}
          </Txt>
        </CenterBetweenRow>

        <CenterBetweenRow mb={4}>
          <Txt h2 c3>
            {t`Borrowable`}
          </Txt>
          <Txt h2>
            {borrowableFromAmount} {`${fromAsset?.symbol ?? ''}`}
          </Txt>
        </CenterBetweenRow>
        <CenterBetweenRow mb={4}>
          <Txt h2 c3>
            {t`APY`}
          </Txt>
          <Txt critical={borrowApyPositive} success>
            {borrowApy}
          </Txt>
        </CenterBetweenRow>
        <CenterBetweenRow>
          <Txt h2 c3>
            {t`Borrow Power`}
          </Txt>
          <CenterRow>
            <ProgressBar
              pct={currentProfile?.summary.borrowingPowerPct ?? 0}
              style={{ width: 150, borderRadius: borderRadiusSM }}
            />
            <Txt ml={3}>
              {currentProfile?.summary.borrowingPowerPct ?? 0}%
            </Txt>
          </CenterRow>
        </CenterBetweenRow>
      </ResultCard>

      <Button
        onPress={() => {
          if (fromAsset && insufficientFunds) {
            openReserveAction(fromAsset.id);
          } else {
            activeRoute && activeRoute.swap();
          }
        }}
        disabled={
          !fromAsset ||
          !toAsset ||
          fromAmount.eq(0) ||
          (!allowBorrow && insufficientFunds) ||
          (!insufficientFunds &&
            (!activeRoute || activeRoute.receive === 0))
        }
        mb={12}
        mt={8}
        title={
          activeRoute && activeRoute.receive === 0
            ? t`Unable to find a route to swap`
            : !fromAsset
            ? t`Select a token to swap`
            : !toAsset
            ? t`Select a token to swap to`
            : !hasRoutes
            ? t`No available routes`
            : fromAmountNum === 0
            ? t`Input the amount to swap`
            : insufficientFunds
            ? allowBorrow
              ? t`Deposit ${fromAsset?.symbol ?? '-'} to swap`
              : t`Insufficient Funds`
            : t`Swap`
        }
      />
    </Col>
  );
};
