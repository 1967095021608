import { ReserveDetails } from '@aries-markets/aries-tssdk';
import { getTokenInfoHub, useProviderHub } from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { computeTotal, typeInfoToStr } from '@aries/aptos-defi/utils';
import { getMax, getMin } from '@aries/defi-toolkit/utils';
import { createGlobalStore } from '@aries/shared/deps';
import { bigMin, delay } from '@aries/shared/utils';
import Big from 'big.js';
import { compact, keyBy, mapValues, partition } from 'lodash';
import { useMemo } from 'react';
import useSWR from 'swr';
import { FarmingType, getReserveConfig } from '../../config';
import { Reward } from './interface';

export const [useReserves, getReserves] = createGlobalStore(() => {
  const { currentNetwork, env, ARIES_PROGRAM } = useProviderHub();

  const getReserveTypes = () =>
    getReserveConfig(env).map(config => {
      const [address, module, struct] = config.coinAddress.split('::');
      return {
        ...config,
        typeInfo: {
          module_name: module,
          struct_name: struct,
          account_address: address,
        },
      };
    });

  const { data: reserveMeta } = useSWR(
    ['ReserveMeta', currentNetwork.name],
    async () => {
      const sdk = getAriesSDK();
      const reservesTable = await sdk.reserve.Reserves.fetch.fromProgram();
      return reservesTable;
    },
    { revalidateOnFocus: false },
  );

  const {
    data: reserves = [],
    mutate: mutateStats,
    isValidating,
  } = useSWR(
    ['Reserves', reserveMeta?.stats],
    async () => {
      const reserveTypes = getReserveTypes();
      const res =
        (await reserveMeta?.stats.fromKeys(
          reserveTypes.map(ty => ty.typeInfo),
        )) ?? [];
      return res.map((i, index) => ({
        coinAddress: typeInfoToStr(i.key),
        emodeIds: reserveTypes[index].emodeIds,
        ...i.value,
      }));
    },
    { refreshInterval: 30 * 1000 },
  );

  const { data: reserveOracleInfoMap = {} } = useSWR(
    ['ReservesOracleinfos', reserveMeta?.stats],
    async () => {
      await delay(200);
      const sdk = getAriesSDK();
      const oracleIndexTable =
        await sdk.oracle.OracleIndex.fetch.fromProgram();
      const res =
        (await oracleIndexTable?.prices.fromKeys(
          getReserveTypes().map(ty => ty.typeInfo),
        )) ?? [];

      const reservesOracleInfos = compact(
        res.map(i => {
          if (!i.value.switchboard && !i.value.pyth) {
            return undefined;
          }

          return {
            coinAddress: typeInfoToStr(i.key),
            switchboardAddr: i.value.switchboard?.sbAddr ?? '',
            switchboardMaxAge: i.value.switchboard?.maxAge ?? '0',
            switchboardWeight: i.value.switchboard?.weight ?? '0',
            pythId: i.value.pyth?.pythId?.bytes ?? '',
            pythMaxAge: i.value.pyth?.maxAge ?? '0',
            pythWeight: i.value.pyth?.weight ?? '0',
          };
        }),
      );

      return mapValues(
        keyBy(reservesOracleInfos, o => o.coinAddress),
        ({ coinAddress, ...rest }) => rest,
      );
    },
    { refreshInterval: 30 * 1000, revalidateOnFocus: false },
  );

  const { data: reserveFarms = [], mutate: mutateFarms } = useSWR(
    ['ReserveFarms', reserveMeta?.farms],
    async () => {
      await delay(200);
      const farms =
        (await reserveMeta?.farms
          .fromKeys(
            getReserveTypes().flatMap(ty => {
              return ty.farmTypes.map(fm => ({
                fst: ty.typeInfo,
                snd: {
                  module_name: 'reserve_config',
                  struct_name: fm,
                  account_address: ARIES_PROGRAM,
                },
              }));
            }),
          )
          .then(farms =>
            Promise.all(
              farms.map(async ({ key, value }) => ({
                key,
                value: { ...value, rewards: await value.rewards.fetch() },
              })),
            ),
          )) ?? [];
      return farms
        .flatMap(farm =>
          farm.value.rewards.map(reward => ({
            reserveCoinAddress: typeInfoToStr(farm.key.fst),
            rewardCondition: farm.key.snd.struct_name as FarmingType,
            rewardCoinAddress: typeInfoToStr(reward.key),
            totalShares: farm.value.share,
            updatedTimestamp: farm.value.timestamp,
            ...reward.value,
          })),
        )
        .map(farm =>
          simulateRewardUpdate(farm, Big(Date.now() / 1000).round(0)),
        );
    },
    { refreshInterval: 30 * 1000 },
  );

  return useMemo(() => {
    const reserveDetails = reserves.map(reserve => {
      const partialReserveDetails = getReserveDetail(reserve);
      return {
        coinAddress: reserve.coinAddress,
        rawReserve: reserve,
        oracleInfo: reserveOracleInfoMap[reserve.coinAddress],
        ...partialReserveDetails,
        ...getReserveRewardDetails(
          partialReserveDetails,
          reserveFarms.filter(
            fm => fm.reserveCoinAddress === reserve.coinAddress,
          ),
        ),
      };
    });

    return {
      reserves: reserveDetails,
      reserveMap: keyBy(reserveDetails, r => r.coinAddress),
      refresh: () =>
        mutateStats(v => v, true).then(() => mutateFarms(v => v, true)),
      loading: isValidating,
    };
  }, [
    reserves,
    reserveFarms,
    reserveOracleInfoMap,
    mutateFarms,
    mutateStats,
    isValidating,
  ]);
});

const getReserveDetail = (reserve: ReserveDetails) => {
  const {
    reserveConfig: {
      loanToValue: loanToValuePct,
      borrowFeeHundredthBips,
      withdrawFeeHundredthBips,
      liquidationThreshold,
      depositLimit,
      borrowLimit,
      liquidationBonusBips,
      flashLoanFeeHundredthBips,
      borrowFactor: borrowFactorPct,
      reserveRatio,
    },
    totalBorrowedShare,
    totalBorrowed: totalBorrowedLamports,
    reserveAmount,
    interestRateConfig,
    totalLpSupply: totalIssuedToken,
    totalCashAvailable,
  } = reserve;

  // Total
  const totalBorrowed = totalBorrowedLamports;
  const totalAsset = totalBorrowed
    .plus(totalCashAvailable)
    .minus(reserveAmount);
  const totalDeposited = totalAsset;

  // Need to minus reserved profit of platform
  const availableLiquidity = totalCashAvailable
    .minus(reserveAmount)
    .mul(0.95);

  const shareToAssetExchangeRatio = totalIssuedToken.eq(0)
    ? Big(0)
    : totalAsset.div(totalIssuedToken);

  const shareToBorrowExchangeRatio = totalBorrowedShare.eq(0)
    ? Big(1)
    : totalBorrowed.div(totalBorrowedShare);

  // Some limit
  const maxDepositableLamports = getMax(
    Big(0),
    depositLimit.minus(totalDeposited),
  );

  const maxBorrowableLamports = getMin(
    getMax(Big(0), borrowLimit.minus(totalBorrowed)),
    availableLiquidity,
  );

  const maxWithdrableLamports = availableLiquidity;

  const utilizationRatio = totalAsset.eq(0)
    ? Big(0)
    : totalBorrowed.div(totalAsset);

  // APY
  const getBorrowApyPct = () => {
    // 0.8
    const optimalUtilizationRatio =
      interestRateConfig.optimalUtilization.div(100);

    if (
      optimalUtilizationRatio.eq(1) ||
      utilizationRatio.lt(optimalUtilizationRatio)
    ) {
      // 10, 0
      const { optimalBorrowRate, minBorrowRate } = interestRateConfig;

      // 0.10 / 0.10
      const normalizedFactor = utilizationRatio.div(
        optimalUtilizationRatio,
      );

      const borrowRateDiff = optimalBorrowRate.sub(minBorrowRate).div(100);

      return normalizedFactor
        .mul(borrowRateDiff)
        .add(minBorrowRate.div(100));
    }

    const normalizedFactor = utilizationRatio
      .sub(optimalUtilizationRatio)
      .div(new Big(1).sub(optimalUtilizationRatio));

    const { maxBorrowRate, optimalBorrowRate } = interestRateConfig;
    const borrowRateDiff = maxBorrowRate.sub(optimalBorrowRate).div(100);

    return normalizedFactor
      .mul(borrowRateDiff)
      .add(optimalBorrowRate.div(100));
  };

  const getSupplyApyPct = () => {
    const borrowApy = getBorrowApyPct();
    return borrowApy
      .mul(100 - reserveRatio)
      .mul(utilizationRatio)
      .div(100);
  };

  const shareToBorrow = (share: Big) => {
    return share.mul(shareToBorrowExchangeRatio);
  };

  const shareToDeposit = (share: Big) => {
    return share.mul(shareToAssetExchangeRatio);
  };

  return {
    borrowApyPct: getBorrowApyPct().mul(100),
    supplyApyPct: getSupplyApyPct().mul(100),
    borrowFeePct: borrowFeeHundredthBips.div(10 ** 4),
    withdrawFeePct: withdrawFeeHundredthBips.div(10 ** 4),
    flashLoanFeePct: flashLoanFeeHundredthBips.div(10 ** 4).toNumber(),
    shareToDeposit,
    shareToBorrow,
    liquidationThreshold,
    loanToValuePct,
    marketCap: totalAsset,
    totalBorrowed,
    totalDeposited,
    reserveConfig: reserve.reserveConfig,
    maxDepositableLamports,
    maxBorrowableLamports,
    maxWithdrableLamports,
    availableLiquidity,
    liquidationBonusPct: liquidationBonusBips.div(100).toNumber(),
    optimalInterestRatePct:
      interestRateConfig.optimalBorrowRate.toNumber(),
    optimalUtilizationPct:
      interestRateConfig.optimalUtilization.toNumber(),
    maxInterestRatePct: interestRateConfig.maxBorrowRate.toNumber(),
    minInterestRatePct: interestRateConfig.minBorrowRate.toNumber(),
    borrowFactorPct,
    utilizationRatio,
  };
};

export type ReserveRewardEntry = Reward & {
  reserveCoinAddress: string;
  rewardCondition: FarmingType;
  rewardCoinAddress: string;
  totalShares: Big;
  updatedTimestamp: Big;
};

/** Simulate reserve farm update */
const simulateRewardUpdate = (
  reward: ReserveRewardEntry,
  nowTimestampSecs: Big,
) => {
  const timeElapsedDays = nowTimestampSecs
    .minus(reward.updatedTimestamp)
    .div(86400);
  const rewardToDistribute = bigMin(
    reward.totalShares.gt(0) && reward.remainingReward.gt(0)
      ? reward.rewardConfig.rewardPerDay.mul(timeElapsedDays)
      : Big(0),
    reward.remainingReward,
  );
  return {
    ...reward,
    updatedTimestamp: nowTimestampSecs,
    remainingReward: reward.remainingReward.minus(rewardToDistribute),
    rewardPerShare: reward.rewardPerShare.add(
      reward.totalShares.gt(0)
        ? rewardToDistribute.div(reward.totalShares)
        : 0,
    ),
  };
};

const getReserveRewardDetails = (
  partialReserveDetails: ReturnType<typeof getReserveDetail>,
  rewards: ReserveRewardEntry[],
) => {
  if (rewards.length === 0) {
    return {
      borrowRewardApyPct: Big(0),
      supplyRewardApyPct: Big(0),
      rawFarms: [],
    };
  }
  const tokenMap = getTokenInfoHub()?.emptyableTokenMap;
  const reserveCoinInfo = tokenMap?.[rewards[0].reserveCoinAddress];

  const accDailyRewardYield = (reward: ReserveRewardEntry) => {
    const rewardCoinInfo = tokenMap?.[reward.rewardCoinAddress];
    if (!rewardCoinInfo || !reserveCoinInfo) {
      return Big(0);
    }
    // We assume the daily reward will be renewed forever except it is really drained out.
    const dailyRewardValue = rewardCoinInfo.toUSDValue(
      bigMin(reward.rewardConfig.rewardPerDay, reward.remainingReward),
    );
    const totalShareValue = reserveCoinInfo.toUSDValue(
      reward.rewardCondition === 'DepositFarming'
        ? partialReserveDetails.shareToDeposit(reward.totalShares)
        : partialReserveDetails.shareToBorrow(reward.totalShares),
    );
    const dailyRewardYield =
      totalShareValue > 0
        ? dailyRewardValue / totalShareValue
        : // Let's assume there is 1 share to share the rewards under this edge case
          dailyRewardValue / 1;
    return Big(dailyRewardYield);
  };

  const rawFarms = rewards.map(r => ({
    ...r,
    rewardApy: accDailyRewardYield(r).mul(365 * 100),
  }));
  const [depositRewards, borrowRewards] = partition(
    rawFarms,
    fm => fm.rewardCondition === 'DepositFarming',
  );
  return {
    borrowRewardApyPct: computeTotal(borrowRewards, r => r.rewardApy),
    supplyRewardApyPct: computeTotal(depositRewards, r => r.rewardApy),
    rawFarms,
  };
};

export type Reserve = ReturnType<typeof useReserves>['reserves'][number];
