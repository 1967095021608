import { InputTransactionData } from '@aptos-labs/wallet-adapter-core';
import { createGlobalStore } from '@aries/shared/deps';
import { useWallet as useWalletImpl } from './hook';

export const [useWalletAdapter, getWalletAdapter] = createGlobalStore(
  () => {
    const { walletCtx, walletCtxRef, setWalletModalShow, disconnect } =
      useWalletImpl();

    const getAddressWithNotify = () => {
      const address = walletCtxRef.current?.address;

      if (!address) {
        throw new Error('Please connect your wallet');
      }

      return address;
    };

    return {
      walletAddress: walletCtx?.address,
      walletIcon: walletCtx?.adapter.wallet?.icon,
      walletPublicKey: walletCtx?.adapter?.account?.publicKey?.toString(),
      signAndSubmitTransaction: (
        transactionData: InputTransactionData,
      ) => {
        const adapter = walletCtxRef.current?.adapter;
        if (!adapter) {
          throw new Error('Please connect your wallet');
        }

        return adapter.signAndSubmitTransaction(transactionData) as any;
      },
      getAddressWithNotify,
      getAddress: () => {
        return walletCtxRef.current?.address;
      },
      connect: () => setWalletModalShow(true),
      disconnect,
    };
  },
);
