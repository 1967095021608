import { CenterRow, createIcon } from '@aries/mobile-core/components';
import { ScreenWidth } from '@aries/mobile-core/utils';
import { globalToken } from '@aries/ui-theming';
import React, { useEffect, useRef } from 'react';
import { Animated, StatusBar } from 'react-native';
import styled from 'styled-components/native';
import { IconLogo } from '~/components';

const AriesFinance = createIcon(require('./AriesFinance.png'), 101 / 853);

export const SplashScreen: React.FunctionComponent<{}> = () => {
  const scaleRef = useRef(new Animated.Value(0));

  const open = () => {
    Animated.timing(scaleRef.current, {
      toValue: 1,
      useNativeDriver: true,
      duration: 1000,
    }).start();
  };

  useEffect(() => {
    StatusBar.setBarStyle('light-content');
    open();
  }, []);

  return (
    <Container>
      <Animated.View
        style={{ opacity: scaleRef.current, alignItems: 'center' }}
      >
        <IconLogo size={ScreenWidth * 0.25} style={{ marginBottom: 30 }} />

        <AriesFinance size={ScreenWidth * 0.55} />
      </Animated.View>
    </Container>
  );
};

const { colorBgBase } = globalToken;
const Container = styled(CenterRow)`
  justify-content: center;
  flex: 1;
  background-color: ${colorBgBase};
`;
