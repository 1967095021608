import { useIsUserInUS } from '@aries/aptos-defi/common';
import { useProfile } from '@aries/lending-fe-hooks';
import {
  CenterBetweenRow,
  CenterRow,
  Col,
  EndCol,
  Row,
  Spin,
  styled,
  Txt,
} from '@aries/mobile-core/components';
import { useTranslation } from '@aries/shared/locale';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import React from 'react';
import { IconSecondDown, SmallCard, TokenIcon } from '~/components';
import { openReserveAction, ProfileSelect } from '~/services';
import { BorrowingPower } from './BorrowingPower';
import { Section } from './common';

const { colorTextCritical, colorTextSuccess } = colorConfig;
const { colorBgSpotlight, colorInteractive } = globalToken;
export const UserSummary: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { isUserInUS } = useIsUserInUS();
  const { currentProfile, currentProfileLoading } = useProfile();

  const summary = currentProfile?.summary ?? {
    apy: 0,
    depositedValueUSD: '-',
    borrowedValueUSD: '-',
    availableMarginUSD: '-',
    rewardValueUSD: '-',
    borrowingPowerPct: 0,
    deposits: [],
    loans: [],
    rewards: [],
    riskFactorPct: 0,
    isApyPositive: true,
  };

  const depositList = currentProfile?.summary.deposits ?? [];
  const loanList = currentProfile?.summary.loans ?? [];
  const { mode } = useThemeMode();
  return (
    <Col>
      <Section
        title={
          <CenterRow>
            <Txt h4>{t`Account`}</Txt>
            {currentProfileLoading && (
              <Spin size={17} style={{ marginLeft: 6 }} />
            )}
          </CenterRow>
        }
        headerRight={
          <CenterRow>
            <ProfileSelect />
            <IconSecondDown size={10} ml={3} />
          </CenterRow>
        }
      >
        <CenterRow>
          {[
            {
              title: t`APY`,
              subTitle: `${summary.apy.toFixed(2)}%`,
              color: !summary.isApyPositive ? colorTextCritical[mode] : '',
            },
            {
              title: t`Borrowed`,
              subTitle: `${summary.borrowedValueUSD}`,
              color: colorTextCritical[mode],
            },
            {
              title: t`Risk Factor`,
              subTitle: `${summary.riskFactorPct.toFixed(2)}%`,
              color:
                summary.riskFactorPct > 50 ? colorTextCritical[mode] : '',
            },
          ].map((item, index) => {
            return (
              <SmallCard
                key={item.title + index}
                title={item.title}
                subTitle={item.subTitle}
                subColor={item.color ?? colorTextSuccess[mode]}
                hasMarginLeft={index > 0}
              />
            );
          })}
        </CenterRow>
        <BorrowingPower />
      </Section>
      <Section
        title={
          <CenterRow>
            <Txt h3 bold>
              {t`Supply`}
            </Txt>
            {currentProfileLoading && (
              <Spin size={17} style={{ marginLeft: 6 }} />
            )}
          </CenterRow>
        }
        style={{ padding: 0 }}
        headerRight={summary.depositedValueUSD}
      >
        {depositList.length > 0 ? (
          depositList.map(v => {
            return (
              <AssetItem
                onPress={() => openReserveAction(v.asset.id)}
                key={v.asset.id}
              >
                <CenterRow>
                  <TokenIcon size={28} asset={v.asset} />
                  <CenterBetweenRow ml={12} flex={1}>
                    <Col>
                      <CenterRow>
                        <Txt h3 bold>
                          {v.asset.symbol}
                        </Txt>
                      </CenterRow>
                      <Txt h2 c2 bold>
                        {v.asset.priceUSD}
                      </Txt>
                    </Col>

                    <EndCol>
                      <Txt h3 bold>
                        {v.amount}
                      </Txt>
                      <Txt h2 c2 bold>
                        {v.valueUSDStr}
                      </Txt>
                    </EndCol>
                  </CenterBetweenRow>
                </CenterRow>
              </AssetItem>
            );
          })
        ) : (
          <Row mg={16}>
            <Txt>{t`No Deposit`}</Txt>
          </Row>
        )}
      </Section>

      <Section
        title={
          <CenterRow>
            <Txt h3 bold>
              {t`Borrow`}
            </Txt>
            {currentProfileLoading && (
              <Spin size={17} style={{ marginLeft: 6 }} />
            )}
          </CenterRow>
        }
        containerStyle={{ marginTop: 18, marginBottom: 24 }}
        style={{ padding: 0 }}
        headerRight={summary.borrowedValueUSD}
      >
        {loanList.length > 0 ? (
          loanList.map(v => {
            return (
              <AssetItem
                onPress={() => openReserveAction(v.asset.id)}
                key={v.asset.id}
              >
                <CenterRow>
                  <TokenIcon size={28} asset={v.asset} />
                  <CenterBetweenRow ml={12} flex={1}>
                    <Col>
                      <CenterRow>
                        <Txt h3>{v.asset.symbol}</Txt>
                      </CenterRow>
                      <Txt h2 c2>
                        {v.asset.priceUSD}
                      </Txt>
                    </Col>

                    <EndCol>
                      <Txt h3>{v.amount}</Txt>
                      <Txt h2 c2>
                        {v.valueUSDStr}
                      </Txt>
                    </EndCol>
                  </CenterBetweenRow>
                </CenterRow>
              </AssetItem>
            );
          })
        ) : (
          <Row mg={16}>
            <Txt>{t`No Borrow`}</Txt>
          </Row>
        )}
      </Section>

      {!isUserInUS && (
        <Section
          title={
            <CenterRow>
              <Txt h3 bold>
                {t`Rewards`}
              </Txt>
              {currentProfileLoading && (
                <Spin size={17} style={{ marginLeft: 6 }} />
              )}
            </CenterRow>
          }
          containerStyle={{ marginTop: 18, marginBottom: 24 }}
          style={{ padding: 0 }}
          headerRight={summary.rewardValueUSD}
        >
          {summary.rewards.length > 0 ? (
            summary.rewards.map(v => {
              return (
                <AssetItem
                  onPress={() => {
                    currentProfile?.claimRewards?.(v.asset.id);
                  }}
                  key={v.asset.id}
                >
                  <CenterRow>
                    <TokenIcon size={28} asset={v.asset} />
                    <CenterBetweenRow ml={12} flex={1}>
                      <Col>
                        <CenterRow>
                          <Txt h3>{v.asset.symbol}</Txt>
                        </CenterRow>
                        <Txt h2 c2>
                          {v.asset.priceUSD}
                        </Txt>
                      </Col>

                      <EndCol>
                        <Txt h3>{v.amount}</Txt>
                        <Txt h2 c2>
                          {v.valueUSDStr}
                        </Txt>
                      </EndCol>
                    </CenterBetweenRow>
                  </CenterRow>
                  ~
                </AssetItem>
              );
            })
          ) : (
            <Row mg={16}>
              <Txt>{t`No Rewards`}</Txt>
            </Row>
          )}
        </Section>
      )}
    </Col>
  );
};

const AssetItem = styled.TouchableOpacity`
  padding: 16px;
  :hover {
    background-color: ${colorBgSpotlight};
    border-left: 2px solid ${colorInteractive};
  }
`;
