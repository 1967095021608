import {
  BrandColorConfig,
  BrandColorToken,
  setTheme,
} from '@aries/ui-theming';

const colorPrimary = {
  light: '#71ceff',
  dark: '#71ceff',
};
const colorSuccess = {
  light: '#15803d',
  dark: '#15803d',
};
const colorWarning = {
  light: '#92400e',
  dark: '#92400e',
};
const colorError = {
  light: '#b91c1c',
  dark: '#b91c1c',
};
const colorInfo = {
  light: '#71ceff',
  dark: '#71ceff',
};

export const brandColorConfig: BrandColorConfig = {
  colorPrimary,
  colorSuccess,
  colorWarning,
  colorError,
  colorInfo,
};

export const brandColorTokens: BrandColorToken = {
  colorPrimary: setTheme(colorPrimary),
  colorSuccess: setTheme(colorSuccess),
  colorWarning: setTheme(colorWarning),
  colorError: setTheme(colorError),
  colorInfo: setTheme(colorInfo),
};
