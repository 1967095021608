import { ScrollView } from '@aries/mobile-core/components';
import React from 'react';
import { FirstPageWrapper } from '~/components';
import { Assets } from './Assets';
import { AssetsCount } from './AssetsCount';

export const Lending: React.FunctionComponent = () => {
  return (
    <FirstPageWrapper>
      <ScrollView>
        <AssetsCount />
        <Assets />
      </ScrollView>
    </FirstPageWrapper>
  );
};
