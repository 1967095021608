import { Network } from '@aptos-labs/ts-sdk';
import {
  AptosWalletAdapterProvider as WalletProviderImpl,
  NetworkName,
  useWallet as useWalletInner,
  WalletReadyState,
} from '@aptos-labs/wallet-adapter-react';
import {
  CenterCol,
  CenterRow,
  Col,
  Image,
  ScrollView,
  Txt,
} from '@aries/mobile-core/components';
import {
  NotificationBase,
  Notify,
  openModal,
} from '@aries/mobile-core/Smoothy';
import { useLatest } from '@aries/shared/hooks';
import { Trans, useTranslation } from '@aries/shared/locale';
import { shortenAddress } from '@aries/shared/utils';
import { globalToken } from '@aries/ui-theming';
import { BloctoWallet } from '@blocto/aptos-wallet-adapter-plugin';
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';
import { MSafeWalletAdapter } from '@msafe/aptos-wallet-adapter';
import { OKXWallet } from '@okwallet/aptos-wallet-adapter';
import { PontemWallet } from '@pontem/wallet-adapter-plugin';
import { RiseWallet } from '@rise-wallet/wallet-adapter';
import { SpikaWallet } from '@spika/aptos-plugin';
import { TrustWallet } from '@trustwallet/aptos-wallet-adapter';
import { WelldoneWallet } from '@welldone-studio/aptos-wallet-adapter';
import { FewchaWallet } from 'fewcha-plugin-wallet-adapter';
import { compact, sortBy, uniqBy } from 'lodash';
import { PetraWallet } from 'petra-plugin-wallet-adapter';
import React, { ReactNode, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { BitgetWallet } from '../adapters/bitget-adapter';
import { WALLET_POSITION_MAP } from '../config';
import { useWallet } from '../hook';
import { useSupportMizuWallet } from '../mizu-support';

export const WalletProvider = ({
  children,
  isTestnet,
}: {
  children: ReactNode;
  isTestnet: boolean;
}) => {
  const [wallets, setWallets] = useState<any[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setWallets(
        compact([
          new PetraWallet(),
          new OKXWallet(),
          new BitgetWallet(),
          new PontemWallet(),
          new MartianWallet(),
          new RiseWallet(),
          new BloctoWallet({
            network: NetworkName.Mainnet,
            bloctoAppId: '774418fe-0a28-4a49-bd4f-5cc513178fc8',
          }),
          new TrustWallet(),
          new FewchaWallet(),
          new MSafeWalletAdapter(),
          new SpikaWallet(),
          new WelldoneWallet(),
        ]),
      );
    }, 100);
  }, []);

  if (wallets.length === 0) {
    return null;
  }

  return (
    <WalletProviderImpl
      plugins={wallets}
      autoConnect={!window.location.href.includes('localhost')}
      dappConfig={{
        network: isTestnet ? Network.TESTNET : Network.MAINNET,
        mizuwallet: {
          manifestURL:
            'https://assets.mz.xyz/static/config/mizuwallet-connect-manifest.json',
        },
      }}
    >
      <>
        {children}
        <SelectWalletModal />
        <WalletCtxSpy />
      </>
    </WalletProviderImpl>
  );
};

const WalletCtxSpy = () => {
  const { t } = useTranslation();
  const { setWalletCtx } = useWallet();
  const adapter = useWalletInner();
  const { wallet, connected, account } = adapter;
  useEffect(() => {
    if (connected && wallet && account?.address) {
      const { address } = account;

      const walletCtx = {
        adapter: {
          ...adapter,
          disconnect: () => {
            try {
              adapter.disconnect();
              window.location.reload();
              // eslint-disable-next-line no-empty
            } catch (error) {}
          },
        },
        address,
      };

      setWalletCtx(walletCtx);

      Notify.info(
        t`Wallet Connected!`,
        t`Address: ${shortenAddress(address)}`,
      );
    } else {
      setWalletCtx(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, wallet, account?.address, Notify]);

  return null;
};

const SelectWalletModal: React.FC<{}> = () => {
  const { mizuSupported } = useSupportMizuWallet();
  const { walletModalShow, setWalletModalShow } = useWallet();

  const {
    wallet,
    disconnect,
    connect,
    wallets: adapters,
  } = useWalletInner();
  const connectRef = useLatest(connect);
  const disconnectRef = useLatest(disconnect);

  useEffect(() => {
    if (walletModalShow) {
      openModal({
        title: 'Select Wallet',
        renderContent: ({ close }) => {
          return (
            <ScrollView>
              <CenterCol>
                <Txt success mb={4} h3 bold>
                  <Trans i18nkey="Powered by AIP-62 Wallet Standard" />
                </Txt>
              </CenterCol>
              <NotificationBase
                type="info"
                title={<Trans i18nkey="REMIND" />}
                style={{ paddingTop: 8, paddingBottom: 8 }}
                content={
                  <Txt h2 c2>
                    <Trans i18nkey="Please switch your wallet to" />{' '}
                    <Txt h3 warning>
                      <Trans i18nkey="CORRECT NETWORK" />
                    </Txt>
                    .
                  </Txt>
                }
              />
              {sortBy(
                uniqBy(adapters ?? [], a => a.name).filter(
                  _adapter => _adapter.name !== 'T wallet',
                ),
                _adapter =>
                  (
                    _adapter.name.includes('Mizu')
                      ? mizuSupported
                      : _adapter.readyState ===
                          WalletReadyState.Installed ||
                        _adapter.readyState === WalletReadyState.Loadable
                  )
                    ? WALLET_POSITION_MAP[_adapter.name] ?? 1000
                    : (WALLET_POSITION_MAP[_adapter.name] ?? 1000) * 10000,
              ).map(_adapter => {
                const i = { ..._adapter };
                if (i.name.includes('Mizu') && !mizuSupported) {
                  i.readyState = WalletReadyState.Unsupported;
                }

                return (
                  <TouchableOpacity
                    key={i.name}
                    onPress={async () => {
                      try {
                        disconnectRef.current();
                        // eslint-disable-next-line no-empty
                      } catch {}
                      setTimeout(async () => {
                        try {
                          if (
                            i.readyState !== WalletReadyState.Installed &&
                            i.readyState !== WalletReadyState.Loadable &&
                            !i.name.includes('Mizu')
                          ) {
                            window.open(i.url);
                          } else {
                            if (i.name === 'Mizu Wallet') {
                              try {
                                await fetch(
                                  'https://assets.mz.xyz/static/config/mizuwallet-connect-manifest.json',
                                );
                              } catch (error) {
                                Notify.error(
                                  'Mizu is unavailable in your country.',
                                );
                                return;
                              }
                            }

                            connectRef.current(i.name);
                            close();
                          }
                          // eslint-disable-next-line no-empty
                        } catch {}
                      }, 150);
                    }}
                  >
                    <WalletItem
                      disabled={false}
                      isActive={wallet?.name === i.name}
                      mt={10}
                    >
                      <Image
                        style={{ height: 45, width: 45 }}
                        source={{ uri: i.icon }}
                      />
                      <Col ml={15}>
                        <Txt h3 bold>
                          {i.name}
                        </Txt>
                        <Txt
                          c2={
                            i.readyState !== WalletReadyState.Installed &&
                            i.readyState !== WalletReadyState.Loadable
                          }
                          h2
                        >
                          {i.readyState}
                        </Txt>
                      </Col>
                    </WalletItem>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          );
        },
      });
      setWalletModalShow(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletModalShow]);
  return null;
};

const { colorBgQuaternary, colorBgElevated, colorBgDisabled } =
  globalToken;
// @ts-ignore
const WalletItem = styled(CenterRow)<{
  isActive: boolean;
  disabled: boolean;
}>`
  padding-left: 15px;
  background-color: ${p =>
    p.disabled
      ? colorBgDisabled
      : p.isActive
      ? colorBgQuaternary
      : undefined};
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background-color: ${colorBgElevated};
  }
  padding: 15px 12px;
  width: 440px;
`;
