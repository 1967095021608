import { useStableCallback } from '@aries/shared/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOrderbookDetailHub } from './detail-hub';
import { useOrderbookList } from './list';

export { parseOrderId } from './detail-hub';

export const useAllOrderBook = () => {
  const {
    orderbookList: metaList = [],
    loading: marketLoading,
    refresh,
  } = useOrderbookList();
  const [enableOrderBook, setEnableOrderBook] = useState(false);
  const orderbookRef = useRef(enableOrderBook);
  orderbookRef.current = enableOrderBook;

  const { orderbookMap, triggerLoadOrderbookDetail } =
    useOrderbookDetailHub();

  const [currentMarketId, setCurrentMarketId] = useState<string>();

  const orderbookList = useMemo(() => {
    if (!metaList) return [];

    return metaList.map(m => {
      const { marketId } = m;
      return {
        ...m,
        value: orderbookMap[marketId]?.value,
        loading: orderbookMap[marketId]?.loading,
      };
    });
  }, [metaList, orderbookMap]);

  const refreshCurrentMarket = async () => {
    const market = metaList?.find(p => p.marketId === currentMarketId);
    if (!market) return;

    await triggerLoadOrderbookDetail(market);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (orderbookRef.current) {
        refreshCurrentMarket();
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarketId]);

  const changeMarket = useStableCallback((marketId: string) => {
    const market = metaList?.find(p => p.marketId === marketId);
    if (!market) return;

    setCurrentMarketId(marketId);
    triggerLoadOrderbookDetail(market);
  });

  useEffect(() => {
    if (
      orderbookList &&
      orderbookList.length > 0 &&
      !orderbookList.find(p => p.marketId === currentMarketId)
    ) {
      setCurrentMarketId(orderbookList[0].marketId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbookList, currentMarketId]);

  useEffect(() => {
    if (orderbookRef.current) {
      metaList.forEach((market, i) => {
        setTimeout(() => {
          triggerLoadOrderbookDetail(market);
        }, 1000 * i);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbookList.length]);

  return {
    orderbookList,
    currentMarketId,
    triggerLoadOrderbookDetail,
    changeMarket,
    refreshCurrentMarket,
    marketLoading,
    refresh,
    setEnableOrderBook,
  };
};

export type OrderBookList = NonNullable<
  ReturnType<typeof useAllOrderBook>
>['orderbookList'];
