import { useProviderHub } from '@aries/aptos-defi/common';
import { typeInfoToStr } from '@aries/aptos-defi/utils';
import axios from 'axios';
import Big from 'big.js';
import { useMemo } from 'react';
import useSWR from 'swr';

const ECONIA_RESTFUL_API_BASE_URL =
  'https://aptos-mainnet-econia.nodeinfra.com';

export const useOrderbookList = () => {
  const {
    ECONIA_PROGRAM,
    provider: { client },
    env,
  } = useProviderHub();

  const {
    data: orderbookList = [],
    isValidating: loading,
    mutate,
  } = useSWR<
    {
      baseCoinAddress: string;
      quoteCoinAddress: string;
      marketId: string;
      lotSize: Big;
      minSize: Big;
      tickSize: Big;
    }[]
  >(
    ['OrderBookEventList', client, ECONIA_PROGRAM],
    async () => {
      try {
        return (
          await axios.get<
            {
              market_id: number;
              lot_size: number;
              tick_size: number;
              min_size: number;
              base_account_address: string;
              base_module_name: string;
              base_struct_name: string;
              quote_account_address: string;
              quote_module_name: string;
              quote_struct_name: string;
            }[]
          >(`${ECONIA_RESTFUL_API_BASE_URL}/markets`)
        ).data.map(marketInfo => {
          return {
            baseCoinAddress: typeInfoToStr(
              {
                account_address: marketInfo.base_account_address,
                module_name: marketInfo.base_module_name,
                struct_name: marketInfo.base_struct_name,
              },
              false,
            ),
            quoteCoinAddress: typeInfoToStr(
              {
                account_address: marketInfo.quote_account_address,
                module_name: marketInfo.quote_module_name,
                struct_name: marketInfo.quote_struct_name,
              },
              false,
            ),
            marketId: marketInfo.market_id.toString(),
            lotSize: Big(marketInfo.lot_size),
            minSize: Big(marketInfo.min_size),
            tickSize: Big(marketInfo.tick_size),
          };
        });
      } catch (error) {
        return [];
      }
    },
    { revalidateIfStale: false, revalidateOnFocus: false },
  );

  const refresh = () => mutate(v => v, true);

  return {
    refresh,
    orderbookList: useMemo(
      () =>
        orderbookList.filter(v =>
          (env.isTestnet
            ? ECONIA_TESTNET_VALID_MARKET_IDS
            : ECONIA_MAINNET_VALID_MARKET_IDS
          ).includes(v.marketId),
        ),
      [env.isTestnet, orderbookList],
    ),
    loading,
  };
};

const ECONIA_TESTNET_VALID_MARKET_IDS = ['3'];
const ECONIA_MAINNET_VALID_MARKET_IDS = ['7', '8', '11'];
