import { AppEnv } from '@aries/defi-toolkit/types';
import Big from 'big.js';

// To avoid Big.toString() get string such as 1e23
Big.PE = 50;

export const ECONIA_LOTS = 10 ** 4;

export const ARIES_TEST_PROGRAM_ADDRESS =
  '0xdb495ff915af4ab6cb6a011cb64349b41442068a578e35e25e2fff6990c7c30f';

export const ARIES_PROGRAM_ADDRESS =
  '0x9770fa9c725cbd97eb50b2be5f7416efdfd1f1554beb0750d4dae4c64e860da3';

export const ECONIA_TEST_PROGRAM_ADDRESS =
  '0xc0de11113b427d35ece1d8991865a941c0578b0f349acabbe9753863c24109ff';

export const ECONIA_PROGRAM_ADDRESS =
  '0xc0deb00c405f84c85dc13442e305df75d1288100cdd82675695f6148c7ece51c';

export const ECONIA_COIN_ACCOUNT =
  '0xc0de11113b427d35ece1d8991865a941c0578b0f349acabbe9753863c24109ff';

export const ARIES_API_ENDPOINT = 'https://api-v2.ariesmarkets.xyz';

export const EMODE_ID_TESTNET = 'apt';
export const EMODE_ID_MAINNET = 'Apt';

export type FarmingType = 'DepositFarming' | 'BorrowFarming';

type ReserveInitConfig = {
  coinAddress: string;
  coinGeckoKey: string;
  farmTypes: FarmingType[];
  pythPriceId?: string;
  emodeIds?: string[];
};

// [${coinAddress}, ${coinGeckoKey}]
const RESERVES_TO_INIT_TESTNET: ReserveInitConfig[] = [
  {
    coinAddress: '0x1::aptos_coin::AptosCoin',
    coinGeckoKey: 'aptos',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    emodeIds: [EMODE_ID_TESTNET],
  },
  {
    coinAddress:
      '0x498d8926f16eb9ca90cab1b3a26aa6f97a080b3fcbe6e83ae150b7243a00fb68::devnet_coins::DevnetBTC',
    coinGeckoKey: 'bitcoin',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    pythPriceId:
      '0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b',
  },
  {
    coinAddress:
      '0x498d8926f16eb9ca90cab1b3a26aa6f97a080b3fcbe6e83ae150b7243a00fb68::devnet_coins::DevnetUSDC',
    coinGeckoKey: 'usd-coin',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    pythPriceId:
      '0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722',
    emodeIds: [EMODE_ID_TESTNET],
  },
];

export const APTOS_COIN_ADDRESS = '0x1::aptos_coin::AptosCoin';
export const USD_COIN_ADDRESS =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC';
const RESERVES_TO_INIT_MAINNET: ReserveInitConfig[] = [
  {
    coinAddress: APTOS_COIN_ADDRESS,
    coinGeckoKey: 'aptos',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    emodeIds: [EMODE_ID_MAINNET],
  },
  {
    coinAddress:
      '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T',
    coinGeckoKey: 'usd-coin',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0xcfea864b32833f157f042618bd845145256b1bf4c0da34a7013b76e42daa53cc::usdy::USDY',
    coinGeckoKey: 'ondo-us-dollar-yield',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0xdd89c0e695df0692205912fb69fc290418bed0dbe6e4573d744a6d5e6bab6c13::coin::T',
    coinGeckoKey: 'solana',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH',
    coinGeckoKey: 'ether',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress: USD_COIN_ADDRESS,
    coinGeckoKey: 'usd-coin',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::stapt_token::StakedApt',
    coinGeckoKey: 'amnis-aptos',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    emodeIds: [EMODE_ID_MAINNET],
  },
  {
    coinAddress:
      '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::amapt_token::AmnisApt',
    coinGeckoKey: 'amnis-aptos',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    emodeIds: [EMODE_ID_MAINNET],
  },
  {
    coinAddress:
      '0x84d7aeef42d38a5ffc3ccef853e1b82e4958659d16a7de736a29c55fbbeb0114::staked_aptos_coin::StakedAptosCoin',
    coinGeckoKey: 'tortuga-staked-aptos',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0xcc8a89c8dce9693d354449f1f73e60e14e347417854f029db5bc8e7454008abb::coin::T',
    coinGeckoKey: 'ether',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
  },
  {
    coinAddress:
      '0x159df6b7689437016108a019fd5bef736bac692b6d4a1f10c941f6fbb9a74ca6::oft::CakeOFT',
    coinGeckoKey: 'pancakeswap-token',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    pythPriceId:
      '0x2356af9529a1064d41e32d617e2ce1dca5733afa901daba9e2b68dee5d53ecf9',
  },
  {
    coinAddress:
      '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT',
    coinGeckoKey: 'tether',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    pythPriceId:
      '0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b',
  },
  {
    coinAddress:
      '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WBTC',
    coinGeckoKey: 'bitcoin',
    farmTypes: ['DepositFarming', 'BorrowFarming'],
    pythPriceId:
      '0xc9d8b075a5c69303365ae23633d4e085199bf5c520a3b90fed1322a0342ffc33',
  },
  // '0xae478ff7d83ed072dbc5e264250e67ef58f57c99d89b447efd8a0a2e8b2be76e::coin::T', // BTC
  // '0xcc8a89c8dce9693d354449f1f73e60e14e347417854f029db5bc8e7454008abb::coin::T', // ETH
];

export const getReserveConfig = (env: AppEnv) => {
  if (env.isMainnet) {
    return RESERVES_TO_INIT_MAINNET;
  }
  if (env.isTestnet) {
    return RESERVES_TO_INIT_TESTNET;
  }
  throw Error(`reserves not configured under ${env}`);
};
