import { camelCaseKey } from '@aries-markets/create-sdk';
import { getProviderHub } from '@aries/aptos-defi/common';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import { Big, useDeferredState } from '@aries/shared/utils';
import * as $ from '@manahippo/move-to-ts';
import { u128, u64, U64, u8, U8 } from '@manahippo/move-to-ts';
import { groupBy, isNil, reverse, sortBy } from 'lodash';
import { getEconiaSDK } from '../../sdk';
import { OrdersView } from '../../sdk/idl/market';
import { MarketEventHandleCreationNumbers } from '../../sdk/idl/user';
import { useOrderbookList } from './list';

type MarketInfo = ReturnType<
  typeof useOrderbookList
>['orderbookList'][number];
export const useOrderbookDetailHub = () => {
  type OrderbookData = { value: OrderbookDetail | null; loading: boolean };
  const [orderbookMap, requestUpdateOrderbookMap] = useDeferredState<
    Record<string, OrderbookData>
  >({}, 100);

  const triggerLoadOrderbookDetail = async (marketInfo: MarketInfo) => {
    const { marketId } = marketInfo;
    const updateProfile = (
      updateFn: (oldValue: OrderbookData) => OrderbookData,
    ) =>
      requestUpdateOrderbookMap(currentMap => ({
        ...currentMap,
        [`${marketId}`]: updateFn(
          currentMap[`${marketId}`] ?? {
            loading: true,
            value: null,
          },
        ),
      }));

    updateProfile(({ value }) => ({ loading: true, value }));
    try {
      const orderbookDetail = await getOrderbookDetail(marketInfo);
      updateProfile(({ value: oldValue }) => ({
        loading: false,
        value: { tradings: [], ...oldValue, ...orderbookDetail },
      }));

      const tradings = await getMarketTradings(marketInfo);
      updateProfile(({ value }) => ({
        loading: false,
        value: value ? { ...value, tradings } : null,
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`err: fetch econia trading market ${marketId}`);
      updateProfile(() => ({ loading: false, value: null }));
    }
  };

  return {
    orderbookMap,
    triggerLoadOrderbookDetail,
  };
};

const getOrderbookDetail = async (marketInfo: MarketInfo) => {
  const { marketId, lotSize, tickSize } = marketInfo;
  const client = getProviderHub()?.provider?.client;
  const getOpenOrdersPld = await getEconiaSDK()
    .market.getOpenOrdersPaginated({
      market_id: Big(marketId),
      n_asks_max: Big(50),
      n_bids_max: Big(50),
      start_ask_id: Big(0),
      start_bid_id: Big(0),
    })
    .makePayload();
  const openOrders = (await client?.view(getOpenOrdersPld))?.at(0);

  const { asks: askOrders, bids: bidOrders } = camelCaseKey(
    openOrders ?? {
      asks: [],
      bids: [],
    },
  ) as OrdersView;

  const groupOrders = (arr: typeof askOrders, isBid: boolean) => {
    const parsed = arr.map(({ remainingSize, price }) => {
      return {
        price: Big(price).div(lotSize).mul(tickSize),
        lamports: Big(remainingSize).mul(lotSize),
      };
    });

    const groupedByPrice = groupBy(parsed, o => o.price.toString());

    let cumulativeTotal = Big(0);
    const sorted = sortBy(
      Object.entries(groupedByPrice).map(([price, orders]) => ({
        price,
        priceNum: Number(price),
        lamports: orders.reduce(
          (sum, cur) => sum.add(cur.lamports),
          Big(0),
        ),
      })),
      v => (isBid ? -v.priceNum : v.priceNum),
    );

    const totalLamports = sorted.reduce(
      (sum, cur) => sum.add(cur.lamports),
      Big(0),
    );

    return sorted.map(v => {
      cumulativeTotal = cumulativeTotal.add(v.lamports);
      return {
        ...v,
        totalLamports: cumulativeTotal,
        depthPct:
          (totalLamports.eq(0)
            ? 0
            : cumulativeTotal.div(totalLamports).toNumber()) * 100,
      };
    });
  };

  const asks = groupOrders(askOrders, false);
  const bids = groupOrders(bidOrders, true);

  const maxBidLamports = (() => {
    let maxPrice = Big(bids[0]?.price ?? 0);
    for (const o of bids) {
      if (maxPrice.lt(o.price)) {
        maxPrice = Big(o.price);
      }
    }
    return maxPrice;
  })();

  const minAskLamports = (() => {
    let minPrice = Big(asks[0]?.price ?? 0);
    for (const o of asks) {
      if (minPrice.gt(o.price)) {
        minPrice = Big(o.price);
      }
    }
    return minPrice;
  })();

  return {
    asks,
    bids,
    maxBidLamports,
    minAskLamports,
  };
};

const NO_CUSTODIAN = Big(0);
const getMarketTradings = async (
  marketInfo: MarketInfo,
): Promise<OrderbookDetail['tradings']> => {
  const walletAddress = getWalletCtx()?.walletAddress;
  const client = getProviderHub()?.provider?.client;
  if (isNil(walletAddress) || isNil(client)) {
    return [];
  }
  const getMarketEventHandleCreationNumbersPld = await getEconiaSDK()
    .user.getMarketEventHandleCreationNumbers({
      user: walletAddress,
      market_id: Big(marketInfo.marketId),
      custodian_id: NO_CUSTODIAN,
    })
    .makePayload();
  const creationNumbers = camelCaseKey(
    (
      (await client.view(getMarketEventHandleCreationNumbersPld))[0] as any
    )?.vec?.at(0),
  ) as MarketEventHandleCreationNumbers | undefined;
  if (isNil(creationNumbers)) {
    return [];
  }
  return (
    client
      .getEventsByCreationNumber(
        walletAddress,
        `${creationNumbers.fillEventsHandleCreationNum}`,
      )
      .then(events => {
        return reverse(events).map(event => {
          const { data } = event;
          // version does exist, just not in the typing
          const { version, sequence_number: SequenceNum } = event as any;
          return {
            id: `${version}-${SequenceNum}`,
            version: `${version}`,
            priceLamports: Big(data.price),
            isBid: !data.maker_side,
            lamports: Big(data.size),
          };
        });
      }) ?? []
  );
};

export const HI_PRICE: U64 = u64('4294967295');
export const SHIFT_COUNTER: U8 = u8('64');
export const HI_64: U64 = u64('18446744073709551615');

export const parseOrderId = (orderId: string) => {
  try {
    const price = u64($.copy(u128(orderId)).and(u128($.copy(HI_PRICE))));
    const serialId = u64(
      $.copy(u128(orderId))
        .shr($.copy(SHIFT_COUNTER))
        .and(u128($.copy(HI_64))),
    );
    return {
      price: Big(price.toBigInt().toString()),
      serialId: Big(serialId.toBigInt().toString()),
    };
  } catch (err) {
    return {
      price: Big(0),
      serialId: Big(0),
    };
  }
};

type OrderbookDetail = {
  asks: {
    totalLamports: Big;
    depthPct: number;
    price: string;
    priceNum: number;
    lamports: Big;
  }[];
  bids: {
    totalLamports: Big;
    depthPct: number;
    price: string;
    priceNum: number;
    lamports: Big;
  }[];
  tradings: {
    id: string;
    version: string;
    priceLamports: Big;
    lamports: Big;
    isBid: boolean;
  }[];
  maxBidLamports: Big;
  minAskLamports: Big;
};
