import {
  AptosProvider,
  camelCaseKey,
  deserialize,
  MoveValueType,
  StructValueType,
} from '@aries-markets/create-sdk';
import { compact, isString } from 'lodash';
import { delay, parseTypeInfo } from './util';

// Define inner custom runtime deserilizer here
export const getIterableValueResolver = (
  value: { handle: string; headKeyValue?: any; tailKeyValue?: any },
  ctx: {
    path?: string;
    schema: MoveValueType;
    provider: AptosProvider;
    program: string;
  },
) => {
  if (isString(ctx.schema)) {
    return value;
  }

  const { typeArgs: [, valueType] = [] } = ctx.schema;

  const valueSchema = valueType as StructValueType;

  const getItem = async (
    keyValue: any,
    options?: { version?: number | bigint },
  ) => {
    try {
      const v = `${ctx.program}::${valueSchema.module}::${valueSchema.structName}`;

      const res = await ctx.provider.client.getTableItem(
        value.handle,
        {
          key_type: '0x1::type_info::TypeInfo',
          value_type: `${ctx.program}::iterable_table::IterableValue<0x1::type_info::TypeInfo, ${v}>`,
          key: keyValue,
        },
        {
          ledgerVersion: options?.version,
        },
      );

      const parsed = await deserialize(res.val, {
        ...ctx,
        schema: (valueType as StructValueType).schema,
      });

      return {
        next: res.next as { vec: any[] } | null,
        value: camelCaseKey(parsed),
        key: parseTypeInfo(keyValue),
      };
    } catch (err) {
      return null;
    }
  };

  const fetch = async (options?: {
    version?: number | bigint;
    noDelay?: boolean;
  }) => {
    const res: any[] = [];
    let next = value.headKeyValue;

    while (next) {
      // eslint-disable-next-line no-await-in-loop
      const item = await getItem(next, options);

      next = item?.next?.vec?.[0];

      res.push({
        value: item?.value,
        key: item?.key!,
      });
      if (!options?.noDelay) {
        // eslint-disable-next-line no-await-in-loop
        await delay(15);
      }
    }

    return compact(res);
  };

  return {
    fetch,
    handle: value.handle,
  };
};
