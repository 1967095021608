import { Profile } from '@aries-markets/aries-tssdk/idl/profile';
import { camelCaseKey } from '@aries-markets/create-sdk';
import { EMode } from '@aries/aptos-defi/../aries-tssdk/src/idl/emode_category';
import { typeInfoToStr } from '@aries/aptos-defi//utils';
import { getAriesProgram, getProviderHub } from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import { Big, delay, useDeferredState } from '@aries/shared/utils';
import { isNil } from 'lodash';

export const useProfileDetailHub = () => {
  type ProfileData = { value: ProfileDetail | null; loading: boolean };
  const [profileMap, requestUpdateProfileMap] = useDeferredState<
    Record<string, ProfileData>
  >({}, 100);

  const triggerLoadProfileDetail = async (
    profileAddress: string,
    profileName: string,
  ) => {
    const updateProfile = (
      updateFn: (oldValue: ProfileData) => ProfileData,
    ) =>
      requestUpdateProfileMap(currentMap => ({
        ...currentMap,
        [profileAddress]: updateFn(
          currentMap[profileAddress] ?? { loading: true, value: null },
        ),
      }));

    updateProfile(({ value }) => ({ loading: true, value }));
    try {
      const { loans, deposits, getRewards, profileEmode } =
        await getProfileBasics(profileAddress, profileName);
      updateProfile(({ value }) => ({
        loading: false,
        value: { ...value, loans, deposits, profileEmode },
      }));
      const rewards = await getRewards();
      updateProfile(({ value }) => ({
        loading: false,
        value: value ? { ...value, rewards } : null,
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`err: fetch profile ${profileAddress}`);
      updateProfile(() => ({ loading: false, value: null }));
    }
  };

  return {
    profileMap,
    triggerLoadProfileDetail,
  };
};

const getProfileBasics = async (
  profileAddress: string,
  profileName: string,
) => {
  const sdk = getAriesSDK();
  const res = await sdk.profile.Profile.fetch.fromAddress(profileAddress);

  const rawDeposits = await res.depositedReserves.fetch();
  const rawLoans = await res.borrowedReserves.fetch();

  const loans = rawLoans.map(v => ({
    coinAddress: typeInfoToStr(v.key),
    ...v.value,
  }));

  const deposits = rawDeposits.map(v => ({
    coinAddress: typeInfoToStr(v.key),
    ...v.value,
  }));

  const profileEmode = await getProfileEmode(profileAddress);
  return {
    loans,
    deposits,
    profileEmode,
    getRewards: () => getProfileRewards({ ...res, profileName }),
  };
};

const getProfileEmode = async (profileAddress: string) => {
  const sdk = getAriesSDK();

  const aptosClient = getProviderHub()?.provider.client;
  if (!aptosClient) {
    return undefined;
  }
  try {
    const getProfileEmodeIdPayload = await sdk.emode_category
      .profileEmode({
        profile_account: profileAddress,
      })
      .makePayload();
    const profileEmodeId = (
      (await aptosClient.view(getProfileEmodeIdPayload))[0] as any
    ).vec[0];
    await delay(400);
    if (profileEmodeId) {
      const getEmodeConfigPayload = await sdk.emode_category
        .emodeConfig({
          emode_id: profileEmodeId,
        })
        .makePayload();
      const profileEmode = camelCaseKey(
        (await aptosClient.view(getEmodeConfigPayload))[0],
      ) as EMode;

      return {
        emodeId: profileEmodeId,
        ...profileEmode,
        liquidationBonusBips: Big(profileEmode.liquidationBonusBips),
      };
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return undefined;
};

const getProfileRewards = async (
  profile: Profile & { profileName: string },
) => {
  const fetchParseFarms = async (
    farmTable: Profile['borrowFarms'] | Profile['depositFarms'],
    farmType: 'DepositFarming' | 'BorrowFarming',
  ) => {
    const profileFarms = await farmTable.fetch().then(farms =>
      Promise.all(
        farms.map(async ({ key, value }) => ({
          key,
          value: { ...value, rewards: await value.rewards.fetch() },
        })),
      ),
    );
    const walletAddress = getWalletCtx()?.walletAddress;
    const aptosClient = getProviderHub()?.provider.client;
    if (!walletAddress || isNil(aptosClient)) {
      return [];
    }

    return Promise.all(
      profileFarms
        .flatMap(farm =>
          farm.value.rewards.map(reward => ({
            reserveCoinAddress: typeInfoToStr(farm.key),
            rewardCoinAddress: typeInfoToStr(reward.key),
            rewardCondition: farmType,
            share: farm.value.share,
            ...reward.value,
          })),
        )
        .map(
          async ({ lastRewardPerShare, unclaimedAmount, ...pReward }) => {
            const getProfileFarmingCoinRewardPld = await getAriesSDK()
              .profile.profileFarmCoin(
                {
                  user_address: walletAddress,
                  profile_name: profile.profileName,
                },
                {
                  ReserveType: pReward.reserveCoinAddress,
                  RewardCoin: pReward.rewardCoinAddress,
                  FarmingType: `${getAriesProgram()}::reserve_config::${farmType}`,
                },
              )
              .makePayload();
            try {
              const claimableLamports = (await aptosClient.view(
                getProfileFarmingCoinRewardPld,
              )) as string[];
              return {
                ...pReward,
                claimableLamports: Big(claimableLamports?.[0] || '1').div(
                  Big(10).pow(18),
                ),
              };
              // eslint-disable-next-line no-empty
            } catch (error) {}

            return {
              ...pReward,
              claimableLamports: Big(0),
            };
          },
        ),
    );
  };

  return [
    ...(await fetchParseFarms(profile.depositFarms, 'DepositFarming')),
    ...(await fetchParseFarms(profile.borrowFarms, 'BorrowFarming')),
  ];
};

export type ProfileDetail = Pick<
  Awaited<ReturnType<typeof getProfileBasics>>,
  'loans' | 'deposits' | 'profileEmode'
> & {
  rewards?: Awaited<ReturnType<typeof getProfileRewards>>;
};
