import {
  getAriesProgram,
  getBalanceHub,
  getTokenInfoHub,
  hasEnoughGasOrThrow,
} from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { getAptosLendingApp } from '@aries/aptos-defi/lending';
import {
  getCurrentProfile,
  getProfileHub,
  getReserves,
} from '@aries/aptos-defi/lending/data';
import { getPythUpdatePayload } from '@aries/aptos-defi/oracle';
import { withSendTxNotify } from '@aries/aptos-defi/utils';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import Big from 'big.js';
import { FarmingType } from '../config';

const U64_MAX = Big(2).pow(64).sub(1);

export const getReservesActions = ({
  coinAddress,
}: {
  coinAddress: string;
}) => {
  const env = getAptosLendingApp()?.env!;

  const c = () => {
    hasEnoughGasOrThrow();
    return getAriesSDK().controller;
  };

  const extern = () => {
    hasEnoughGasOrThrow();
    return getAriesSDK().extern;
  };

  const refresh = () => {
    getBalanceHub()?.refresh();
    getReserves()?.refresh();
    getProfileHub()?.refreshCurrentProfile();
  };

  const deposit = withSendTxNotify(async (lamports: Big) => {
    const res = await c()
      .deposit(
        {
          amount: lamports,
          profile_name: getCurrentProfile().name,
          repay_only: false,
        },
        { Coin0: coinAddress },
      )
      .execute();

    refresh();
    return res;
  });

  const tryWithdraw = async (
    profile: ReturnType<typeof getCurrentProfile>,
    ...params: Parameters<ReturnType<typeof c>['withdraw']>
  ) => {
    const [args, typeArgs] = params;
    try {
      const quickPassTx = c().withdraw(args, typeArgs);
      if ((await quickPassTx.simulate()).success) {
        return quickPassTx.execute();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    const vaas = await getPythUpdatePayload(env, profile, [
      params[1].Coin0,
    ]);

    return extern()
      .withdraw({ ...args, pyth_vaas: vaas }, typeArgs)
      .execute();
  };

  const withdraw = withSendTxNotify(
    async (lamports: Big, isMax?: boolean) => {
      const profile = getCurrentProfile();
      const res = await tryWithdraw(
        profile,
        {
          amount: isMax ? U64_MAX : lamports,
          profile_name: profile.name,
          allow_borrow: false,
        },
        { Coin0: coinAddress },
      );
      refresh();
      return res;
    },
  );

  const borrow = withSendTxNotify(async (lamports: Big) => {
    const borrowedUSD =
      getTokenInfoHub()?.tokenMap[coinAddress].toUSDValue(lamports) ?? 0;

    if (borrowedUSD < 5) {
      throw new Error('You need to borrow at least $5 worth of assets.');
    }

    const profile = getCurrentProfile();
    const res = tryWithdraw(
      profile,
      {
        amount: lamports,
        profile_name: profile.name,
        allow_borrow: true,
      },
      { Coin0: coinAddress },
    );
    refresh();
    return res;
  });

  const repay = withSendTxNotify(
    async (lamports: Big, isMax?: boolean) => {
      isMax;
      const res = await c()
        .deposit(
          {
            amount: isMax ? U64_MAX : lamports,
            profile_name: getCurrentProfile().name,
            repay_only: true,
          },
          { Coin0: coinAddress },
        )
        .execute();

      refresh();

      return res;
    },
  );

  // const addCollateral = withSendTxNotify(async (amount?: number) => {
  //   const maxAmount =
  //     getBalanceHub()?.balanceMap[
  //       getLPTokenAddress(coinAddress)
  //     ].amount?.toNumber();

  //   const v = amount ?? maxAmount;

  //   if (!v) {
  //     throw new Error('Should input a valid number.');
  //   }

  //   const res = await c().addCollateral(
  //     { amount: Big(v), profile_name: getCurrentProfile().name },
  //     { Coin0: coinAddress },
  //   );

  //   refresh();

  //   return res;
  // });

  // const removeCollateral = withSendTxNotify(async (amount?: number) => {
  //   const currentProfile = getCurrentProfile();
  //   const maxAmount =
  //     currentProfile?.depositByCoin[coinAddress]?.lamports.toNumber();

  //   const v = amount ?? maxAmount;

  //   if (!v) {
  //     throw new Error('Should input a valid number.');
  //   }

  //   const res = await c().removeCollateral(
  //     { amount: Big(v), profile_name: currentProfile.name },
  //     { Coin0: coinAddress },
  //   );

  //   refresh();

  //   return res;
  // });

  return {
    deposit,
    withdraw,
    borrow,
    repay,
  };
};

export type ReserveActions = ReturnType<typeof getReservesActions>;

export const initAries = withSendTxNotify(async () => {
  const wallet = getWalletCtx()?.walletAddress;
  if (!wallet) throw new Error('Please connect your wallet.');

  const res = await getAriesSDK()
    .controller.init({
      admin_addr: wallet,
    })
    .execute();

  return res;
});

export const addReserve = withSendTxNotify(async (coinAddress: string) => {
  const res = await getAriesSDK()
    .controller.addReserve({
      Coin0: coinAddress,
    })
    .execute();

  if (res.success) {
    getReserves()?.refresh();
  }

  return res;
});

export const updateReserveConfig = withSendTxNotify(
  async (coinAddress: string, val: any) => {
    const res = await getAriesSDK()
      .controller.updateReserveConfig(val, {
        Coin0: coinAddress,
      })
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const updateInterestConfig = withSendTxNotify(
  async (coinAddress: string, val: any) => {
    const res = await getAriesSDK()
      .controller.updateInterestRateConfig(val, {
        Coin0: coinAddress,
      })
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const claimRewards = withSendTxNotify(
  async (profileName: string, rewardCoinAddress: string) => {
    const res = await getAriesSDK()
      .extern.claimRewards(
        { profile_name: profileName },
        { RewardCoin: rewardCoinAddress },
      )
      .execute();

    if (res.success) {
      await getProfileHub()?.refreshCurrentProfile();
    }

    return res;
  },
);

export const addReserveReward = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    amount,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    amount: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const lamports = rewardAsset.toLamports(amount.toNumber());
    const ariesSdk = getAriesSDK();
    try {
      await ariesSdk.reward_container.RewardContainer.getByType.fromProgram(
        {
          CoinType: rewardCoin,
        },
      );
    } catch (e) {
      await ariesSdk.controller
        .initRewardContainer({
          Coin0: rewardCoin,
        })
        .execute();
    }

    const res = await getAriesSDK()
      .controller.addReward(
        { amount: lamports },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const updateRewardRate = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    rewardAmountPerday,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    rewardAmountPerday: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const rewardPerDay = rewardAsset.toLamports(
      rewardAmountPerday.toNumber(),
    );
    const res = await getAriesSDK()
      .controller.updateRewardRate(
        {
          reward_per_day: rewardPerDay,
        },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);

export const removeReward = withSendTxNotify(
  async ({
    rewardCoin,
    reserveCoin,
    farmingType,
    amount,
  }: {
    reserveCoin: string;
    rewardCoin: string;
    farmingType: FarmingType;
    amount: Big;
  }) => {
    const rewardAsset = getTokenInfoHub()?.tokenMap?.[rewardCoin];
    if (!rewardAsset?.id) {
      throw new Error('Invalid Reward Coin');
    }
    const lamports = rewardAsset.toLamports(amount.toNumber());
    const res = await getAriesSDK()
      .controller.removeReward(
        {
          amount: lamports,
        },
        {
          ReserveCoin: reserveCoin,
          RewardCoin: rewardCoin,
          FarmingType: `${getAriesProgram()}::reserve_config::${farmingType}`,
        },
      )
      .execute();

    if (res.success) {
      await getReserves()?.refresh();
    }

    return res;
  },
);
