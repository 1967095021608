import Big from 'big.js';
import { CoinCapabilities } from './assets';
import { CapabilityStore } from './faucet';
import {
  EconiaFeeStore,
  IncentiveParameters,
  IntegratorFeeStores,
  UtilityCoinStore,
} from './incentives';
import {
  MarketEventHandles,
  OrderBooks,
  Orders,
  SwapperEventHandles,
} from './market';
import { GenericAsset, RecognizedMarkets, Registry } from './registry';
import { SignerCapabilityStore, TestStruct } from './resource_account';
import { Collateral, MarketAccounts } from './user';

export type EconiaDefine = {
  assets: [
    {
      CoinCapabilities: {
        type: CoinCapabilities<any>;
        typeArgs: 'CoinType';
      };
    },
    {},
  ];
  incentives: [
    {
      EconiaFeeStore: {
        type: EconiaFeeStore<any>;
        typeArgs: 'QuoteCoinType';
      };
      IncentiveParameters: { type: IncentiveParameters };
      IntegratorFeeStores: {
        type: IntegratorFeeStores<any>;
        typeArgs: 'QuoteCoinType';
      };
      UtilityCoinStore: {
        type: UtilityCoinStore<any>;
        typeArgs: 'CoinType';
      };
    },
    {
      updateIncentives: {
        args: {
          market_registration_fee: Big;
          underwriter_registration_fee: Big;
          custodian_registration_fee: Big;
          taker_fee_divisor: Big;
          integrator_fee_store_tiers: Big[][];
        };
        typeArgs: { UtilityCoinType: string };
      };
      upgradeIntegratorFeeStoreViaCoinstore: {
        args: { market_id: Big; new_tier: number };
        typeArgs: { QuoteCoinType: string; UtilityCoinType: string };
      };
      withdrawEconiaFeesAllToCoinStore: {
        args: { market_id: Big };
        typeArgs: { QuoteCoinType: string };
      };
      withdrawEconiaFeesToCoinStore: {
        args: { market_id: Big; amount: Big };
        typeArgs: { QuoteCoinType: string };
      };
      withdrawIntegratorFeesViaCoinstores: {
        args: { market_id: Big };
        typeArgs: { QuoteCoinType: string; UtilityCoinType: string };
      };
      withdrawUtilityCoinsAllToCoinStore: {
        args: never;
        typeArgs: { UtilityCoinType: string };
      };
      withdrawUtilityCoinsToCoinStore: {
        args: { amount: Big };
        typeArgs: { UtilityCoinType: string };
      };
    },
  ];
  market: [
    {
      MarketEventHandles: { type: MarketEventHandles };
      OrderBooks: { type: OrderBooks };
      SwapperEventHandles: { type: SwapperEventHandles };
      Orders: { type: Orders };
    },
    {
      getOpenOrder: {
        args: { market_id: Big; order_id: Big };
        typeArgs: never;
      };
      getOpenOrders: {
        args: { market_id: Big; n_asks_max: Big; n_bids_max: Big };
        typeArgs: never;
      };
      getOpenOrdersAll: {
        args: { market_id: Big };
        typeArgs: never;
      };
      getOpenOrdersPaginated: {
        args: {
          market_id: Big;
          n_asks_max: Big;
          n_bids_max: Big;
          start_ask_id: Big;
          start_bid_id: Big;
        };
        typeArgs: never;
      };
      cancelAllOrdersUser: {
        args: { market_id: Big; side: boolean };
        typeArgs: never;
      };
      cancelOrderUser: {
        args: { market_id: Big; side: boolean; market_order_id: Big };
        typeArgs: never;
      };
      changeOrderSizeUser: {
        args: {
          market_id: Big;
          side: boolean;
          market_order_id: Big;
          new_size: Big;
        };
        typeArgs: never;
      };
      placeLimitOrderPassiveAdvanceUserEntry: {
        args: {
          market_id: Big;
          integrator: string;
          side: boolean;
          size: Big;
          advance_style: boolean;
          target_advance_amount: Big;
        };
        typeArgs: { BaseType: string; QuoteType: string };
      };
      placeLimitOrderUserEntry: {
        args: {
          market_id: Big;
          integrator: string;
          side: boolean;
          size: Big;
          price: Big;
          restriction: number;
          self_match_behavior: number;
        };
        typeArgs: { BaseType: string; QuoteType: string };
      };
      placeMarketOrderUserEntry: {
        args: {
          market_id: Big;
          integrator: string;
          direction: boolean;
          size: Big;
          self_match_behavior: number;
        };
        typeArgs: { BaseType: string; QuoteType: string };
      };
      registerMarketBaseCoinFromCoinstore: {
        args: { lot_size: Big; tick_size: Big; min_size: Big };
        typeArgs: {
          BaseType: string;
          QuoteType: string;
          UtilityType: string;
        };
      };
      swapBetweenCoinstoresEntry: {
        args: {
          market_id: Big;
          integrator: string;
          direction: boolean;
          min_base: Big;
          max_base: Big;
          min_quote: Big;
          max_quote: Big;
          limit_price: Big;
        };
        typeArgs: { BaseType: string; QuoteType: string };
      };
      indexOrdersSdk: {
        args: { _1: Big };
        typeArgs: never;
      };
    },
  ];
  registry: [
    {
      GenericAsset: { type: GenericAsset };
      RecognizedMarkets: { type: RecognizedMarkets };
      Registry: { type: Registry };
    },
    {
      registerIntegratorFeeStoreBaseTier: {
        args: { market_id: Big };
        typeArgs: { QuoteCoinType: string; UtilityCoinType: string };
      };
      registerIntegratorFeeStoreFromCoinstore: {
        args: { market_id: Big; tier: number };
        typeArgs: { QuoteCoinType: string; UtilityCoinType: string };
      };
      removeRecognizedMarket: {
        args: { market_id: Big };
        typeArgs: never;
      };
      removeRecognizedMarkets: {
        args: { market_ids: Big[] };
        typeArgs: never;
      };
      setRecognizedMarket: {
        args: { market_id: Big };
        typeArgs: never;
      };
      setRecognizedMarkets: {
        args: { market_ids: Big[] };
        typeArgs: never;
      };
    },
  ];
  resource_account: [
    {
      SignerCapabilityStore: { type: SignerCapabilityStore };
      TestStruct: { type: TestStruct };
    },
    {},
  ];
  user: [
    {
      Collateral: { type: Collateral<any>; typeArgs: 'CoinType' };
      MarketAccounts: { type: MarketAccounts };
      MarketEventHandles: { type: MarketEventHandles };
    },
    {
      getMarketEventHandleCreationNumbers: {
        args: { user: string; market_id: Big; custodian_id: Big };
        typeArgs: never;
      };
      depositFromCoinstore: {
        args: { market_id: Big; custodian_id: Big; amount: Big };
        typeArgs: { CoinType: string };
      };
      initMarketEventHandlesIfMissing: {
        args: { market_id: Big; custodian_id: Big };
        typeArgs: never;
      };
      registerMarketAccount: {
        args: { market_id: Big; custodian_id: Big };
        typeArgs: { BaseType: string; QuoteType: string };
      };
      registerMarketAccountGenericBase: {
        args: { market_id: Big; custodian_id: Big };
        typeArgs: { QuoteType: string };
      };
      withdrawToCoinstore: {
        args: { market_id: Big; amount: Big };
        typeArgs: { CoinType: string };
      };
    },
  ];
  faucet: [
    {
      CapabilityStore: {
        type: CapabilityStore<any>;
        typeArgs: 'CoinType';
      };
    },
    {
      initialize: {
        args: {
          name: string;
          symbol: string;
          decimals: number;
          monitor_supply: boolean;
        };
        typeArgs: { CoinType: string };
      };
      mint: {
        args: { amount: Big };
        typeArgs: { CoinType: string };
      };
    },
  ];
};

export const EconiaIdl = {
  modules: [
    {
      name: 'assets',
      displayName: 'assets',
      methods: [],
      structs: [
        {
          name: 'CoinCapabilities',
          displayName: 'CoinCapabilities',
          typeArgs: [{ name: 'CoinType' }],
          moveSchema: {
            burn_capability: {
              structName: { module: 'coin', member: 'BurnCapability' },
              typeArgs: ['CoinType'],
            },
            freeze_capability: {
              structName: { module: 'coin', member: 'FreezeCapability' },
              typeArgs: ['CoinType'],
            },
            mint_capability: {
              structName: { module: 'coin', member: 'MintCapability' },
              typeArgs: ['CoinType'],
            },
          },
        },
      ],
    },
    {
      name: 'incentives',
      displayName: 'incentives',
      methods: [
        {
          name: 'update_incentives',
          displayName: 'updateIncentives',
          args: [
            {
              name: 'market_registration_fee',
              jsType: 'Big',
              moveType: 'u64',
            },
            {
              name: 'underwriter_registration_fee',
              jsType: 'Big',
              moveType: 'u64',
            },
            {
              name: 'custodian_registration_fee',
              jsType: 'Big',
              moveType: 'u64',
            },
            { name: 'taker_fee_divisor', jsType: 'Big', moveType: 'u64' },
            {
              name: 'integrator_fee_store_tiers',
              jsType: 'Big[][]',
              moveType: 'vector',
            },
          ],
          typeArgs: [{ name: 'UtilityCoinType' }],
        },
        {
          name: 'upgrade_integrator_fee_store_via_coinstore',
          displayName: 'upgradeIntegratorFeeStoreViaCoinstore',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'new_tier', jsType: 'number', moveType: 'u8' },
          ],
          typeArgs: [
            { name: 'QuoteCoinType' },
            { name: 'UtilityCoinType' },
          ],
        },
        {
          name: 'withdraw_econia_fees_all_to_coin_store',
          displayName: 'withdrawEconiaFeesAllToCoinStore',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [{ name: 'QuoteCoinType' }],
        },
        {
          name: 'withdraw_econia_fees_to_coin_store',
          displayName: 'withdrawEconiaFeesToCoinStore',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'QuoteCoinType' }],
        },
        {
          name: 'withdraw_integrator_fees_via_coinstores',
          displayName: 'withdrawIntegratorFeesViaCoinstores',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [
            { name: 'QuoteCoinType' },
            { name: 'UtilityCoinType' },
          ],
        },
        {
          name: 'withdraw_utility_coins_all_to_coin_store',
          displayName: 'withdrawUtilityCoinsAllToCoinStore',
          args: [],
          typeArgs: [{ name: 'UtilityCoinType' }],
        },
        {
          name: 'withdraw_utility_coins_to_coin_store',
          displayName: 'withdrawUtilityCoinsToCoinStore',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [{ name: 'UtilityCoinType' }],
        },
      ],
      structs: [
        {
          name: 'EconiaFeeStore',
          displayName: 'EconiaFeeStore',
          typeArgs: [{ name: 'QuoteCoinType' }],
          moveSchema: {
            map: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: ['u64', 'Coin'],
            },
          },
        },
        {
          name: 'IncentiveParameters',
          displayName: 'IncentiveParameters',
          typeArgs: [],
          moveSchema: {
            utility_coin_type_info: 'TypeInfo',
            market_registration_fee: 'u64',
            underwriter_registration_fee: 'u64',
            custodian_registration_fee: 'u64',
            taker_fee_divisor: 'u64',
            integrator_fee_store_tiers: {
              structName: 'vector',
              typeArgs: [
                {
                  structName: 'IntegratorFeeStoreTierParameters',
                  module: 'incentives',
                  schema: {
                    fee_share_divisor: 'u64',
                    tier_activation_fee: 'u64',
                    withdrawal_fee: 'u64',
                  },
                },
              ],
            },
          },
        },
        {
          name: 'IntegratorFeeStores',
          displayName: 'IntegratorFeeStores',
          typeArgs: [{ name: 'QuoteCoinType' }],
          moveSchema: {
            map: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: [
                'u64',
                {
                  structName: 'IntegratorFeeStore',
                  module: 'incentives',
                  schema: {
                    tier: 'u8',
                    coins: {
                      structName: 'Coin',
                      typeArgs: ['QuoteCoinType'],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'UtilityCoinStore',
          displayName: 'UtilityCoinStore',
          typeArgs: [{ name: 'CoinType' }],
          moveSchema: {
            coins: { structName: 'Coin', typeArgs: ['CoinType'] },
          },
        },
      ],
    },
    {
      name: 'market',
      displayName: 'market',
      methods: [
        {
          name: 'get_open_order',
          displayName: 'getOpenOrder',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'order_id', jsType: 'Big', moveType: 'u128' },
          ],
          typeArgs: [],
        },
        {
          name: 'get_open_orders',
          displayName: 'getOpenOrders',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'n_asks_max', jsType: 'Big', moveType: 'u64' },
            { name: 'n_bids_max', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [],
        },
        {
          name: 'get_open_orders_all',
          displayName: 'getOpenOrdersAll',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [],
        },
        {
          name: 'get_open_orders_paginated',
          displayName: 'getOpenOrdersPaginated',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'n_asks_max', jsType: 'Big', moveType: 'u64' },
            { name: 'n_bids_max', jsType: 'Big', moveType: 'u64' },
            { name: 'start_ask_id', jsType: 'Big', moveType: 'u128' },
            { name: 'start_bid_id', jsType: 'Big', moveType: 'u128' },
          ],
          typeArgs: [],
        },
        {
          name: 'cancel_all_orders_user',
          displayName: 'cancelAllOrdersUser',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'side', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [],
        },
        {
          name: 'cancel_order_user',
          displayName: 'cancelOrderUser',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'side', jsType: 'boolean', moveType: 'bool' },
            { name: 'market_order_id', jsType: 'Big', moveType: 'u128' },
          ],
          typeArgs: [],
        },
        {
          name: 'change_order_size_user',
          displayName: 'changeOrderSizeUser',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'side', jsType: 'boolean', moveType: 'bool' },
            { name: 'market_order_id', jsType: 'Big', moveType: 'u128' },
            { name: 'new_size', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [],
        },
        {
          name: 'place_limit_order_passive_advance_user_entry',
          displayName: 'placeLimitOrderPassiveAdvanceUserEntry',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'integrator', jsType: 'string', moveType: 'address' },
            { name: 'side', jsType: 'boolean', moveType: 'bool' },
            { name: 'size', jsType: 'Big', moveType: 'u64' },
            { name: 'advance_style', jsType: 'boolean', moveType: 'bool' },
            {
              name: 'target_advance_amount',
              jsType: 'Big',
              moveType: 'u64',
            },
          ],
          typeArgs: [{ name: 'BaseType' }, { name: 'QuoteType' }],
        },
        {
          name: 'place_limit_order_user_entry',
          displayName: 'placeLimitOrderUserEntry',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'integrator', jsType: 'string', moveType: 'address' },
            { name: 'side', jsType: 'boolean', moveType: 'bool' },
            { name: 'size', jsType: 'Big', moveType: 'u64' },
            { name: 'price', jsType: 'Big', moveType: 'u64' },
            { name: 'restriction', jsType: 'number', moveType: 'u8' },
            {
              name: 'self_match_behavior',
              jsType: 'number',
              moveType: 'u8',
            },
          ],
          typeArgs: [{ name: 'BaseType' }, { name: 'QuoteType' }],
        },
        {
          name: 'place_market_order_user_entry',
          displayName: 'placeMarketOrderUserEntry',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'integrator', jsType: 'string', moveType: 'address' },
            { name: 'direction', jsType: 'boolean', moveType: 'bool' },
            { name: 'size', jsType: 'Big', moveType: 'u64' },
            {
              name: 'self_match_behavior',
              jsType: 'number',
              moveType: 'u8',
            },
          ],
          typeArgs: [{ name: 'BaseType' }, { name: 'QuoteType' }],
        },
        {
          name: 'register_market_base_coin_from_coinstore',
          displayName: 'registerMarketBaseCoinFromCoinstore',
          args: [
            { name: 'lot_size', jsType: 'Big', moveType: 'u64' },
            { name: 'tick_size', jsType: 'Big', moveType: 'u64' },
            { name: 'min_size', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [
            { name: 'BaseType' },
            { name: 'QuoteType' },
            { name: 'UtilityType' },
          ],
        },
        {
          name: 'swap_between_coinstores_entry',
          displayName: 'swapBetweenCoinstoresEntry',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'integrator', jsType: 'string', moveType: 'address' },
            { name: 'direction', jsType: 'boolean', moveType: 'bool' },
            { name: 'min_base', jsType: 'Big', moveType: 'u64' },
            { name: 'max_base', jsType: 'Big', moveType: 'u64' },
            { name: 'min_quote', jsType: 'Big', moveType: 'u64' },
            { name: 'max_quote', jsType: 'Big', moveType: 'u64' },
            { name: 'limit_price', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'BaseType' }, { name: 'QuoteType' }],
        },
        {
          name: 'index_orders_sdk',
          displayName: 'indexOrdersSdk',
          args: [{ name: '_1', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [],
        },
      ],
      structs: [
        {
          name: 'MarketEventHandles',
          displayName: 'MarketEventHandles',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Table',
              typeArgs: [
                'u64',
                {
                  structName: 'MarketEventHandlesForMarket',
                  module: 'market',
                  schema: {
                    cancel_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'CancelOrderEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            order_id: 'u128',
                            user: 'address',
                            custodian_id: 'u64',
                            reason: 'u8',
                          },
                        },
                      ],
                    },
                    place_swap_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'PlaceSwapOrderEvent',
                          module: 'market',
                          schema: {
                            market_id: 'u64',
                            signing_account: 'address',
                            integrator: 'address',
                            direction: 'bool',
                            min_base: 'u64',
                            max_base: 'u64',
                            min_quote: 'u64',
                            max_quote: 'u64',
                            limit_price: 'u64',
                            order_id: 'u128',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'OrderBooks',
          displayName: 'OrderBooks',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: [
                'u64',
                {
                  structName: 'OrderBook',
                  module: 'market',
                  schema: {
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                    asks: {
                      structName: 'AVLqueue',
                      module: 'avl_queue',
                      schema: {
                        bits: 'u128',
                        root_lsbs: 'u8',
                        tree_nodes: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'u64',
                            {
                              structName: 'TreeNode',
                              module: 'avl_queue',
                              schema: { bits: 'u128' },
                            },
                          ],
                        },
                        list_nodes: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'u64',
                            {
                              structName: 'ListNode',
                              module: 'avl_queue',
                              schema: {
                                last_msbs: 'u8',
                                last_lsbs: 'u8',
                                next_msbs: 'u8',
                                next_lsbs: 'u8',
                              },
                            },
                          ],
                        },
                        values: {
                          structName: 'Table',
                          typeArgs: ['u64', 'Option'],
                        },
                      },
                      typeArgs: [
                        {
                          structName: 'Order',
                          module: 'market',
                          schema: {
                            size: 'u64',
                            price: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            order_access_key: 'u64',
                          },
                        },
                      ],
                    },
                    bids: {
                      structName: 'AVLqueue',
                      module: 'avl_queue',
                      schema: {
                        bits: 'u128',
                        root_lsbs: 'u8',
                        tree_nodes: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'u64',
                            {
                              structName: 'TreeNode',
                              module: 'avl_queue',
                              schema: { bits: 'u128' },
                            },
                          ],
                        },
                        list_nodes: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'u64',
                            {
                              structName: 'ListNode',
                              module: 'avl_queue',
                              schema: {
                                last_msbs: 'u8',
                                last_lsbs: 'u8',
                                next_msbs: 'u8',
                                next_lsbs: 'u8',
                              },
                            },
                          ],
                        },
                        values: {
                          structName: 'Table',
                          typeArgs: ['u64', 'Option'],
                        },
                      },
                      typeArgs: [
                        {
                          structName: 'Order',
                          module: 'market',
                          schema: {
                            size: 'u64',
                            price: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            order_access_key: 'u64',
                          },
                        },
                      ],
                    },
                    counter: 'u64',
                    maker_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'MakerEvent',
                          module: 'market',
                          schema: {
                            market_id: 'u64',
                            side: 'bool',
                            market_order_id: 'u128',
                            user: 'address',
                            custodian_id: 'u64',
                            type: 'u8',
                            size: 'u64',
                            price: 'u64',
                          },
                        },
                      ],
                    },
                    taker_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'TakerEvent',
                          module: 'market',
                          schema: {
                            market_id: 'u64',
                            side: 'bool',
                            market_order_id: 'u128',
                            maker: 'address',
                            custodian_id: 'u64',
                            size: 'u64',
                            price: 'u64',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'SwapperEventHandles',
          displayName: 'SwapperEventHandles',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Table',
              typeArgs: [
                'u64',
                {
                  structName: 'SwapperEventHandlesForMarket',
                  module: 'market',
                  schema: {
                    cancel_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'CancelOrderEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            order_id: 'u128',
                            user: 'address',
                            custodian_id: 'u64',
                            reason: 'u8',
                          },
                        },
                      ],
                    },
                    fill_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'FillEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            size: 'u64',
                            price: 'u64',
                            maker_side: 'bool',
                            maker: 'address',
                            maker_custodian_id: 'u64',
                            maker_order_id: 'u128',
                            taker: 'address',
                            taker_custodian_id: 'u64',
                            taker_order_id: 'u128',
                            taker_quote_fees_paid: 'u64',
                            sequence_number_for_trade: 'u64',
                          },
                        },
                      ],
                    },
                    place_swap_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'PlaceSwapOrderEvent',
                          module: 'market',
                          schema: {
                            market_id: 'u64',
                            signing_account: 'address',
                            integrator: 'address',
                            direction: 'bool',
                            min_base: 'u64',
                            max_base: 'u64',
                            min_quote: 'u64',
                            max_quote: 'u64',
                            limit_price: 'u64',
                            order_id: 'u128',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'Orders',
          displayName: 'Orders',
          typeArgs: [],
          moveSchema: {
            asks: {
              structName: 'vector',
              typeArgs: [
                {
                  structName: 'Order',
                  module: 'market',
                  schema: {
                    size: 'u64',
                    price: 'u64',
                    user: 'address',
                    custodian_id: 'u64',
                    order_access_key: 'u64',
                  },
                },
              ],
            },
            bids: {
              structName: 'vector',
              typeArgs: [
                {
                  structName: 'Order',
                  module: 'market',
                  schema: {
                    size: 'u64',
                    price: 'u64',
                    user: 'address',
                    custodian_id: 'u64',
                    order_access_key: 'u64',
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'registry',
      displayName: 'registry',
      methods: [
        {
          name: 'register_integrator_fee_store_base_tier',
          displayName: 'registerIntegratorFeeStoreBaseTier',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [
            { name: 'QuoteCoinType' },
            { name: 'UtilityCoinType' },
          ],
        },
        {
          name: 'register_integrator_fee_store_from_coinstore',
          displayName: 'registerIntegratorFeeStoreFromCoinstore',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'tier', jsType: 'number', moveType: 'u8' },
          ],
          typeArgs: [
            { name: 'QuoteCoinType' },
            { name: 'UtilityCoinType' },
          ],
        },
        {
          name: 'remove_recognized_market',
          displayName: 'removeRecognizedMarket',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [],
        },
        {
          name: 'remove_recognized_markets',
          displayName: 'removeRecognizedMarkets',
          args: [
            { name: 'market_ids', jsType: 'Big[]', moveType: 'vector' },
          ],
          typeArgs: [],
        },
        {
          name: 'set_recognized_market',
          displayName: 'setRecognizedMarket',
          args: [{ name: 'market_id', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [],
        },
        {
          name: 'set_recognized_markets',
          displayName: 'setRecognizedMarkets',
          args: [
            { name: 'market_ids', jsType: 'Big[]', moveType: 'vector' },
          ],
          typeArgs: [],
        },
      ],
      structs: [
        {
          name: 'GenericAsset',
          displayName: 'GenericAsset',
          typeArgs: [],
          moveSchema: {},
        },
        {
          name: 'RecognizedMarkets',
          displayName: 'RecognizedMarkets',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: [
                {
                  structName: 'TradingPair',
                  module: 'registry',
                  schema: {
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                  },
                },
                {
                  structName: 'RecognizedMarketInfo',
                  module: 'registry',
                  schema: {
                    market_id: 'u64',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                  },
                },
              ],
            },
            recognized_market_events: {
              structName: 'EventHandle',
              typeArgs: [
                {
                  structName: 'RecognizedMarketEvent',
                  module: 'registry',
                  schema: {
                    trading_pair: {
                      structName: 'TradingPair',
                      module: 'registry',
                      schema: {
                        base_type: 'TypeInfo',
                        base_name_generic: 'String',
                        quote_type: 'TypeInfo',
                      },
                    },
                    recognized_market_info: {
                      structName: 'Option',
                      typeArgs: [
                        {
                          structName: 'RecognizedMarketInfo',
                          module: 'registry',
                          schema: {
                            market_id: 'u64',
                            lot_size: 'u64',
                            tick_size: 'u64',
                            min_size: 'u64',
                            underwriter_id: 'u64',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'Registry',
          displayName: 'Registry',
          typeArgs: [],
          moveSchema: {
            market_id_to_info: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: [
                'u64',
                {
                  structName: 'MarketInfo',
                  module: 'registry',
                  schema: {
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                  },
                },
              ],
            },
            market_info_to_id: {
              structName: 'Table',
              typeArgs: [
                {
                  structName: 'MarketInfo',
                  module: 'registry',
                  schema: {
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                  },
                },
                'u64',
              ],
            },
            n_custodians: 'u64',
            n_underwriters: 'u64',
            market_registration_events: {
              structName: 'EventHandle',
              typeArgs: [
                {
                  structName: 'MarketRegistrationEvent',
                  module: 'registry',
                  schema: {
                    market_id: 'u64',
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'resource_account',
      displayName: 'resource_account',
      methods: [],
      structs: [
        {
          name: 'SignerCapabilityStore',
          displayName: 'SignerCapabilityStore',
          typeArgs: [],
          moveSchema: { signer_capability: 'SignerCapability' },
        },
        {
          name: 'TestStruct',
          displayName: 'TestStruct',
          typeArgs: [],
          moveSchema: {},
        },
      ],
    },
    {
      name: 'user',
      displayName: 'user',
      methods: [
        {
          name: 'deposit_from_coinstore',
          displayName: 'depositFromCoinstore',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'custodian_id', jsType: 'Big', moveType: 'u64' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'CoinType' }],
        },
        {
          name: 'get_market_event_handle_creation_numbers',
          displayName: 'getMarketEventHandleCreationNumbers',
          args: [
            { name: 'user', jsType: 'string', moveType: 'address' },
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'custodian_id', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [],
        },
        {
          name: 'init_market_event_handles_if_missing',
          displayName: 'initMarketEventHandlesIfMissing',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'custodian_id', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [],
        },
        {
          name: 'register_market_account',
          displayName: 'registerMarketAccount',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'custodian_id', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'BaseType' }, { name: 'QuoteType' }],
        },
        {
          name: 'register_market_account_generic_base',
          displayName: 'registerMarketAccountGenericBase',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'custodian_id', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'QuoteType' }],
        },
        {
          name: 'withdraw_to_coinstore',
          displayName: 'withdrawToCoinstore',
          args: [
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'CoinType' }],
        },
      ],
      structs: [
        {
          name: 'Collateral',
          displayName: 'Collateral',
          typeArgs: [{ name: 'CoinType' }],
          moveSchema: {
            map: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: ['u128', 'Coin'],
            },
          },
        },
        {
          name: 'MarketAccounts',
          displayName: 'MarketAccounts',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Table',
              typeArgs: [
                'u128',
                {
                  structName: 'MarketAccount',
                  module: 'user',
                  schema: {
                    base_type: 'TypeInfo',
                    base_name_generic: 'String',
                    quote_type: 'TypeInfo',
                    lot_size: 'u64',
                    tick_size: 'u64',
                    min_size: 'u64',
                    underwriter_id: 'u64',
                    asks: {
                      structName: 'Tablist',
                      module: 'tablist',
                      schema: {
                        table: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'K',
                            {
                              structName: 'Node',
                              module: 'tablist',
                              schema: {
                                value: 'V',
                                previous: {
                                  structName: 'Option',
                                  typeArgs: ['K'],
                                },
                                next: {
                                  structName: 'Option',
                                  typeArgs: ['K'],
                                },
                              },
                            },
                          ],
                        },
                        head: { structName: 'Option', typeArgs: ['K'] },
                        tail: { structName: 'Option', typeArgs: ['K'] },
                      },
                      typeArgs: [
                        'u64',
                        {
                          structName: 'Order',
                          module: 'market',
                          schema: {
                            size: 'u64',
                            price: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            order_access_key: 'u64',
                          },
                        },
                      ],
                    },
                    bids: {
                      structName: 'Tablist',
                      module: 'tablist',
                      schema: {
                        table: {
                          structName: 'TableWithLength',
                          typeArgs: [
                            'K',
                            {
                              structName: 'Node',
                              module: 'tablist',
                              schema: {
                                value: 'V',
                                previous: {
                                  structName: 'Option',
                                  typeArgs: ['K'],
                                },
                                next: {
                                  structName: 'Option',
                                  typeArgs: ['K'],
                                },
                              },
                            },
                          ],
                        },
                        head: { structName: 'Option', typeArgs: ['K'] },
                        tail: { structName: 'Option', typeArgs: ['K'] },
                      },
                      typeArgs: [
                        'u64',
                        {
                          structName: 'Order',
                          module: 'market',
                          schema: {
                            size: 'u64',
                            price: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            order_access_key: 'u64',
                          },
                        },
                      ],
                    },
                    asks_stack_top: 'u64',
                    bids_stack_top: 'u64',
                    base_total: 'u64',
                    base_available: 'u64',
                    base_ceiling: 'u64',
                    quote_total: 'u64',
                    quote_available: 'u64',
                    quote_ceiling: 'u64',
                  },
                },
              ],
            },
            custodians: {
              structName: 'Tablist',
              module: 'tablist',
              schema: {
                table: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'K',
                    {
                      structName: 'Node',
                      module: 'tablist',
                      schema: {
                        value: 'V',
                        previous: {
                          structName: 'Option',
                          typeArgs: ['K'],
                        },
                        next: { structName: 'Option', typeArgs: ['K'] },
                      },
                    },
                  ],
                },
                head: { structName: 'Option', typeArgs: ['K'] },
                tail: { structName: 'Option', typeArgs: ['K'] },
              },
              typeArgs: ['u64', 'vector'],
            },
          },
        },
        {
          name: 'MarketEventHandles',
          displayName: 'MarketEventHandles',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Table',
              typeArgs: [
                'u128',
                {
                  structName: 'MarketEventHandlesForMarketAccount',
                  module: 'user',
                  schema: {
                    cancel_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'CancelOrderEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            order_id: 'u128',
                            user: 'address',
                            custodian_id: 'u64',
                            reason: 'u8',
                          },
                        },
                      ],
                    },
                    change_order_size_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'ChangeOrderSizeEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            order_id: 'u128',
                            user: 'address',
                            custodian_id: 'u64',
                            side: 'bool',
                            new_size: 'u64',
                          },
                        },
                      ],
                    },
                    fill_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'FillEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            size: 'u64',
                            price: 'u64',
                            maker_side: 'bool',
                            maker: 'address',
                            maker_custodian_id: 'u64',
                            maker_order_id: 'u128',
                            taker: 'address',
                            taker_custodian_id: 'u64',
                            taker_order_id: 'u128',
                            taker_quote_fees_paid: 'u64',
                            sequence_number_for_trade: 'u64',
                          },
                        },
                      ],
                    },
                    place_limit_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'PlaceLimitOrderEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            integrator: 'address',
                            side: 'bool',
                            size: 'u64',
                            price: 'u64',
                            restriction: 'u8',
                            self_match_behavior: 'u8',
                            remaining_size: 'u64',
                            order_id: 'u128',
                          },
                        },
                      ],
                    },
                    place_market_order_events: {
                      structName: 'EventHandle',
                      typeArgs: [
                        {
                          structName: 'PlaceMarketOrderEvent',
                          module: 'user',
                          schema: {
                            market_id: 'u64',
                            user: 'address',
                            custodian_id: 'u64',
                            integrator: 'address',
                            direction: 'bool',
                            size: 'u64',
                            self_match_behavior: 'u8',
                            order_id: 'u128',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'faucet',
      displayName: 'faucet',
      methods: [
        {
          name: 'initialize',
          displayName: 'initialize',
          args: [
            { name: 'name', jsType: 'string', moveType: 'String' },
            { name: 'symbol', jsType: 'string', moveType: 'String' },
            { name: 'decimals', jsType: 'number', moveType: 'u8' },
            {
              name: 'monitor_supply',
              jsType: 'boolean',
              moveType: 'bool',
            },
          ],
          typeArgs: [{ name: 'CoinType' }],
        },
        {
          name: 'mint',
          displayName: 'mint',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [{ name: 'CoinType' }],
        },
      ],
      structs: [
        {
          name: 'CapabilityStore',
          displayName: 'CapabilityStore',
          typeArgs: [{ name: 'CoinType' }],
          moveSchema: {
            burn_cap: {
              structName: 'BurnCapability',
              typeArgs: ['CoinType'],
            },
            freeze_cap: {
              structName: 'FreezeCapability',
              typeArgs: ['CoinType'],
            },
            mint_cap: {
              structName: 'MintCapability',
              typeArgs: ['CoinType'],
            },
          },
        },
      ],
    },
  ],
};
