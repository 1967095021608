import {
  AptosProvider,
  createAptosSDK,
  defineResolver,
} from '@aries-markets/create-sdk';
import { fromDecimal } from '@aries-markets/resource-parser';
import { AriesDefine, AriesIdl } from './idl';
import { AriesWrapperDefine, AriesWrapperIdl } from './wrapper';
// Define Table / IterableTable resolve
import './resolvers';

export * from '@aries-markets/create-sdk';
export { type ReserveDetails } from './idl/reserve_details';

defineResolver('Decimal', val => fromDecimal(val));

export const getAriesSDK = (provider: AptosProvider, program = '0x1') =>
  createAptosSDK<AriesDefine>({
    provider,
    program,
    idlJSON: AriesIdl,
  });

export const getAriesWrapperSDK = (
  provider: AptosProvider,
  program = '0x1',
) =>
  createAptosSDK<AriesWrapperDefine>({
    provider,
    program,
    idlJSON: AriesWrapperIdl,
  });
