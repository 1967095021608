import { useReserves } from '@aries/lending-fe-hooks';
import {
  Button,
  CenterCol,
  CenterRow,
  Col,
  EndCol,
  Input,
  Txt,
  View,
} from '@aries/mobile-core/components';
import { openModal, Popover } from '@aries/mobile-core/Smoothy';
import {
  ErrorIcon,
  WarningIcon,
} from '@aries/mobile-core/Smoothy/components/notification/Icon';
import { useSearchList } from '@aries/shared/hooks';
import { useTranslation } from '@aries/shared/locale';
import { colorConfig, globalToken, useThemeMode } from '@aries/ui-theming';
import { isString } from 'lodash';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { IconSecondSearch, InfoItem, TokenIcon } from '~/components';
import { openReserveAction } from '~/services';
import { Card } from './Card';

const { marginXXS, marginXS, marginSM } = globalToken;
export const Assets: React.FC<{}> = () => {
  const { colorTextCritical, colorTextSuccess } = colorConfig;
  const { t } = useTranslation();
  const { reserves } = useReserves();
  const {
    search,
    setSearch,
    filteredList: renderList,
  } = useSearchList(reserves, item => [
    item.asset.name,
    item.asset.symbol,
  ]);
  const { mode } = useThemeMode();

  const data = renderList
    .filter(v => !v.meta.deprecated)
    .map(detail => {
      const borrowLimitPct =
        detail.meta.borrowLimitNum > 0
          ? (detail.borrow.balance.amountNum /
              detail.meta.borrowLimitNum) *
            100
          : 0;
      const availableBorrowBalance = detail.asset.toBalance(
        detail.meta.borrowLimitNum - detail.borrow.balance.amountNum,
      );

      const depositLimitPct =
        detail.meta.depositLimitNum > 0
          ? (detail.summary.marketCap.amountNum /
              detail.meta.depositLimitNum) *
            100
          : 0;
      const availableDepositBalance = detail.asset.toBalance(
        detail.meta.depositLimitNum - detail.summary.marketCap.amountNum,
      );
      return {
        onPress: () => {
          openReserveAction(detail.asset.id);
        },
        icon: <TokenIcon asset={detail.asset} size={29} />,
        title: detail.asset.symbol,
        subTitle: detail.asset.priceUSD,
        tags: [],
        info: [
          { title: t`LTV:`, subTitle: detail.meta.loanToValue },
          {
            title: t`Deposit APY:`,
            subTitle: (
              <EndCol>
                <Txt
                  h3
                  critical={!detail.deposit.totalApy.isPositive}
                  success={detail.deposit.totalApy.isPositive}
                >
                  {detail.deposit.totalApy.base}
                </Txt>
                {detail.deposit.rewardApy &&
                  detail.notify?.type !== 'warning' && (
                    <CenterRow gap={3}>
                      <Txt c2>{detail.deposit.baseApy.base}</Txt>
                      <Txt c1>+</Txt>
                      <Txt c2>{detail.deposit.rewardApy.base}</Txt>
                      <CenterRow gap={1}>
                        {detail.deposit.rewardAssets.map((a, index) => {
                          return (
                            <CenterRow
                              ml={index !== 0 ? -6 : 0}
                              key={a.id}
                            >
                              <TokenIcon asset={a} size={20} />
                            </CenterRow>
                          );
                        })}
                      </CenterRow>
                    </CenterRow>
                  )}
              </EndCol>
            ),
            color: !detail.borrow.totalApy.isPositive
              ? colorTextCritical[mode]
              : colorTextSuccess[mode],
          },
          {
            title: t`Market Size:`,
            subTitle: (
              <CenterRow style={{ justifyContent: 'flex-end' }}>
                <Txt
                  h2
                  warning={depositLimitPct < 99 && depositLimitPct >= 95}
                  critical={depositLimitPct >= 99}
                >
                  {detail.deposit.balance.valueUSD}
                </Txt>
                <TouchableOpacity
                  onPress={() => {
                    openModal({
                      renderContent: ({ close }) => (
                        <CenterCol
                          gap={8}
                          style={{ height: 200, justifyContent: 'center' }}
                        >
                          <Txt h5>{t`Approaching Limit`}</Txt>
                          <Txt c2>{t`${
                            detail.asset.symbol
                          } deposits are at ${depositLimitPct.toFixed(
                            2,
                          )}% capacity`}</Txt>
                          <Txt
                            c2
                          >{t`Available: ${availableDepositBalance.amount}`}</Txt>
                          <Button
                            title="Close"
                            type="ghost"
                            onPress={() => close()}
                            style={{ width: '100%' }}
                          />
                        </CenterCol>
                      ),
                      showHeader: false,
                      bodyStyle: { height: 200 },
                    });
                  }}
                >
                  {depositLimitPct < 95 ? null : depositLimitPct < 99 ? (
                    <ErrorIcon size={20} ml={5} />
                  ) : (
                    <WarningIcon size={20} ml={5} />
                  )}
                </TouchableOpacity>
              </CenterRow>
            ),
          },
          {
            title: t`Borrow APY:`,
            subTitle: (
              <EndCol>
                <Txt
                  h3
                  critical={!detail.borrow.totalApy.isPositive}
                  success={detail.borrow.totalApy.isPositive}
                  style={{ width: 'fit-content' }}
                >
                  {detail.borrow.totalApy.base}
                </Txt>
                {detail.borrow.rewardApy &&
                  detail.notify?.type !== 'warning' && (
                    <CenterRow gap={3}>
                      <Txt c2>{detail.borrow.baseApy.base}</Txt>
                      <Txt c1>+</Txt>
                      <Txt c2>{detail.borrow.rewardApy.base}</Txt>
                      <CenterRow gap={1}>
                        {detail.borrow.rewardAssets.map((a, index) => {
                          return (
                            <CenterRow
                              ml={index !== 0 ? -6 : 0}
                              key={a.id}
                            >
                              <TokenIcon asset={a} size={20} />
                            </CenterRow>
                          );
                        })}
                      </CenterRow>
                    </CenterRow>
                  )}
              </EndCol>
            ),
            color: detail.borrow.totalApy.isPositive
              ? colorTextCritical[mode]
              : colorTextSuccess[mode],
          },
          {
            title: t`Total Borrowed:`,
            subTitle: (
              <CenterRow style={{ justifyContent: 'flex-end' }}>
                <Txt
                  h2
                  warning={borrowLimitPct < 99 && borrowLimitPct >= 95}
                  critical={borrowLimitPct >= 99}
                  c2={borrowLimitPct < 95}
                >
                  {detail.borrow.balance.valueUSD}
                </Txt>
                <Popover
                  content={
                    <Col gap={8}>
                      <Txt h5>{t`Approaching Limit`}</Txt>
                      <Txt c2>{t`${
                        detail.asset.symbol
                      } borrows are at ${borrowLimitPct.toFixed(
                        2,
                      )}% capacity`}</Txt>
                      <Txt
                        c2
                      >{t`Available: ${availableBorrowBalance.amount}`}</Txt>
                    </Col>
                  }
                >
                  <TouchableOpacity
                    onPress={() => {
                      openModal({
                        renderContent: ({ close }) => (
                          <CenterCol
                            gap={8}
                            style={{
                              height: 200,
                              justifyContent: 'center',
                            }}
                          >
                            <Txt h5>{t`Approaching Limit`}</Txt>
                            <Txt c2>{t`${
                              detail.asset.symbol
                            } borrows are at ${borrowLimitPct.toFixed(
                              2,
                            )}% capacity`}</Txt>
                            <Txt
                              c2
                            >{t`Available: ${availableBorrowBalance.amount}`}</Txt>
                            <Button
                              title="Close"
                              type="ghost"
                              onPress={() => close()}
                              style={{ width: '100%' }}
                            />
                          </CenterCol>
                        ),
                        showHeader: false,
                        bodyStyle: { height: 200 },
                      });
                    }}
                  >
                    {borrowLimitPct < 95 ? null : borrowLimitPct < 99 ? (
                      <ErrorIcon size={20} ml={5} />
                    ) : (
                      <WarningIcon size={20} ml={5} />
                    )}
                  </TouchableOpacity>
                </Popover>
              </CenterRow>
            ),
          },
          {
            title: t`Your Deposits:`,
            subTitle: (
              <EndCol>
                <Txt h3>
                  {`${detail.deposit.ofUser?.deposited?.amountNum?.toFixed(
                    2,
                  )} ${detail.asset.symbol}`}
                </Txt>
                <Txt h2 c2>
                  {detail.deposit.ofUser?.deposited?.valueUSD}
                </Txt>
              </EndCol>
            ),
            hidden: !detail.deposit.ofUser?.deposited?.amountNum,
          },
          {
            title: t`Your Loans:`,
            subTitle: (
              <EndCol>
                <Txt h3>
                  {`${detail.borrow.ofUser?.borrowed?.amountNum?.toFixed(
                    2,
                  )} ${detail.asset.symbol}`}
                </Txt>
                <Txt h2 c2>
                  {detail.borrow.ofUser?.borrowed?.valueUSD}
                </Txt>
              </EndCol>
            ),
            hidden: !detail.borrow.ofUser?.borrowed?.amountNum,
          },
        ],
      };
    });

  return (
    <View>
      <Container>
        <Input
          leftComponent={
            <IconSecondSearch style={{ marginRight: marginXS }} />
          }
          value={search}
          onChangeText={v => {
            setSearch(v);
          }}
          placeholder={t`Search`}
          style={{ marginBottom: marginSM }}
        />
        {/* <Tags
          tags={[
            { label: '#Borrow', color: '#D571FF' },
            { label: '#Collateral', color: '#9271FF' },
          ]}
        /> */}
        {data.map((item, index) => {
          const { title, subTitle, icon, tags, info } = item;
          return (
            <Card
              key={title + index}
              title={title}
              subTitle={subTitle}
              titleIcon={icon}
              titleTags={tags}
              onPress={item.onPress}
            >
              {info
                .filter(item => !item.hidden)
                .map((item, index) => {
                  return (
                    <InfoItem
                      key={item.title + index}
                      title={item.title}
                      subTitle={
                        isString(item.subTitle) ? (
                          <Txt color={item.color} bold>
                            {item.subTitle}
                          </Txt>
                        ) : (
                          item.subTitle
                        )
                      }
                    />
                  );
                })}
            </Card>
          );
        })}
      </Container>
    </View>
  );
};

const Container = styled(Col)`
  flex: 1;
  padding-top: ${marginXXS}px;
`;
