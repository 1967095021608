import {
  AptosProvider,
  deserialize,
  deserializeBaseType,
  MoveValueType,
  StructValueType,
} from '@aries-markets/create-sdk';
import { isString } from 'lodash';

export const getSimpleMapResolver = async (
  value: {
    data: { key: string; value: any }[];
  },
  ctx: {
    path?: string;
    schema: MoveValueType;
    provider: AptosProvider;
    program: string;
  },
) => {
  if (isString(ctx.schema)) {
    return [];
  }

  const { typeArgs } = ctx.schema;
  const valType = typeArgs?.[1];
  if (!valType) return [];

  return Promise.all(
    value.data.map(async v => {
      const val = await (isString(valType)
        ? deserializeBaseType(v.value, valType)
        : deserialize(v.value, {
            ...ctx,
            schema: (valType as StructValueType).schema,
          }));

      return { key: v.key, value: val };
    }),
  );
};
