import { getProviderHub } from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import {
  EMODE_ID_MAINNET,
  EMODE_ID_TESTNET,
} from '@aries/aptos-defi/config';
import {
  getCurrentProfile,
  getProfileHub,
} from '@aries/aptos-defi/lending/data';
import { withSendTxNotify } from '@aries/aptos-defi/utils';

const getEmodeId = () =>
  getProviderHub()?.env.isMainnet ? EMODE_ID_MAINNET : EMODE_ID_TESTNET;
export const enterEmode = withSendTxNotify(async () => {
  const res = await getAriesSDK()
    .controller.enterEmode({
      profile_name: getCurrentProfile().name,
      emode_id: getEmodeId(),
    })
    .execute();

  getProfileHub()?.refreshCurrentProfile();

  return res;
});

export const exitEmode = withSendTxNotify(async () => {
  const res = await getAriesSDK()
    .controller.exitEmode({
      profile_name: getCurrentProfile().name,
    })
    .execute();

  getProfileHub()?.refreshCurrentProfile();

  return res;
});
