import { ECONIA_COIN_ACCOUNT } from '@aries/aptos-defi/config';
import { BaseAsset } from '@aries/defi-toolkit/types';
import axios from 'axios';
import { getCurrentAptosSDK } from '../aptos-sdk';
import IconCake from '../assets/pancakeswap.jpeg';

const TESTNET =
  'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/src/defaultList.testnet.json';
const MAINNET =
  'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/typescript/src/defaultList.mainnet.json';
export const fetchTokenList = async (
  isTestnet: boolean,
): Promise<BaseAsset[]> => {
  const url = isTestnet ? TESTNET : MAINNET;

  const rawList = await axios.get(url);

  const data = rawList?.data as {
    name: string;
    symbol: string;
    decimals: number;
    logo_url: string;
    token_type: {
      type: string;
    };
    coingecko_id: string;
  }[];

  const tokenList =
    data.map(coin => {
      const baseInfo = {
        name: coin.name,
        symbol: coin.symbol,
        decimals: coin.decimals,
        logoURI: logoURIOverride[coin.symbol] ?? coin.logo_url,
        coingeckoId: coin.coingecko_id,
      };

      return {
        ...baseInfo,
        id: coin.token_type.type,
        coingeckoId: coingeckoIdOverride[coin.symbol] ?? coin.coingecko_id,
      };
    }) ?? [];

  if (isTestnet) {
    const coinStore =
      await getCurrentAptosSDK().coin.CoinInfo.all.fromAddress(
        ECONIA_COIN_ACCOUNT,
      );
    tokenList.push(
      ...coinStore.map(c => ({
        id:
          /(?:0x1::coin::CoinInfo<)(.*)(?:>)/g.exec(c.resourceType)?.[1] ??
          '',
        name: c.name,
        symbol: c.symbol,
        decimals: c.decimals.toNumber(),
        logoURI: logoURIOverride[c.symbol] ?? logoURIOverride.tUSDC,
        coingeckoId: coingeckoIdOverride[c.symbol] ?? 'usd-coin',
      })),
    );
  }

  return tokenList;
};

const coingeckoIdOverride: Record<string, string> = {
  tAPT: 'tortuga-staked-aptos',
  eAPT: 'tortuga-staked-aptos',
  stAPT: 'amnis-staked-aptos-coin',
  eUSDC: 'usd-coin',
  zWBTC: 'bitcoin',
  GUI: 'gui-inu',
};
const logoURIOverride: Record<string, string> = {
  CAKE: IconCake,
  eUSDC:
    'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/icons/USDC.svg',
  eAPT: 'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/icons/APT.webp',
};
