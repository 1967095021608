import { useBalanceHub } from '@aries/aptos-defi/common';
import { useAptosLendingApp } from '@aries/aptos-defi/lending';
import { Asset, EMPTY_BALANCE } from '@aries/defi-toolkit/types';
import { useProfileHub } from './data';

export const useBorrowableWallet = (asset: Asset | undefined) => {
  const { reserves } = useAptosLendingApp();
  const { currentProfile } = useProfileHub();
  const { balanceMap } = useBalanceHub();
  const reserve = reserves.find(r => {
    return r.id === asset?.id;
  });

  const depositBalance =
    asset?.toBalance(reserve?.deposit.ofUser?.withdrawable ?? 0) ??
    EMPTY_BALANCE;

  const walletBalance =
    asset?.lamportsToBalance(balanceMap[asset.id].amount) ?? EMPTY_BALANCE;

  const getBorrowableAmount = (toAsset: Asset | undefined) => {
    const empty = {
      borrowableAmount: 0,
      maxLeverage: 0,
    };

    const fromAsset = asset;

    if (!toAsset || !fromAsset) return empty;
    const reserveA = reserves.find(r => r.asset.id === fromAsset.id);
    const reserveB = reserves.find(r => r.asset.id === toAsset.id);

    if (!reserveA || !reserveB) {
      return empty;
    }

    const depositA = reserveA.deposit.ofUser?.deposited.amountNum ?? 0;
    const ltvA = reserveA.meta.loanToValuePct / 100;
    const ltvB = reserveB.meta.loanToValuePct / 100;

    const priceA = fromAsset.priceUSDNum;
    const priceB = toAsset.priceUSDNum;

    const pA2pB = priceB / priceA;
    const equity = currentProfile?.availableMarginUSD.toNumber() ?? 0;

    const simulate =
      (pA2pB * (equity - priceA * depositA * ltvA) +
        depositA * priceB * ltvB) /
      (priceA * pA2pB - priceB * ltvB);

    const maxBorrowableAmount = (simulate + depositA) * 0.96;
    return {
      borrowableAmount: maxBorrowableAmount,
      maxLeverage: equity === 0 ? 0 : maxBorrowableAmount / equity,
    };
  };

  return {
    depositBalance,
    walletBalance,
    borrowMeta: {
      borrowApy: reserve?.borrow.baseApy.base ?? '-',
      borrowApyPositive: !!reserve?.borrow.baseApy.isPositive,
    },
    getBorrowableAmount,
  };
};
