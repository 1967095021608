import { ImgHTMLAttributes } from 'react';
import AnimeSwa from './animeswa.png';
import AptosSwa from './aptosswap.png';
import Aux from './aux.png';
import Basiq from './basiq.jpeg';
import Cellana from './cellana.avif';
import Cetus from './cetus.png';
import Ditto from './ditto.jpeg';
import Econia from './econia.png';
import Hippo from './hippo.jpeg';
import Liquidswap from './liquidateswap.jpeg';
import Obric from './obric.png';
import Pancakeswap from './pancakeswap.jpeg';
import Sushiswap from './sushiswap.avif';
import Thala from './thala.jpeg';
import Tortuga from './tortuga.jpeg';

export { default as IconEconiaLogoLong } from './econia-long.png';

const standardizeImg =
  (Icon: string) =>
  (overrideProps: ImgHTMLAttributes<HTMLImageElement>) => {
    const {
      style: overrideStyle,
      height,
      width,
      ...restOverrideProps
    } = overrideProps;
    const style = {
      height: height ?? '1em',
      width: width ?? '1em',
      borderRadius: '50%',
      ...overrideStyle,
    };
    return (
      <img src={Icon} alt="icon" style={style} {...restOverrideProps} />
    );
  };

export const IconHippo = standardizeImg(Hippo);
export const IconAux = standardizeImg(Aux);
export const IconLiquidswap = standardizeImg(Liquidswap);
export const IconPancakeswap = standardizeImg(Pancakeswap);
export const IconCetus = standardizeImg(Cetus);
export const IconObric = standardizeImg(Obric);
export const IconAnimeswap = standardizeImg(AnimeSwa);
export const IconTortuga = standardizeImg(Tortuga);
export const IconAptosswap = standardizeImg(AptosSwa);
export const IconDitto = standardizeImg(Ditto);
export const IconBasiq = standardizeImg(Basiq);
export const IconEconia = standardizeImg(Econia);
export const IconThala = standardizeImg(Thala);
export const IconCellana = standardizeImg(Cellana);
export const IconSushiswap = standardizeImg(Sushiswap);
