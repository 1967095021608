import axios from 'axios';
import useSWR from 'swr';

export const useIsUserInUS = () => {
  const { data: isUserInUS = false, isValidating } = useSWR<boolean>(
    ['isUserInUS'],
    async () => {
      const response = await axios.get(
        `https://app.ariesmarkets.xyz/api/check-ip`,
      );
      return response.data.isUsIp;
    },
    { revalidateOnFocus: false, revalidateIfStale: false },
  );

  return { isUserInUS, isCheckingUserIpLoading: isValidating };
};
