import { camelCaseKey } from '@aries-markets/create-sdk';

export const delay = (time: number) =>
  new Promise(r => {
    setTimeout(r, time);
  });

export const typeInfoToStr = (
  typeInfo:
    | TypeInfo
    | {
        account_address: string;
        module_name: string;
        struct_name: string;
      },
  isRaw?: boolean,
) => {
  const { accountAddress, moduleName, structName } = camelCaseKey(
    typeInfo,
  ) as TypeInfo;

  return `${accountAddress}::${
    isRaw
      ? Buffer.from(
          moduleName.startsWith('0x') ? moduleName.slice(2) : moduleName,
          'hex',
        ).toString('utf-8')
      : moduleName
  }::${
    isRaw
      ? Buffer.from(
          moduleName.startsWith('0x') ? structName.slice(2) : structName,
          'hex',
        ).toString('utf-8')
      : structName
  }`;
};

export const parseTypeInfo = (_typeInfo: TypeInfo): TypeInfo => {
  const typeInfo = camelCaseKey(_typeInfo);
  return {
    accountAddress: typeInfo.accountAddress,
    moduleName: Buffer.from(typeInfo.moduleName.slice(2), 'hex').toString(
      'utf-8',
    ),
    structName: Buffer.from(typeInfo.structName.slice(2), 'hex').toString(
      'utf-8',
    ),
  };
};
