import { createStore } from '@aries/shared/deps';
import { OrderBookAppDefine } from '@aries/trading-fe-hooks/adapter';
import { useProviderHub } from '../common';
import { SwapProvider, useSwapByInitial, useSwapContext } from '../swap';
import { useEconiaTrading } from './providers/econia';

/**
 * Orderbook app impl on aptos blockchain
 */
const useAptosTradingAppImpl: () => OrderBookAppDefine = () => {
  const trading = useEconiaTrading();
  const { env } = useProviderHub();

  return {
    ...trading,
    useSwapByInitial,
    useSwapContext,
    SwapProvider,
    env,
  };
};

export const [useAptosTradingApp, AptosTradingProvider] = createStore(
  useAptosTradingAppImpl,
);
