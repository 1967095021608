import { AppEnv } from '@aries/defi-toolkit/types';
import { AptosPriceServiceConnection } from '@pythnetwork/pyth-aptos-js';
import { getReserveConfig } from '../config';
import { getCurrentProfile } from '../lending/data';

export const getPythUpdatePayload = (
  env: AppEnv,
  profile: ReturnType<typeof getCurrentProfile>,
  customCoin?: string[],
) => {
  const profilePositionCoins = profile.loanList
    .map(l => l.asset)
    .concat(profile.depositList.map(d => d.asset))
    .map(a => a.id)
    .concat(customCoin ?? []);

  const pythPricesIds = getReserveConfig(env).flatMap(config =>
    profilePositionCoins.find(c => c === config.coinAddress) &&
    config.pythPriceId
      ? [config.pythPriceId]
      : [],
  );

  if (pythPricesIds.length === 0) {
    return [];
  }

  if (env.isMainnet) {
    const conn = new AptosPriceServiceConnection(
      'https://hermes.pyth.network',
    );
    return conn.getPriceFeedsUpdateData(pythPricesIds);
  }
  if (env.isTestnet) {
    const conn = new AptosPriceServiceConnection(
      'https://hermes-beta.pyth.network',
    );
    return conn.getPriceFeedsUpdateData(pythPricesIds);
  }

  throw Error(`Pyth not supported under ${env}`);
};
