import { useBorrowableWallet } from '@aries/aptos-defi/lending/wallet';
import { Asset } from '@aries/defi-toolkit/types';
import { createStore } from '@aries/shared/deps';
import { useDebouncedValue } from '@aries/shared/hooks';
import { Swap } from '@aries/trading-fe-hooks';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { useWallet } from '../wallet';
import {
  getBinanceRatePlugin,
  getCoinGeckoRatePlugin,
  useCEXPrices,
} from './exchanges';
import { usePairSelectOptions } from './pair-select-options';
import { usePairState } from './pair-selection';
import { useSwapMarket } from './providers';

export const useSwapByInitial: (
  initialValue?: Partial<{
    fromAsset: Asset;
    toAsset: Asset;
    fromAmount: number;
    toAmount: number;
  }>,
) => Swap = initialValue => {
  // Fetch pair data
  const {
    totalAssetList,
    getRoutes,
    getRoutesWithFixedInput,
    getHasRoutesByPair,
  } = useSwapMarket();

  // Manage select state
  const {
    currentPair: { fromAsset, fromAmount, toAsset, toAmount },
    changePair,
    changeFromAsset,
    changeFromAmount: setFromAmountInner,
    changeToAsset,
    changeToAmount: setToAmountInner,
    changeDirection,
    setInitialSwapPair,
  } = usePairState(initialValue);
  const isFixedOutputRef = useRef(!!initialValue?.toAmount);

  const [routeLoading, setLoading] = useState(false);
  const [slippage, setSlippage] = useState(0.5);
  const [routes, setRoutes] = useState<Swap['currentPair']['routes']>([]);
  const [activeRoute, setActiveRoute] =
    useState<Swap['currentPair']['routes'][number]>();

  const [allowBorrow, setAllowBorrow] = useState(false);

  const { data: hasRoutes = false } = useSWR(
    ['hasRoutes', fromAsset?.id, toAsset?.id],
    async () => {
      return getHasRoutesByPair(fromAsset, toAsset);
    },
  );

  const CEXPrices = useCEXPrices(
    [getCoinGeckoRatePlugin, getBinanceRatePlugin],
    fromAsset,
    toAsset,
    activeRoute?.exchangeRate,
  );
  // Fetch asset wallet
  const {
    walletBalance: fromWalletBalance,
    depositBalance,
    borrowMeta,
    getBorrowableAmount,
  } = useBorrowableWallet(fromAsset);

  // Calculate select options by current selection
  const { fromList, toList } = usePairSelectOptions(
    totalAssetList,
    allowBorrow,
  );

  const fromAssetBalance = allowBorrow
    ? depositBalance
    : fromWalletBalance;

  const { borrowableAmount: maxFromAmount, maxLeverage } = allowBorrow
    ? getBorrowableAmount(toAsset)
    : { borrowableAmount: fromWalletBalance.amountNum, maxLeverage: 0 };

  const borrowableFromAmount = maxFromAmount - fromAmount;

  const insufficientFunds =
    fromAmount > maxFromAmount || maxFromAmount === 0;

  const { walletBalance, depositBalance: toAssetDeposited } =
    useBorrowableWallet(toAsset);

  const toAssetBalance = allowBorrow ? toAssetDeposited : walletBalance;

  const willBorrowAmount = Number(
    Math.max(0, fromAmount - fromAssetBalance.amountNum).toFixed(
      fromAsset?.decimals,
    ),
  );

  const { walletAddress } = useWallet();
  const latestRefreshTime = useRef(0);
  const debouncedFromAmount = useDebouncedValue(fromAmount, {
    timeout: 250,
  });
  const prevFromAmountRef = useRef(0);
  useEffect(() => {
    let interval: any;

    if (
      fromAsset &&
      toAsset &&
      debouncedFromAmount > 0 &&
      !isFixedOutputRef.current
    ) {
      const refresh = (reloadState = false) => {
        const now = Date.now();
        latestRefreshTime.current = now;
        setLoading(true);
        getRoutes(
          fromAsset,
          toAsset,
          debouncedFromAmount,
          slippage,
          allowBorrow,
          reloadState,
        ).then(res => {
          if (res.length > 0 && latestRefreshTime.current === now) {
            const routes = res.map((r, i) => ({
              ...r,
              swap: () => r.swap(allowBorrow),
              setActive: () => setActiveRoute(routes[i]),
            }));

            setRoutes(routes);
            setActiveRoute(routes[0]);
            setToAmountInner(routes[0]?.receive ?? 0);
          }
          setLoading(false);
        });
      };
      setTimeout(
        () => refresh(),
        prevFromAmountRef.current === 0 || prevFromAmountRef.current === -1
          ? 350
          : 0,
      );
      interval = setInterval(() => {
        refresh(true);
      }, 30000);
    }
    if (fromAsset && toAsset) {
      prevFromAmountRef.current = debouncedFromAmount;
    }
    if (debouncedFromAmount === 0) {
      setActiveRoute(undefined);
      setToAmountInner(0);
    }

    return () => {
      interval && clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromAsset?.symbol,
    toAsset?.symbol,
    debouncedFromAmount,
    slippage,
    allowBorrow,
    walletAddress,
  ]);

  const debouncedToAmount = useDebouncedValue(toAmount, {
    timeout: 250,
  });
  const prevToAmountRef = useRef(0);
  useEffect(() => {
    let interval: any;

    if (
      fromAsset &&
      toAsset &&
      debouncedToAmount > 0 &&
      isFixedOutputRef.current
    ) {
      const refresh = (reloadState = false) => {
        const now = Date.now();
        latestRefreshTime.current = now;
        setLoading(true);
        getRoutesWithFixedInput(
          fromAsset,
          toAsset,
          debouncedToAmount,
          slippage,
          reloadState,
          allowBorrow,
        ).then(res => {
          if (res.length > 0 && latestRefreshTime.current === now) {
            const routes = res.map((r, i) => ({
              ...r,
              swap: () => r.swap(allowBorrow),
              setActive: () => setActiveRoute(routes[i]),
            }));

            setRoutes(routes);
            setActiveRoute(routes[0]);
            setFromAmountInner(routes[0]?.cost ?? 0);
          }
          setLoading(false);
        });
      };
      setTimeout(
        () => refresh(),
        prevToAmountRef.current === 0 || prevToAmountRef.current === -1
          ? 350
          : 0,
      );
    }
    if (fromAsset && toAsset) {
      prevToAmountRef.current = debouncedToAmount;
    }
    if (debouncedToAmount === 0) {
      setActiveRoute(undefined);
      setFromAmountInner(0);
    }

    return () => {
      interval && clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromAsset?.symbol,
    toAsset?.symbol,
    debouncedToAmount,
    slippage,
    allowBorrow,
    walletAddress,
  ]);

  useEffect(() => {
    if (fromAsset && !fromList.find(a => a.id === fromAsset.id)) {
      changeFromAsset(undefined);
    }

    if (toAsset && !toList.find(a => a.id === toAsset.id)) {
      changeToAsset(undefined);
      setFromAmountInner(0);
      setToAmountInner(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromList, toList, fromAsset, toAsset]);

  return {
    fromList,
    toList,
    allowBorrow,
    slippage,
    currentPair: {
      fromAsset,
      fromAmount,
      fromAssetBalance,
      toAsset,
      toAmount,
      toAssetBalance,
      maxFromAmount,
      borrow: {
        borrowableFromAmount,
        willBorrowAmount,
        maxLeverage,
        ...borrowMeta,
      },
      insufficientFunds,
      hasRoutes,
      routes,
      activeRoute,
      routeLoading,
      changeActiveRoute: v => {
        setActiveRoute(v);
        setToAmountInner(v.receive ?? 0);
      },
      CEXPrices,
    },
    setSlippage,
    changePair,
    changeFromAsset,
    changeFromAmount: (v: number) => {
      setFromAmountInner(prev => {
        if (prev !== v) {
          isFixedOutputRef.current = false;
        }
        return v;
      });
    },
    changeToAsset,
    changeToAmount: (v: number) => {
      setToAmountInner(prev => {
        if (prev !== v) {
          isFixedOutputRef.current = true;
        }
        return v;
      });
    },
    changeDirection,
    setAllowBorrow,
    setInitialSwapPair,
  };
};

export const [useSwapContext, SwapProvider] = createStore(() =>
  useSwapByInitial(),
);
