import {
  hasEnoughGasOrThrow,
  useProviderHub,
} from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { withSendTxNotify } from '@aries/aptos-defi/utils';
import useSWR from 'swr';

export const useProfileList = (walletAddress: string | undefined) => {
  const { currentNetwork } = useProviderHub();
  const { data: profileList, mutate } = useSWR(
    ['ProfileList', walletAddress, currentNetwork.name],
    async () => {
      if (!walletAddress) return null;

      try {
        const sdk = getAriesSDK();

        const res = await sdk.profile.Profiles.fetch.fromAddress(
          walletAddress,
        );

        const list = res.profileSigners.map(v => ({
          profileName: v.key.replace('profile', ''),
          address: v.value.account,
        }));

        // Sort by created time.
        return list.reverse();
      } catch (err) {
        return [];
      }
    },
    { revalidateOnFocus: false },
  );

  const init = async (profileName: string) => {
    const success = await withSendTxNotify(async () => {
      const sdk = getAriesSDK();
      hasEnoughGasOrThrow();
      return sdk.controller
        .registerUser({
          default_profile_name: profileName,
        })
        .execute();
    })();

    if (!success) {
      return false;
    }

    await mutate(v => v);
    return success;
  };

  const addProfile = async (profileName: string) => {
    const success = await withSendTxNotify(async () => {
      hasEnoughGasOrThrow();
      const sdk = getAriesSDK();
      return sdk.controller
        .addSubaccount({ profile_name: profileName })
        .execute();
    })();

    if (!success) {
      return false;
    }

    await mutate(v => v);
    return success;
  };

  return {
    profileList,
    init,
    addProfile,
  };
};
