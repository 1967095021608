import { CenterRow, Col, Txt } from '@aries/mobile-core/components';
import { globalToken } from '@aries/ui-theming';
import React, { ReactNode } from 'react';
import { Tag } from '~/components';

const { marginXXS, marginXS } = globalToken;
export const Header: React.FC<{
  tags?: { label: string; color?: string; bckColor?: string }[];
  icon: ReactNode;
  title: string;
  subTitle?: string;
}> = ({ tags = [], icon, title, subTitle }) => {
  return (
    <CenterRow mb={marginXS}>
      <CenterRow mr={marginXS} ml={marginXXS}>
        {icon}
      </CenterRow>
      <Col>
        <CenterRow mt={marginXXS}>
          <Txt bold mr={marginXXS} h4>
            {title}
          </Txt>

          {tags.map(({ label, color, bckColor }) => {
            return (
              <Tag
                label={label}
                color={color}
                bckColor={bckColor}
                style={{ marginLeft: marginXXS }}
                key={label}
              />
            );
          })}
        </CenterRow>
        {subTitle ? (
          <Txt c2 h2>
            {subTitle}
          </Txt>
        ) : null}
      </Col>
    </CenterRow>
  );
};
