import {
  getAriesSDK as getAriesSDKInner,
  getAriesWrapperSDK as getAriesWrapperSDKInner,
} from '@aries-markets/aries-tssdk';

import { AptosProvider } from '@aries-markets/create-sdk';
import { memoize } from 'lodash';
import { getAriesProgram, getProviderHub } from './aptos-provider';

const getAriesSDKMemo = memoize(
  (provider: AptosProvider, program: string) =>
    getAriesSDKInner(provider, program),
);

const getWrapperSDKMemo = memoize(
  (provider: AptosProvider, program: string) =>
    getAriesWrapperSDKInner(provider, program),
);

export const getAriesSDK = () => {
  const ariesSDK = getAriesSDKMemo(
    getProviderHub()?.provider!,
    getAriesProgram(),
  );
  const wrapperSDK = getWrapperSDKMemo(
    getProviderHub()?.provider!,
    getAriesProgram(),
  );
  return {
    ...ariesSDK,
    extern: { ...wrapperSDK.wrapped_controller },
  };
};

export const getLPTokenAddress = (coinType: string) =>
  `${getAriesProgram()}::lp::LP<${coinType}>`;
